import React from "react";
import { connect } from "react-redux";
import moment from "moment";
import { Formik, Form } from "formik";
import { Row, Col, Radio, TimePicker } from "antd";
import DatePicker from "../datepickers/DatePicker";
import Select from "../input/Select";
import Button from "../button/index";
import Input from "../input/index";
import TextArea from "../input/TextArea";
import Switch from "../switch/index";
import { Link } from "react-router-dom";

function onChangeTime(time, timeString) {
  let newTimeString = moment(time).format("HH:mm:ss");
  return newTimeString;
}
function onChangeDate(time, timeString) {
  let newTimeString = moment(time).format("YYYY-MM-DD HH:mm:ss");
  return newTimeString;
}
const RootForm = props => {
  return (
    <div>
      <Formik
        initialValues={props.initialValues}
        validationSchema={props.schemaName}
        enableReinitialize={props.enableReinitialize}
        onSubmit={(values, formikConfig) => {
          if (props.customErrors) {
            for (let error in props.customErrors) {
              if (props.customErrors[error]) {
                return;
              }
            }
          }
          if (props.customSubmit) {
            props.customSubmit(values, formikConfig);
          } else {
            let callback = () => {
              if (props.resetAfterSubmit) {
                formikConfig.resetForm(props.initialValues);
              }
              if (props.cb) {
                props.cb(values);
              }
            };
            // let extras = (() => {
            //   if (props.redirectProp) {
            //     return props.redirectProp(values.email);
            //   } else {
            //     return props.extras;
            //   }
            // })();
            props.dispatch(props.apiCall(values, props.cb, props.extras));
            // console.log("Form Api Call Props", props);
          }
        }}
      >
        {({
          values,
          touched,
          errors,
          dirty,
          isSubmitting,
          handleChange,
          handleBlur,
          handleSubmit
        }) => (
          <Form>
            <Row>
              {props.controls.map(val => {
                //console.log("CONTROLS", val, values, props, touched
                //, values, errors, props.customErrors, touched
                //);
                let selectBo;
                return (
                  <Col key={val.name} span={val.span} style={val.colStyle}>
                    {val.label ? (
                      <p {...val.label.attr}>{val.label.name}</p>
                    ) : null}
                    <val.Type
                      {...val.props}
                      password={
                        val.name === "password" ||
                        val.name === "confirm_password" ||
                        val.name === "new_password" ||
                        val.name === "currentPassword" ||
                        val.name === "cvc"
                          ? true
                          : false
                      }
                      //{val.Type === Select ? val.noBorder ? val.noBorder : null : null}
                      //noBorder
                      disabled={val.disabled}
                      block={val.block}
                      allowClear={val.allowClear}
                      onSelect={val.onSelect}
                      name={val.name}
                      value={values[val.name]}
                      className={val.className}
                      disabledDate={val.disabledDate}
                      showToday={val.showToday}
                      suffix={val.suffix}
                      rows={val.Type == TextArea ? val.rows : null}
                      onChange={e => {
                        if (
                          val.Type == Input ||
                          val.Type == Radio.Group ||
                          val.Type == TextArea
                        ) {
                          if (props.enableReinitialize && val.handleChanged) {
                            val.handleChanged(e);
                          } else {
                            handleChange(e);
                          }
                          if (props.changeEmail && val.name == "email") {
                            props.changeEmail(e.target.value);
                          }
                        } else if (val.Type == TimePicker) {
                          let newt = onChangeTime(e);
                          let newtval = moment(newt, "HH:mm:ss");
                          let obj = {
                            target: {
                              name: val.name,
                              value: newtval
                            }
                          };
                          if (props.enableReinitialize && val.handleChanged) {
                            val.handleChanged(obj);
                          } else {
                            handleChange(obj);
                          }
                        } else if (val.Type == DatePicker) {
                          let newt = onChangeDate(e);
                          let newtval = moment(newt, "YYYY-MM-DD HH:mm:ss");
                          let obj = {
                            target: {
                              name: val.name,
                              value: newtval
                            }
                          };
                          if (props.enableReinitialize && val.handleChanged) {
                            val.handleChanged(obj);
                          } else {
                            handleChange(obj);
                          }
                        } else if (val.Type == Select || val.Type == Switch) {
                          let obj = {
                            target: {
                              name: val.name,
                              value: e
                            }
                          };
                          if (props.enableReinitialize && val.handleChanged) {
                            val.handleChanged(obj);
                          } else {
                            handleChange(obj);
                          }
                        } else {
                          let changeEvent = e.target
                            ? e
                            : { target: { name: val.name, value: e } };
                          if (props.enableReinitialize && val.handleChanged) {
                            val.handleChanged(changeEvent);
                          } else {
                            handleChange(changeEvent);
                          }
                        }
                      }}
                      // onBlur={
                      //   val.name == "email"
                      //     ? e => {
                      //         if (val.onBlur) val.onBlur(values);
                      //         handleBlur(e);
                      //       }
                      //     : handleBlur
                      // }
                      onBlur={e => {
                        console.log("Blur", e, val.name);
                        handleBlur(
                          e && e.target
                            ? e
                            : { target: { name: val.name, value: "" } }
                        );
                      }}
                      placeholder={val.placeholder}
                      visibilityToggle={val.visibilityToggle}
                      defaultValue={
                        val.Type == Select ? val.defaultValue : null
                      }
                      addonBefore={val.addonBefore}
                    >
                      {val.Type == Select || val.Type == Radio.Group
                        ? val.options ? val.options() : null
                        : null}
                    </val.Type>
                    {(errors[val.name] && touched[val.name]) ||
                    (props.customErrors
                      ? props.customErrors[val.name]
                      : false) ? (
                      <div className="dangerColor mr-b small">
                        {errors[val.name] ||
                          (props.customErrors
                            ? props.customErrors[val.name]
                            : "")}
                      </div>
                    ) : null}
                  </Col>
                );
              })}
            </Row>
            <Row
              type="flex"
              // justify={
              //   typeof props.isLink !== "undefined"
              //     ? props.isLink.align
              //     : "center"
              // }
              justify="center"
              align="middle"
            >
              {typeof props.isLink !== "undefined" ? (
                <Col span={24} {...props.isLink} className={`${props.isLink.className}`}>
                  <Link
                    to={`${props.isLink.url}`}
                  >{`${props.isLink.linktext}`}</Link>
                </Col>
              ) : null}
              <Col span={props.submitButton.span}>
                <Button
                  autoWidthSubmit={props.autoWidthSubmit}
                  loading={props.loading}
                  size={props.submitButton.submitSize}
                  block={!props.autoWidthSubmit}
                  onClick={
                    props.submitButton.onClick
                      ? props.submitButton.onClick
                      : handleSubmit
                  }
                  type="submit"
                  className={props.submitButton.className}
                  style={props.submitButton.style}
                >{`${props.submitButton.buttonText}`}</Button>
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default connect()(RootForm);
