import React from "react";
import propTypes from 'prop-types';
import { LineChart, Line, CartesianGrid, XAxis, YAxis, Tooltip } from 'recharts';
import { withTheme } from "styled-components";

class renderLineChart extends React.Component {

  state = {
    updateXAxis: false
  }

    componentWillUpdate(nextProps) {
      
      if (this.props.data !== nextProps.data) {
        this.setState({ updateXAxis: !this.state.updateXAxis })
      }
    }

    render() {
    var obj;
    if (this.props.data[0])
      obj = Object.keys(this.props.data[0]);
      
    return (
      <div>
        <LineChart width={this.props.width} height={this.props.height} data={this.props.data.length !== 0 ? this.props.data : [{ empty: 0 }]} margin={this.props.margin}>
          {obj && !this.props.loading ? obj.map((value, index) => {
            //console.log("DETAILS", obj, value, index)
            return (
              <Line key={index.toString()} type={this.props.lineType} dataKey={obj[index + 2]} stroke={this.props.lineColor ? this.props.lineColor[index] : this.props.theme[this.props.theme.mode].primary.main} />
            )
          }) : (
              null
            )}
          {this.props.isCartReq ? <CartesianGrid dataKey={"empty"} stroke={this.props.cartStroke} strokeDasharray={this.props.cartDash} /> : null}
          
          {this.state.updateXAxis ? (
          <XAxis
          key={100}
            dataKey={obj && !this.props.loading ? obj[1] : null}
            allowDuplicatedCategory={false}
          />
          ) : (
          <XAxis
          key={200}
            dataKey={obj && !this.props.loading ? obj[1] : null}
            allowDuplicatedCategory={false}
          />
          )}
          <YAxis domain={this.props.data.length && [0, () => {
            let array = [...this.props.data];
            return array.sort((a, b) => a.uv - b.uv)[array.length - 1].uv
          }]}/>
          {this.props.isTooltip ? <Tooltip /> : null}
        </LineChart>
        <div style={{ flexDirection:'row', display:'flex', justifyContent:'center', alignItems:'center'}}>
          <div style={{
            height:15,
            width:15,
            borderRadius:15,
            background:'#AD1E23',
            marginRight: 10
          }}/>
          <p> ios</p>
          <div style={{
            height:15,
            width:15,
            borderRadius:15,
            background:'black',
            marginLeft: 10,
            marginRight: 10
          }}/>
             <p>android</p>
        </div>
      </div>
    )
  }
}

renderLineChart.defaultProps = {
  width: '100%',
  height: 300,
  margin: { top: 0, right: 0, left: 0, bottom: 0 },
  lineType: 'monotone',
  isCartReq: false,
  cartStroke: '#f0f0f0',
  cartDash: 5,
  isTooltip: true,
}

renderLineChart.propTypes = {
  data: propTypes.array.isRequired,
  lineColor: propTypes.array.isRequired,
}
export default withTheme(renderLineChart);