import { put, takeLatest } from "redux-saga/effects";

import { PROFILE_UPDATE_REQUEST } from "../configurations/Types";

import {
  profileUpdateSuccess,
  profileUpdateError
} from "../actions/UpdateProfile";

import {
  USERS_URL,
  UPDATE_PROFILE_ENDPOINT
} from "../configurations/Constants";

import moment from "moment";
import info from "../components/message/index";
import { privateAgent } from "../configurations/AxiosAgent";

const updateProfileApi = body => {
  // console.log("body updateProfileApi", body);
  let current_time = moment().format("YYYY-MM-DD HH:mm:ss");
  // console.log("current_time", current_time);
  return privateAgent.put(`${USERS_URL}${UPDATE_PROFILE_ENDPOINT}`, {
    ...body,
    current_time
  });
};

export function* updateProfile(action) {
  let res;
  // console.log("updateProfile from sage", action);
  try {
    // console.log("try running", action);
    let { btnFlag, ...properBody } = action.body;
    res = yield updateProfileApi(properBody);
    // console.log("res after request", res);
    if (res.data.status === 200) {
      // console.log("res.data.data.status ===400 :");
      info("success", `${res.data.data.message}`);
      yield put(profileUpdateSuccess(action.body.full_name));

      let newStorage = JSON.parse(localStorage.getItem("reviseuserauth"));
      newStorage.full_name = properBody.full_name;
      localStorage.setItem("reviseuserauth", JSON.stringify(newStorage));
      // console.log("eidt profile cb :",action.cb)
      if (action.cb) {
        action.cb();
      }
    }
  } catch (e) {
    if (e.response) {
      info("error", `${e.response.data.error.message}`);
    }
    yield put(profileUpdateError(e));
  }
}

// Our watcher Saga: spawn a new UpdateProfile task on each FLAGGED_REQUEST
export function* watchProfileChanages() {
  yield takeLatest(PROFILE_UPDATE_REQUEST, updateProfile);
}
