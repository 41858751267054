import { put, takeLatest, cancelled } from "redux-saga/effects";
import {
  USERS_REQUEST,
  USER_REQUEST,
  CHANGE_USER_STATUS_REQUEST,
  ADD_USER_REQUEST,
  DELETE_USER_REQUEST,
  GET_USER_DETAIL_REQUESST,
} from "../configurations/Types";
import {
  USERS_ENDPOINT,
  USER_ENDPOINT,
  CHANGE_USER_STATUS_ENDPOINT,
  ADD_USER_ENDPOINT,
  DELETE_USER_ENDPOINT,
  DELETE_ORGANIZATION_ENDPOINT,
  GET_USER_DETAIL_ENDPOINT,
} from "../configurations/Constants";
import {
  usersRequest,
  usersRequestSuccess,
  usersError,
  receivedUser,
  userError,
  changeUserStatusSuccess,
  changeUserStatusError,
  getUserDetailSuccess,
  getUserDetailError,
} from "../actions/Users";
import info from "../components/message/index";
import { privateAgent, CancelToken } from "../configurations/AxiosAgent";
import moment from "moment";

const getUserAPI = (params, token) =>
  privateAgent.get(USERS_ENDPOINT, {
    cancelToken: token.token,
    params: { ...params },
  });

const userAPI = (userId) => privateAgent.get(`${USER_ENDPOINT}/${userId}`);

// const updateUserStatusAPI = ({user_id, status}) => {
//   return privateAgent.patch(`${CHANGE_USER_STATUS_ENDPOINT}/${user_id}`, {
//     status,
//   });
// };

// const updateOrganizationAPI = (param, body) => {
//   return privateAgent.put(`${CHANGE_USER_STATUS_ENDPOINT}/${param}`, {
//     ...body,
//   });
// };

const addUserAPI = (body) => {
  return privateAgent.post(`${ADD_USER_ENDPOINT}`, {
    ...body,
  });
};

const deleteUserAPI = ({ id, body }) => {
  return privateAgent.delete(`${DELETE_USER_ENDPOINT}/${id}`, {
    ...body,
  });
};

let token;

// Our worker Saga: will perform the async getUsers task
function* getAllUsers({ payload, cb }) {
  let res;
  try {
    token && token.cancel();
    token = CancelToken.source();
    res = yield getUserAPI(payload, token);

    const data = {
      count: res.data.data.count,
      data: res.data.data.rows,
    };
    yield put(usersRequestSuccess(data));
  } catch (e) {
    if (cb) {
      cb(e.response);
    }
    yield put(usersError(e));
    try {
      info(
        "error",
        `${e.response ? e.response.data.error.message : "Network Error"}`
      );
    } catch (e) {}
  }
}

function* userRequest(action) {
  let res;
  try {
    res = yield userAPI(action.id);
    yield put(receivedUser(res.data.data));
  } catch (e) {
    yield put(userError(e));
    try {
      info(
        "error",
        `${e.response ? e.response.data.message : "Network Error"}`
      );
    } catch (e) {}
  } finally {
    if (yield cancelled()) {
      console.log("CANCELLED");
    }
  }
  if (action.callback) {
    action.callback(res);
  }
}

// function* userStatusRequest({params,cb}) {
//   let res;
//   try {
//     res = yield updateUserStatusAPI(params);
//     if(res.status == 200){
//       if (cb) {
//         cb(res);
//       }
//       info("success", res?.data?.message);
//     }
//   } catch (e) {
//     console.log("updateUser_ERROR", e.response);
//     yield put(changeUserStatusError(e));
//     info(
//       "error",
//       `${e.response ? e.response.data.error.message : "Network Error"}`
//     );
//   } finally {
//     if (yield cancelled()) {
//       console.log("CANCELLED");
//     }
//   }
// }

function* addUserRequest({ body, cb }) {
  const objAddUser = {
    email: body?.email,
    userName: body?.userName,
  };

  let res;
  try {
    if (token) {
      token.cancel();
    }
    token = CancelToken.source();
    res = yield addUserAPI(objAddUser);
    if (res.status == 200) {
      if (cb) {
        cb(res);
      }
      info("success", res?.data?.message);
      yield put(usersRequest(body?.state));
    }
  } catch (e) {
    try {
      info(
        "error",
        // `${e.response ? e.response.data.error.message : "Network error"}`
        `${
          e.response?.data?.message
            ? "Network error"
            : e.response?.data?.error?.message
            ? "Your account has been de-activated by the admin. Please contact at hello@revisebeat.com for further query."
            : "Network error"
        }`
      );
    } catch (e) {
      info("error", `${e.response.data.message}`);
    }
  } finally {
    if (yield cancelled()) {
      console.log("CANCELLED");
    }
  }
}

function* deleteUserRequest({ body, cb }) {
  let res;
  try {
    if (token) {
      token.cancel();
    }
    token = CancelToken.source();
    res = yield deleteUserAPI(body);
    console.log("unauthurizedRes====> ", res);
    if (res.status == 200) {
      info("success", res?.data?.message);
      yield put(usersRequest(body?.state));
      if (cb) {
        cb(res);
      }
    }
  } catch (e) {
    try {
      info(
        "error",
        // `${e.response ? e.response.data.message : "Network error"}`
        `${
          e.response?.data?.message
            ? "Network error"
            : e.response?.data?.error?.message
            ? "Your account has been de-activated by the admin. Please contact at hello@revisebeat.com for further query."
            : "Network error"
        }`
      );
    } catch (e) {}
  } finally {
    if (yield cancelled()) {
      console.log("CANCELLED");
    }
  }
}
const getUserDetailApi = () => {
  const ls = JSON.parse(localStorage.getItem("reviseuserauth"));

  return privateAgent.get(`${GET_USER_DETAIL_ENDPOINT}`, {
    token: ls.token,
    // ...body,
  });
};
function* getUserDetailReq(action) {
  console.log("sagagsaaa", action);
  let res;
  try {
    res = yield getUserDetailApi();
    yield put(getUserDetailSuccess(res.data.data));
  } catch (e) {
    console.log("ERROR", e.response);

    yield put(getUserDetailError(e));
  } finally {
    if (yield cancelled()) {
      console.log("CANCELLED");
    }
  }
  //console.log("In saga", res);
  if (action.callback) {
    action.callback(res);
  }
}

// Our watcher Saga: spawn a new getUsers task on each USERS_REQUEST
export function* watchGetUsers() {
  yield takeLatest(USERS_REQUEST, getAllUsers);
  yield takeLatest(USER_REQUEST, userRequest);
  // yield takeLatest(CHANGE_USER_STATUS_REQUEST, userStatusRequest);
  yield takeLatest(ADD_USER_REQUEST, addUserRequest);
  yield takeLatest(DELETE_USER_REQUEST, deleteUserRequest);
  yield takeLatest(GET_USER_DETAIL_REQUESST, getUserDetailReq);
}
