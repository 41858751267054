import React, { useEffect, useCallback, useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Loading from "../../../components/loading";
import { Row, Col } from "antd";
import Icon from '../../../components/Icon'
import { useHistory, useLocation } from "react-router-dom";
import Input from "../../../components/input";
import Button from '../../../components/button';
import useFormik from "../../../components/formik/useFormik";
import SelectWrapper from "../../../components/input/Select";
import { usersRequest } from "../../../actions/Users";
import { addOrganizationRequest } from '../../../actions/Organization'
import { clearRequests } from "../../../actions/Requests";
import OptionsWrapper from "../../../components/input/SelectOptions";
import { addOrganizationSchema } from "../../../configurations/Schemas/Organization";
import { findRenderedDOMComponentWithClass } from "react-dom/test-utils";
import { Select } from "antd";
const AddOrganization = (props) => {
  const { user } = props.location.state ? props.location.state : {};
  const { full_name, email, status } = user ? user : {};
  const location = useLocation();
  const [filters, setFilters] = useState({
    email: "",
    type: "all",
    active: undefined,
    limit: 100,
    offset: 0
  });



  const formik = useFormik({
    validationSchema: addOrganizationSchema,
    initialValues: location.state || {
      userId:'',
      name: '',
      email: '',
      phone: '',
      location: '',
      organizationName: '',
      ownerName: '',
      allowedUsers:'',
      pricePerUser:'',
      subscriptionPlanId: '',

    },
    onSubmit: (values, { ...rest }) => {
        props.addOrganizationRequest(values, (res) => {
         if(res.status == 200){
           props.history.push('/organization');
         }
        });
    },
  });

  const subscriptionPackages = [
    { key: 1, value: 'Standard Package' },
    { key: 2, value: 'Premium Package' }
  ];

  function onChange(email) {
    setFilters({ ...filters, email: email }); 
    let newEmail = filters.email;
    formik.setFieldValue('email', newEmail);
  }

  function onSelectSubscription(packageId) {
    formik.setFieldValue('subscriptionPlanId', packageId)
    
  }

  function onSelect(email) {
    let userObj = props.userData.users.find((val) => val.email == email);
    if(userObj){
    formik.setFieldValue('name', userObj.full_name);
    formik.setFieldValue('ownerName', userObj.full_name);
    formik.setFieldValue('email', userObj.email);
    formik.setFieldValue('userId', userObj.user_id);
    }else {
      formik.setFieldValue('email', userObj.email);
    }
  }

  useEffect(() => {
    props.usersRequest({ ...filters });
  }, [filters]);


  const field = useCallback(
    (placeholder, name, value, customProps) => (
      <Col span={24}>
        <Input
          style={{ marginTop: 15, marginBottom:'1%' }}
          placeholder={placeholder}
          value={value}
          name={name}
          //   disabled
          onChange={(e) => {
            //console.log(e.target.value, 'inside onChange func of ', name)
            formik.customHandleChange(e, name);
          }}
          onBlur={(e) => formik.customHandleBlur(e, name)}
          {...customProps}
        />
        <span style={{ marginLeft:'2px'}} className="dangerColor textCenter">
          {formik.touched[name] && formik.errors[name]}
        </span>
      </Col>
    ),
    [formik]
  );



  return true ? (
    <>
      <Col>

        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyItems: "center" }}>
          <Link to="/organization">
            <Icon type="arrow-left" style={{ fontSize: '15px', color: '#AD1E23' }} />
          </Link>
          <h4 style={{ marginLeft: 10, marginTop: 5 }}> Add Organization</h4>
        </div>
      </Col>

      <Col style={{ padding: 50, }}>
        <div>
          <h3 style={{ fontWeight: "bold", marginLeft: "18px", marginBottom: '10px' }}> Details </h3>
          <Row gutter={{ xs: 8, sm: 32, md: 24, lg: 32 }}>

            <Col style={{ marginBottom:'2px' }} span={12}>
              <label style={{ marginLeft: "18px", marginBottom: "25px" }}>Email</label>
              <Col span={24}>
                <SelectWrapper
                  // noBorder
                  showArrow={false}
                  showSearch
                  onSelect={onSelect}
                  onSearch={onChange}
                  onFocus={() => formik.setFieldValue('email', "")}
                  loading={props.userData.users ? false : true}
                  // onChange={props.filterChange}
                  value={formik.values["email"]?.length < 2 ? "" : formik.values["email"]}
                  // defaultValue={'Enter Email'}
                  placeholder={'Email'}
                  style={{ width: "100%", marginTop: 15, }}                >
                  {props.userData.users.map(val => {
                    return (
                      <Select.Option value={val.email} key={val.user_id}>
                        {val.email}
                      </Select.Option>
                    );
                  })}
                </SelectWrapper>
                <span style={{ marginTop:'10px', marginLeft:'2px'}} className="dangerColor textCenter">
                  {formik.touched['email'] && formik.errors['email']}
                </span>
              </Col>
            </Col>
            <Col  style={{ marginBottom:'2px' }} span={12}>
              <label className="mr-b-sm" style={{ marginLeft: "20px", marginBottom: "25px" }}>Name</label>
              {field(
                '',
                "name",
                formik.values['name']
              )}</Col>

            <Col span={12}>
              <label style={{ marginLeft: "18px", marginBottom: "25px" }}>Organization Owner</label>
              {field(
                "",
                "ownerName",
                formik.values["ownerName"],
                {
                  onChange: (e) => {
                    formik.customHandleChange(e, "ownerName");
                    // here you dispatch your api for checking the email
                  },
                }
              )}</Col>
            <Col span={12}>
              <label style={{marginLeft: "18px", marginBottom: "25px" }}>Organization Name</label>
              {field(
                "",
                "organizationName",
                formik.values["organizationName"],
                {
                  onChange: (e) => {
                    formik.customHandleChange(e, "organizationName");
                    // here you dispatch your api for checking the email
                  },
                }
              )}</Col>
            <Col style={{ marginBottom:'2px' }} s span={12}>
              <label style={{ marginLeft: "18px", marginBottom: "25px" }}>Phone Number</label>
              {field(
                '',
                "phone",
                formik.values['phone'],
                {
                  onChange: (e) => {
                    formik.customHandleChange(e, "phone");
                    // here you dispatch your api for checking the email
                  },
                }
              )}</Col>
            <Col style={{ marginBottom:'2px' }} s span={12}>
              <label style={{ marginLeft: "18px", marginBottom: "25px" }}>Price per user/year</label>
              {field(
                '',
                "pricePerUser",
                formik.values['pricePerUser'],
                {
                  onChange: (e) => {
                    formik.customHandleChange(e, "pricePerUser");
                    // here you dispatch your api for checking the email
                  },
                }
              )}</Col>

            <Col style={{ marginBottom:'2px' }} s span={12}>
              <label style={{ marginLeft: "18px", marginBottom: "25px"  }}>Subscription Package</label>
              <Col span={24}>
                <SelectWrapper
                  // noBorder
                  onSelect={onSelectSubscription}
                  onChange={props.filterChange}
                  defaultValue={props.options ? props.options[0].label : 'Choose Package'}
                  style={{ width: "100%", marginTop: 15 }}
                >
                  {subscriptionPackages.map(val => {
                    return (
                      <Select.Option value={val.key} key={val.value}>
                        {val.value}
                      </Select.Option>
                    );
                  })}
                </SelectWrapper>
                <span className="dangerColor textCenter">
                  {formik.touched['subscriptionPlanId'] && formik.errors['subscriptionPlanId']}
                </span>
              </Col>
            </Col>


            <Col style={{ marginBottom:'2px' }} s span={12}>
              <label style={{ marginLeft: "18px", marginBottom: "25px" }}>Location</label>
              {field(
                '',
                "location",
                formik.values['location'],
                {
                  onChange: (e) => {
                    formik.customHandleChange(e, "location");
                    // here you dispatch your api for checking the email
                  },
                }
              )}</Col>


            <Col style={{ marginBottom:'2px' }} s span={12}>
              <label style={{ marginLeft: "18px", marginBottom: "25px" }}>Allowed User</label>
              {field(
                '',
                "allowedUsers",
                formik.values['allowedUsers'],
                {
                  onChange: (e) => {
                    formik.customHandleChange(e, "allowedUsers");
                    // here you dispatch your api for checking the email
                  },
                }
              )}</Col>

          </Row>
        </div>


        {/* <div style={{ marginTop: '20px' }}>
            <h3 style={{ fontWeight: "bold", marginLeft: "20px", marginBottom: '10px' }}> Subscription Details </h3>

            <div style={{ padding: '20px' }}>
              <Row xs={12} sm={12} md={12} lg={12} xl={12}>
                <HorizontalStatCard style={{ length: "20px" }} />
              </Row>
              <br />
              <Row xs={12} sm={12} md={12} lg={12} xl={12}>
                <HorizontalStatCard />
              </Row>
              <br />
              <Row xs={12} sm={12} md={12} lg={12} xl={12}>
                <HorizontalStatCard />
              </Row>
            </div>
          </div> */}
      </Col>
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Button
          style={{ width: 200, boxShadow: "0px 5px 5px 1px rgba(0,0,0,0.15)" }}
          sharpBorder size={"large"}
          block
          disabled={props.organizationState.loading ? true : false}
          onClick={() => formik.submitForm()}>
           {props.organizationState.loading? 'Loading..' : 'Add Organization'}
                </Button>
      </div>
    </>


    // ) : (
    //     <Redirect to="/" />
    //     // <div style={{ margin: "30vh 0", textAlign: "center" }}>
    //     //   <Empty description="User not found" />
    //     // </div>
    //   )
  ) : (
      <Loading />
    );
};
const mapStateToProps = (state) => ({ 
  userData: state.Users, 
  organizationState: state.Organization  
});
export default connect(mapStateToProps, {
  // changeUserStatusRequest,
  clearRequests,
  usersRequest,
  addOrganizationRequest
})(AddOrganization);
