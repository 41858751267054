import {
  MESSAGES_REQUEST,
  MESSAGES_SUCCESS,
  MESSAGES_ERROR,
  MESSAGE_DEL_REQUEST,
  MESSAGE_DEL_SUCCESS,
  MESSAGE_DEL_ERROR,
  MESSAGE_RESPONSE_REQUEST,
  MESSAGE_RESPONSE_SUCCESS,
  MESSAGE_RESPONSE_ERROR
} from "../configurations/Types"

const INITIAL_STATE = {
  totalCount: 0,
  messages: [],
  messageDelData:[],
  messageResponseData:[],
  loading: false
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case MESSAGES_REQUEST: {
      let newArr = [...state.messages]
      if (action.flag) {
        newArr = [];
      }
      newArr = [...newArr, 1, 2, 3, 4, 5, 6, 7, 8];
      return Object.assign({}, state, { totalCount: action.flag ? 0 : state.totalCount , messages: [], loading: true })
    }
    case MESSAGES_SUCCESS: {
      console.log("Messages Success", state , action)
      let arr = action.payload;
      // if (state.messages.length !== 0 && !action.flag) {
      //   let newArr = [...state.messages]
      //   newArr.splice( -8, 8, ...arr);
      //   arr = newArr;
      //   //arr=[...state.messages, ...action.payload.data.messages]
      // }
      return Object.assign({}, state,
        {
          totalCount: action.payload.total_count,
          messages: [...arr],
          loading: false,
        });
    }
    case MESSAGES_ERROR: {
        let newArr = [...state.messages]
        newArr.splice( -8, 8);
      return Object.assign({}, state, 
        {messages: newArr, loading: false})
    }
    case MESSAGE_DEL_REQUEST: {
      return Object.assign({}, state, { loading: true })
    }
    case MESSAGE_DEL_SUCCESS: {
      return Object.assign({}, state,
        {
          messageDelData: action.payload.data.message,
          loading: false,
        });
    }
    case MESSAGE_DEL_ERROR: {
      return Object.assign({}, state, 
        {messageDelData: [], loading: false})
    }
    case MESSAGE_RESPONSE_REQUEST: {
      return Object.assign({}, state, { loading: true })
    }
    case MESSAGE_RESPONSE_SUCCESS: {
      return Object.assign({}, state,
        {
          totalCount: action.payload.data.total_count,
          messageResponseData: action.payload.data.message,
          loading: false,
        });
    }
    case MESSAGE_RESPONSE_ERROR: {
      return Object.assign({}, state, 
        {messageResponseData: [], loading: false})
    }
    default: {
      return state;
    }
  }
}