import { Col, Row } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";

import useFormik from "../../components/formik/useFormik";
import Input from "../../components/input";
// import OptionsWrapper from "../../../components/input/SelectOptions";
// import SelectWrapper from "../../../components/input/Select";
import Button from "../../components/button";
import info from "../../components/message/index";
// import { updateOrganizationSchema } from '../../../configurations/Schemas/Organization';
// import {addUserSchema} from "../../../configurations/Schemas/addUser";
// import { updateOrganizationRequest } from '../../../actions/Organization'
import {
  buySubscriptionRequest,
  getSubscriptionStatus,
} from "../../../src/actions/Subscription";
import { usersRequest } from "../../../src/actions/Users";
// import Icon from '../../../components/Icon';
// import { logout } from "../../utils/index";
import { logout } from "../../utils/index";
// import Axios from "axios";

import StripeCheckout from "react-stripe-checkout";

import moment from "moment";
import { closeAllModal, closeModal } from "../../actions/Modal";
import { clearLocalStorage, getUserDetailRequest } from "../../actions/Users";

const SubscriptionModal = (props) => {
  const btnStyle = {
    border: "none",
    borderRadius: "3px",
    padding: "0 7px",
    cursor: "pointer",
    fontWeight: "bold",
  };
  const [userData, setUserData] = useState();

  const userDetail = useSelector((state) => state.Users.userDetail);
  const formik = useFormik({
    // validationSchema: addUserSchema,
    initialValues: {
      email: "",
      password: "",
      // pricePerUser: props.pricePerUser,
      // subscriptionPlanId: props.subscriptionPlanId == 2 ? 1 : 0,
      // allowedUsers: props.allowedUsers
    },
    onSubmit: (values, { ...rest }) => {
      // props.updateOrganizationRequest(props.organizationId, values,(res) => {
      //   if(res.status == 200){
      //     props.closeModal()
      //   }
      // })
    },
  });

  const dispatch = useDispatch();
  const data = useSelector((state) => {
    return state;
  });

  console.log("Dispatch_data: ", data);

  useEffect(() => {
    dispatch(getSubscriptionStatus());
  }, []);

  const subscriptionPackages = [
    { key: 1, value: "Standard Package" },
    { key: 2, value: "Premium Package" },
  ];

  function onSelectSubscription(packageId) {
    formik.setFieldValue("subscriptionPlanId", packageId);
  }

  const field = useCallback(
    (placeholder, name, value, customProps) => (
      <Col span={24}>
        <Input
          style={{ marginTop: 15, marginBottom: "0px" }}
          placeholder={placeholder}
          value={value}
          name={name}
          // disabled
          onChange={(e) => {
            //console.log(e.target.value, 'inside onChange func of ', name)
            formik.customHandleChange(e, name);
          }}
          onBlur={(e) => formik.customHandleBlur(e, name)}
          {...customProps}
        />
        <span className="dangerColor caption textCenter">
          {formik.touched[name] && formik.errors[name]}
        </span>
      </Col>
    ),
    [formik]
  );

  const [years, setyears] = useState(1);
  const [amount, setamount] = useState();

  //TODO uncomment this use effect.

  useEffect(() => {
    if (localStorage.getItem("reviseuserauth")) {
      var userData = JSON.parse(window.localStorage.getItem("reviseuserauth"));
      console.log(userData, "user data");

      setUserData(userData);
    }

    setamount();
    // userData.organization.price_per_user * userData.organization.allowed_users

    console.log(userData, "USER DATA");
  }, []);
  const { subscription = {} } = data?.Auth?.profileDetails;
  console.log("subsDATA ", subscription);
  function handleToken(receiptData) {
    console.log("receiptData: ", receiptData);

    const userId = JSON.parse(localStorage.getItem("reviseuserauth"))?.user?.id;
    const objSubscriptionData = {
      receiptData: receiptData.id,
      startDate: moment().format("MM/DD/YYYY"),
      endDate: moment().add(years, "year").format("MM/DD/YYYY"),
      productId: subscription?.subscriptionPackage,
    };
    // dispatch(buySubscriptionRequest(objSubscriptionData,()=> {
    //   props.callback();
    //           props.closeModal();
    // }))
    props.buySubscriptionRequest(objSubscriptionData, (res) => {
      if (res.status == 200) {
        // props.closeModal()

        props.getSubscriptionStatus(
          () => {
            // props.callback();
            dispatch(closeAllModal());
            dispatch(
              usersRequest(
                {
                  search: "",
                  limit: 10,
                  offset: 0,
                },
                userId
              )
            );
            // props.closeModal();
          },
          userData.user_id,
          "subscription"
        );
      }
    });

    // Axios.post('https://clients3.5stardesigners.net:5000/revisebeat/api/v1/organization/user/subscription', {
    //  token,
    //  organization_id: 51,
    //  amount:7,
    //   duration: 12
    // })
    // .then(function (response) {
    //   console.log(response , "res");
    // })
    // .catch(function (error) {
    //   console.log(error , "err");
    // })
  }
  useEffect(() => {
    // if()

    if (
      userData?.organization?.subscription_package !==
        userDetail?.organization?.subscription_package ||
      userData?.organization?.allowed_users !==
        userDetail?.organization?.allowed_users ||
      userData?.organization?.price_per_user !==
        userDetail?.organization?.price_per_user
    ) {
      localStorage.setItem(
        "reviseuserauth",
        JSON.stringify({ ...userData, ...userDetail })
      );
      window.location.reload();
    }
  }, [userDetail]);
  const subscribePackage = () => {
    console.log("LLLLLLL");
    props.getUserDetailRequest();
  };

  return (
    <Row type="flex" justify="center" align="middle">
      <Col span={24} justify="center" align="middle" style={{ marginTop: 20 }}>
        <h3 className="fontBold" style={{ color: "#C3F64D" }}>
          Subscription Package
        </h3>
        <h4 style={{ textTransform: "capitalize" }}>
          {subscription?.subscriptionPackage} Package
        </h4>
      </Col>

      <Col span={24} justify="center" align="middle" style={{ marginTop: 10 }}>
        <h4 style={{ fontWeight: "bold" }}>
          {userDetail.organization
            ? userDetail?.organization?.subscription_package
            : userData?.organization?.subscription_package}
        </h4>
      </Col>

      <Col span={24} justify="center" align="middle" style={{ marginTop: 10 }}>
        <p style={{}}>
          Price Per User:{" "}
          <span style={{ color: "#C3F64D" }}>
            £ {subscription?.pricePerUser || "-"}
          </span>
        </p>
      </Col>

      <Col span={24} justify="center" align="middle" style={{ marginTop: 10 }}>
        <p style={{}}>
          Total Amount:{" "}
          <span style={{ color: "#C3F64D" }}>
            £{" "}
            {subscription?.pricePerUser * subscription?.usersLimit * years ||
              "-"}
          </span>
        </p>
        {console.log("AMOUNTTTTT", amount)}
      </Col>

      <Col span={24} justify="center" align="middle" style={{ marginTop: 10 }}>
        <p style={{}}>
          Number of Users:{" "}
          <span style={{ color: "#C3F64D" }}>
            {subscription?.usersLimit || "-"}
          </span>
        </p>
      </Col>

      <Col span={24} justify="center">
        <Row
          type="flex"
          justify="center"
          align="middle"
          style={{ marginTop: "5px" }}
        >
          <Col xs={6} sm={6} md={4} lg={6}>
            Duration:
          </Col>
          {/* <Col xs={14} sm={14} md={14} lg={12}> */}
          {/* <Row gutter={[10,10]} justify='space-between'
        style={{
          display:'flex',
          alignItems:'center',
          // width:'100%'
        }}
        > */}
          <Col xs={2} sm={2} md={2} lg={2}>
            <button
              style={btnStyle}
              onClick={() => {
                if (years > 1) {
                  setyears(years - 1);
                  setamount(
                    parseInt(subscription?.amount) -
                      parseInt(subscription?.pricePerUser) *
                        parseInt(subscription?.usersLimit)
                  );
                } else {
                  info("error", `${"Year range cannot be less then 1 year"}`);
                }
              }}
            >
              -
            </button>
          </Col>
          <Col xs={7} sm={7} md={5} lg={5} style={{ textAlign: "center" }}>
            {years} Year{years > 1 && "s"}
          </Col>
          <Col xs={2} sm={2} md={2} lg={2}>
            <button
              style={btnStyle}
              onClick={() => {
                if (years < 12) {
                  setyears(years + 1);
                  setamount(
                    parseInt(subscription?.amount) +
                      parseInt(subscription?.pricePerUser) *
                        parseInt(subscription?.usersLimit)
                  );
                } else {
                  info(
                    "error",
                    `${"Year range cannot be greater then 12 years "}`
                  );
                }
              }}
            >
              +
            </button>
          </Col>
          {/* </Row> */}

          {/* </Col> */}
        </Row>
      </Col>

      {/* <div style={{   boxShadow: "0px 10px 5px 0px rgba(227,185,185,0.75)" , display: "flex" , alignSelf: "center" , justifyContent: "center"}}>
        <span style={{}}>a</span>

      </div> */}

      <Col span={24} justify="center" align="middle" style={{ marginTop: 20 }}>
        {/* <Button
          style={{ width: 200}}
          sharpBorder size={"large"}
          // block
          //   disabled={props.organizationState.loading ? true : false}
          onClick={() => formik.submitForm()}>
          {props.organizationState.loading ?
            <Icon type="loading" style={{ fontSize: "1rem" }} /> : 'Subscribe'}
        </Button> */}
      </Col>

      {/* <div  style={{display: "flex" , flexDirection: "column"}} >
      <div style={{ marginTop:'40px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Button
          style={{ width: 200, boxShadow: "0px 5px 5px 1px rgba(0,0,0,0.15)" }}
          sharpBorder size={"large"}
          block
        //   disabled={props.organizationState.loading ? true : false}
          onClick={() => formik.submitForm()}>
           {props.organizationState.loading? 
           <Icon type="loading" style={{ fontSize: "1rem" }} /> : 'Subscribe' } 
                </Button>
      </div>

     
      </div> */}

      <StripeCheckout
        // name={"jsndjdnj"}
        style={{
          width: "40%",
          color: "#AD1E23",
          backgroundColor: "#AD1E23",
          backgroundImage: null,
        }}
        textStyle={{ color: "white", backgroundImage: "" }}
        amount={amount * 100}
        currency={"GBP"}
        stripeKey="pk_live_51MAWmlD9xspcimQhyIWwtYU5NOYACtMHN1vewCUxoDoKHcjeTuf2mYEuOPwvRijtH3Xvzgdg3i56E1sJd2omi0Yq00B7o4lFEI" //"pk_test_51IpIZlLXheu8Cx1yhx6FSN3oND3LSErnhDlfeaiIEhUPUpGZb8vhiHVmslGfRTAgxUt04d5adFxFy24WFl7QEEm600cTUXXj1e"
        token={handleToken}
        shippingAddress
        billingAddress
        triggerEvent="onClick"
      >
        <Button onClick={subscribePackage} isGreen={true}>
          Subscribe
        </Button>
      </StripeCheckout>

      <Col span={24} justify="center" align="middle" style={{ marginTop: 20 }}>
        {/* <Button
          style={{ width: 150, boxShadow: "0px 5px 5px 1px rgba(0,0,0,0.15)" , backgroundColor: "white" , color: "#AC1D22" }}
          sharpBorder size={"large"}
          block
        //   disabled={props.organizationState.loading ? true : false}
          onClick={() => formik.submitForm()}>
           {props.organizationState.loading? 
           <Icon type="loading" style={{ fontSize: "1rem" }} /> : 'Logout' } 
                </Button> */}
        <p
          style={{ color: "#1E1E22", fontWeight: "bold", cursor: "pointer" }}
          onClick={() => {
            props.closeModal();
            props.clearLocalStorage();
            logout();
          }}
        >
          Logout
        </p>
      </Col>
    </Row>
  );
};

export default connect((state) => ({ organizationState: state.Organization }), {
  // updateOrganizationRequest,
  closeModal,
  buySubscriptionRequest,
  getSubscriptionStatus,
  getUserDetailRequest,
  clearLocalStorage,
})(SubscriptionModal);
