import React, { useState } from "react";
import { connect, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Row, Col } from "antd";
import { emailValidations } from "../../configurations/Schemas/index";
import AuthContainer from "../../appcomponents/shares/auth/AuthContainer";
import Input from "../../components/input/index";
import RootForm from "../../components/form/index";
import { passwordResetRequest } from "../../actions/Auth";

const ForgotPassword = (props) => {
  const [state, setState] = useState({
    email: "",
  });
  const history = useHistory();
  const data = useSelector((state) => {
    return state;
  });

  const handleChanged = (e) => {
    const { name, value } = e.target;
    setState({
      [name]: value,
    });
  };
  const changeEmail = (value) => {
    setState({ email: value });
  };

  const bottomLink = {
    text: "BACK TO LOGIN",
    onClick: () => {
      history.replace("login");
    },
  };

  return (
    <AuthContainer bootomLink={bottomLink}>
      <Row type="flex" justify="center" align="middle" gutter={[10, 30]}>
        <Col span={24}>
          <h3 className="mr-t mr-b">Reset Your Password</h3>
          <p>Enter email address.</p>
        </Col>
        <Col xs={24} sm={22}>
          <RootForm
            schemaName={emailValidations}
            initialValues={state}
            changeEmail={changeEmail}
            apiCall={passwordResetRequest}
            loading={data.Auth.forgotPasswordLoading}
            cb={() => {
              history.push({
                pathname: "/verify-code",
                state: {
                  email: state.email,
                },
              });
            }}
            controls={[
              {
                span: 24,
                name: "email",
                value: state.email,
                handleChanged: handleChanged,
                placeholder: "Email",
                Type: Input,
              },
            ]}
            submitButton={{
              span: 14,
              buttonText: "Continue",
              className: "mr-t-lg mr-b-md bg-gradient",
            }}
          />
        </Col>
      </Row>
    </AuthContainer>
  );
};

// export default ForgotPassword

export default connect((storeState) => ({ Auth: storeState.Auth }))(
  ForgotPassword
);
