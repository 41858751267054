import React from 'react';
import { Layout, Row, Col } from 'antd';
import styled from 'styled-components';
import Card from '../../../components/card/index';
//import LogoImg from '../../../assets/images/logo.svg';
import LogoImg from "../../../assets/images/LoginIcons.png";
// import footerLogo from "../../../assets/images/Logo-footer.svg";
import Image from '../../../components/image/index';
import ContentWrapper from '../../../components/maincontent';

const AuthCard = styled(Card)`
  box-shadow: 0 5px 20px #1E1E220C;
  border-radius: 10px;
  border: none;
  & > .ant-card-body {
    padding: 2rem 4rem;
  }
`
const LogoImage = styled(Image)`
  margin-bottom: 5%;
  width: calc(100% - 55%);
  object-fit: contain
`
const ContentWrap = styled(ContentWrapper)`
  margin: 0;
  padding: 0;
  min-height: auto;
`
const BottomLink = styled.p`
  margin: 30px auto;
  color: #ffffff;
  font-size: 12px !important;
  text-decoration: underline;
  cursor: pointer;
`;
const LayoutFlow = styled(Layout)`
  height: 100vh;
  justify-content: center;
  background-color: #151D30 ;
  text-align: center;
  .input {
    background:  blue;
  }
  .card-link-out {
    margin: auto;
    text-decoration: none;

    &:hover, &:active, &:focus {
      text-decoration: none;
    }

    @media screen and (max-width: 767px){
      position: static;
      bottom: 0;
    }
  }
`
const OAuthCard = (props) => {
  return (
    <ContentWrap>
      <LayoutFlow>
        <Row type="flex" justify="center" align="middle" >
          <Col xxl={6} xl={7} lg={12} md={16} sm={20} xs={24}>
            {props.withImage && <LogoImage src={LogoImg} alt='Lockey Admin Panel' />}
            <AuthCard {...props} />
          </Col>
        </Row>
        {props.bootomLink && (
          <BottomLink
            onClick={() => {
              props.bootomLink.onClick();
            }}
            style={{ display: props?.bootomLink?.isHidden && "none" }}
          >
            {props.bootomLink.text}
          </BottomLink>
        )}
      </LayoutFlow>
    </ContentWrap>
  )
}

export default OAuthCard