import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Row, Col, Skeleton } from "antd";
import { openModal } from "../../../actions/Modal";
import ContentHeader from "../../../appcomponents/shares/contentheader";
import {
  organizationRequest,
} from "../../../actions/Organization";
// import { changeUserStatusRequest } from "../../../actions/Users";

import PopconfirmWrapper from "../../../components/pop-confirm";
import { FiAlertTriangle, FiChevronsDown } from "react-icons/fi";
import styled from "styled-components";
import Blip from "../../../appcomponents/shares/blip";
import TableWrapper from "../../../components/react-table";
import TagWrapper from "../../../components/tag";
import SwitchWrapper from "../../../components/switch";
import AvatarWrapper from "../../../components/avatar/index"

import EmptyProfilePic from "../../../assets/images/empty-profile.png";

const ScrollDown = styled(FiChevronsDown)`
  position: absolute;
  top: auto;
  bottom: auto;
  //bottom: 80px;
  left: 0;
  right: 0;
  //margin: -40px auto 0;
  margin: 0 auto 0;
  font-size: 1.5vw;
  animation: move 3s ease-out infinite;
  color: ${props => props.theme[props.theme.mode].primary.main};
  @keyframes move {
    25% {
      opacity: 1;
    }
    33% {
      opacity: 1;
      transform: translateY(30px);
    }
    67% {
      opacity: 1;
      transform: translateY(40px);
    }
    100% {
      opacity: 0;
      transform: translateY(55px) scale3d(0.5, 0.5, 0.5);
    }
  }
  @media screen and (max-width: 991px) {
    font-size: 1.8rem;
  }
`;
const Organization = props => {
  const [offSet, setOffSet] = useState(0);
  const [toggle, setToggle] = useState(false);
  const [state, setState] = useState({
    offset:0,
    limit: 10,
    search: "",
    active:undefined,
  });
  const [init, setInit] = useState(false);
  const [mount, setMount] = useState(false);
  const [hasScrolled, setHasScrolled] = useState(false);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [tableChanged, setTableChanged] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState(null);

  let map = {
    "1": "primary",
    "2": "secondary",
  };
  

  useEffect(() => {
    if (mount) {
      props.organizationRequest(state.limit, state.offSet, state.search, state.active);
    }
  }, [toggle, tableChanged]);
  //  for Search and Filter
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    //document.body.addEventListener("touchmove", handleScroll);
    if (!mount) {
      props.organizationRequest(state.limit, state.offSet, state.search, state.active);
    }
    setMount(true);
    return () => {
      window.removeEventListener("scroll", handleScroll);
      //document.body.removeEventListener("touchmove", handleScroll);
    };
  }, []);

  useEffect(() => {
    if (mount || init) {
      setToggle(!toggle);
    }
  }, [state.search, state.active, offSet, init]);

  useEffect(() => {
    if (mount) {
      if (
        !loading &&
        !props.organizationState.loading &&
        props.organizationState.totalCount > props.organizationState.length
      ) {
        setLoading(true);
        setOffSet(prev => prev + 8);
      }
    }
  }, [hasScrolled]);

  useEffect(() => {
    if (mount) {
      if (!props.organizationState.loading) {
        setLoading(false);
      }
    }
  }, [props.organizationState.loading]);

  const handleScroll = () => {
    if (
      window.innerHeight + window.pageYOffset >=
      document.getElementById("root").clientHeight - 5
    ) {
      setHasScrolled(prev => !prev);
    }
  };
  const searchChange = e => {
    setOffSet(0);
    setState({ ...state, search: e });
  };

  const filterChange = e => {
    setOffSet(0);
    setState({ ...state, active: e });
  };
 

  return (
    <React.Fragment>
      <Row
        type="flex"
        gutter={[15, 20]}
        justify="space-between"
      >
        <Col span={24}>
          <ContentHeader
            showAddButton
            onClick={() => props.history.push('/addOrganization')}
            searchChange={searchChange}
            filterChange={filterChange}
            title="Organization"
            count={props.organizationState.organization.length}
            options={[
              { label: "View All", value: "" },
              {
                label: (
                  <Row
                    type="flex"
                    align="middle"
                    justify="center"
                    gutter={[5, 0]}
                  >
                    <Col>
                      <Blip color="#22E6AA" margin={"0 0.3vw 0 0"} />
                    </Col>

                    <Col>Active user</Col>
                  </Row>
                ),
                value: 1,
              },
              {
                label: (
                  <Row
                    type="flex"
                    align="middle"
                    justify="center"
                    gutter={[5, 0]}
                  >
                    <Col>
                      <Blip color="#717070" margin={"0 0.3vw 0 0"} />
                    </Col>

                    <Col>Inactive user</Col>
                  </Row>
                ),
                value: 0,
              },
            ]}
          />
        </Col>
        <Col span={24}>
              <TableWrapper
                tableData={props.organizationState.organization}
                getData={state.apiCall}
                search={search}
                // filter={filter}
                // filters={filters}
                pageSize={10}
                dataCount={props.organizationState.totalCount}
                getTrProps={(state, rowInfo, column) => ({
                  onClick: () => props.history.push({
                    pathname: `/organization/${rowInfo.original.user_id}`,

                    state: {
                      referrer: "/users",
                      // referrerState: {
                      //   transaction_type: filters.transaction_type,
                      //   month: filters.month,
                      //   year: filters.year,
                      //   like: filters.like,
                      // },

                      organizationId: rowInfo.original.organization.id,

                    },
                  }),
                  style: { cursor: "pointer", overflowX: "hidden" },
                })}
                getTdProps={(state, rowInfo, column, c, d) => {
                  return column.className === "clickable"
                    ? {
                      onClick: (e) => e.stopPropagation(),
                      style: { cursor: "default", overFlow: "hidden" },
                    }
                    : {};
                }}
                //enableReInit={true}
                tableChanged={tableChanged}
                content={[
                  {
                    name: "",
                    id: "thumbImageUrl",
                    centered: true,
                    small: true,
                    sortable: false,
                    render: (r) => {
                      return (
                        <AvatarWrapper
                          shape="circle"
                          size="large"
                          src={r.original["thumbImageUrl"] || EmptyProfilePic}
                        ></AvatarWrapper>
                      );
                    },
                  },
                  {
                    name: `Organization`,
                    subName: 'User Name' ,
                    id: "fullName",
                    leftAligned:true,
                    render: (r) => {
                      return (
                        <div>
                        <p
                          style={{ justifySelf:'left', cursor: "pointer", textAlign:'left', alignSelf:'left' }}
                          onClick={() => {
                            setSelectedUserId(r?.original?.id);
                          }}
                        >
                          {r?.original?.organization?.name}
                        </p>
                        <p
                        style={{ cursor: "pointer", textAlign:'left', color:'grey'  }}
                        onClick={() => {
                          setSelectedUserId(r?.original?.id);
                        }}
                      >
                        {r?.original?.organization?.owner_name}
                      </p>
                      </div>
                      );
                    },
                  },
                  {
                    name: "Email",
                    centered: true,
                    id: "email",
                    render: (r) => {
                      return (
                        <p style={{ whiteSpace:'nowrap', textOverflow:'ellipsis', overflow:'hidden' }}
                          onClick={() => {
                            setSelectedUserId(r?.original?.id);
                          }}
                        >
                          {r?.original?.email}
                        </p>
                      );
                    },
                  },
                  // {
                  //   name: "User Type",
                  //   id: "usertype",
                  //   className: "clickable",
                  //   sortable: false,
                  //   render: (r) => {
                  //     return (
                  //       <span
                  //         style={{ cursor: "pointer" }}
                  //         onClick={() => {
                  //           setSelectedUserId(r.original.type);
                  //         }}
                  //       >
                  //         {r.original.type}
                  //       </span>
                  //     );
                  //   },
                  // },
                  {
                    name: "Price per User",
                    id: "usertype",
                    centered: true,
                    className: "clickable",
                    sortable: false,
                    render: (r) => {
                      return (
                        <span
                          style={{ cursor: "pointer", }}
                          onClick={() => {
                            setSelectedUserId(r.original.type);
                          }}
                        >
                          {r?.original?.organization?.price_per_user}
                        </span>
                      );
                    },
                  },
                  {
                    name: "Allowed User",
                    id: "usertype",
                    centered: true,
                    className: "clickable",
                    sortable: false,
                    render: (r) => {
                      return (
                        <span
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            setSelectedUserId(r?.original?.type);
                          }}
                        >
                          {r?.original?.organization?.allowed_users ? 
                          `${r?.original?.organization?.allowed_users}/3000` : null}
                        </span>
                      );
                    },
                  },
                  {
                    name: "Status",
                    id: "status",
                    className: "clickable",
                    centered: true,
                    sortable: false,
                    render: (r) => (
                      <TagWrapper type={r.original.status}>
                          { r.original && r.original.status === "free" ? "Free"
                          : r.original.status === "subscribed" ? "Subscribed" 
                          : r.original.status === "pending" ? "Pending" 
                            : "Expired"}
                      </TagWrapper>
                    ),
                  },

                  // {
                  //   name: "Actions",
                  //   id: "actions",
                  //   sortable: false,
                  //   className: "clickable",
                  //   centered: true,
                  //   render: (r) => {
                  //     return (
                  //     <Row type="flex" style={{ width: "100%" }} align="middle">
                  //       <Col>
                  //         <PopconfirmWrapper
                  //           title={
                  //             <h4>
                  //               Are you sure you want to
                  //             {r.original["active"] === 0
                  //                 ? " activate "
                  //                 : " deactivate "}
                  //             this user?

                  //           </h4>
                  //           }
                  //           icon={
                  //             <FiAlertTriangle
                  //               style={{
                  //                 position: "absolute",
                  //                 color: "#f44336",
                  //                 left: 0,
                  //                 top: 0,
                  //                 marginTop: 6,
                  //               }}
                  //             />
                  //           }
                  //           cancelText="No"
                  //           okText="Yes"
                  //           onConfirm={function () {
                  //             props.changeUserStatusRequest(

                  //               {
                  //                 user_id: r.original["user_id"],
                  //                 status: r.original["active"] === 0 ? 1 : 0,
                  //               },
                  //               (res) => setTableChanged(!tableChanged)
                  //             );
                  //           }}
                  //         >
                  //           <SwitchWrapper
                  //             checked={r.original && r.original.active !== 0}
                  //             style={{ marginRight: 10 }}
                  //           //onClick={handleSwitch}
                  //           />
                  //         </PopconfirmWrapper>
                  //       </Col>
                  //       <Col>
                  //         <p style={{ fontWeight: 600 }}>
                  //           User is{" "}
                  //           {r.original["active"] !== 0
                  //             ? "Active"
                  //             : "In-Active"}
                  //         </p>
                  //       </Col>
                  //     </Row>
                  // )},
                  // },
                ]}
              />
            </Col>
      </Row>
     
      {/* {selectedUser && 
              <FloatingUserCard 
              user={selectedUser}
              //onToggle={() => setUserChanged(!userChanged)}
              onClose={() => setSelectedUser(null)} 
              />
            } */}
    </React.Fragment>
  );
};

export default connect(state => ({ organizationState: state.Organization }), {
  openModal,
  organizationRequest,
  // changeUserStatusRequest,
})(Organization);
