import React from "react";
import { Row, Col, Skeleton } from "antd";
import CardWrapper from "../../components/card";
import Image from "../../components/image";
import {FiPower} from "react-icons/fi";
import { logout } from "../../utils/index";
import { useHistory } from "react-router";
// import {logout} from "../../"

import styled from "styled-components";

const StyledCard = styled(CardWrapper)`
  & > .ant-card-body {
    // padding: 0.5rem 0.5rem 0.5rem 0.5rem;
  }
`;

const SettingsCard = (props) => {
  const history  = useHistory();
  return(
  <StyledCard className={props.styledClass}>
    <Skeleton loading={props.loading} avatar active>
      <Row
        type="flex"
        align="middle"
        // justify="space-between"
        
        style={{ textAlign: "left", height:'100%' , cursor: props?.logout && "pointer" }}
        onClick={()=> {
          props.logout ? logout() :
          history.push({
            pathname:  "/change-password",
            state: {
              // response: messageFromServer 
            } 
         });
        }
        }
      >
        {props.logout && 
        <Col xs={4} lg={1}>
        <FiPower
        size={20}

        />
        </Col>
        }
        
        <Col xs={20} lg={23}>
        
          <h4 style={{ color:'black', fontWeight:'500' , cursor: props?.logout && "pointer" }}   >
            {props.title}
          </h4>
        </Col>
      </Row>
      
    </Skeleton>
  </StyledCard>
  )
      };

export default SettingsCard;
