import { put, takeLatest, cancelled } from 'redux-saga/effects';
import { USER_STATS_REQUEST, MONTHLY_USER_STATS_REQUEST, APP_DOWNLOADS_REQUEST, GAME_STATS_REQUEST, EARNING_STATS_REQUEST, WAGERS_STATS_REQUEST } from "../configurations/Types";
import { userStatsSuccess, gameStatsSuccess, earningStatsSuccess, wagersStatsSuccess, monthlyUserStatsSuccess, appDownloadsSuccess } from "../actions/Dashboard";
import { USER_STATS_ENDPOINT, GAME_STATS_ENDPOINT, APP_DOWNLOADS_ENDPOINT, EARNING_STATS_ENDPOINT, WAGERS_STATS_ENDPOINT } from "../configurations/Constants";
// import { userStatsData } from "../mocks/Users"
// import { graphData, pieCharts } from "../mocks/Graphs"

import { privateAgent, CancelToken } from "../configurations/AxiosAgent";

const userStatsAPI = () => privateAgent.get(USER_STATS_ENDPOINT)
const gameStatsAPI = () => privateAgent.get(GAME_STATS_ENDPOINT)

const earningStatsAPI = (duration, token) => privateAgent.get(EARNING_STATS_ENDPOINT, { cancelToken: token.token ,params: { duration } })
const wagersStatsAPI = (duration, token) => privateAgent.get(WAGERS_STATS_ENDPOINT, { cancelToken: token.token, params: { duration } })

const monthlyUserStatsAPI = (params) => privateAgent.get(USER_STATS_ENDPOINT, { params: params })
const appDownloadsAPI = (params) => privateAgent.get(APP_DOWNLOADS_ENDPOINT, { params: params })

let earningsToken;
let wagersToken;

//call user API
function* getUserStats(action) {
  let res;
  try {
  res = yield userStatsAPI();
  yield put(userStatsSuccess(res.data.data));
  }
  catch (e) {
    console.log("ERROR", e);
  }
  finally {
    if (yield cancelled()) {
      console.log("CANCELLED");
    }
  }
  if(action.callback) {
    action.callback(res);
  }
}

//call games API
function* getGameStats(action) {
  let res;
  try {
  res = yield gameStatsAPI();
  yield put(gameStatsSuccess(res.data.data));
  }
  catch (e) {
    console.log("ERROR", e);
  }
  finally {
    if (yield cancelled()) {
      console.log("CANCELLED");
    }
  }
  if(action.callback) {
    action.callback(res);
  }
}

//call Earning API
function* getEarningStats(action) {
  let res;
  try {
  if (earningsToken) {
    earningsToken.cancel();
  }
  earningsToken = CancelToken.source();
  res = yield earningStatsAPI(action.duration, earningsToken);
  yield put(earningStatsSuccess(res.data.data));
  }
  catch (e) {
    console.log("ERROR", e);
  }
  finally {
    if (yield cancelled()) {
      console.log("CANCELLED");
    }
  }
  if(action.callback) {
    action.callback(res);
  }
}

//call Wagers API
function* getWagersStats(action) {
  let res;
  try {
  if (wagersToken) {
    wagersToken.cancel();
  }
  wagersToken = CancelToken.source();
  res = yield wagersStatsAPI(action.duration, wagersToken);
  yield put(wagersStatsSuccess(res.data.data));
  }
  catch (e) {
    console.log("ERROR", e);
  }
  finally {
    if (yield cancelled()) {
      console.log("CANCELLED");
    }
  }
  if(action.callback) {
    action.callback(res);
  }
}

//call Monthly Users API
function* getMonthlyUserStats(action) {
    let res;
    try {
    res = yield monthlyUserStatsAPI(action.params);
    yield put(monthlyUserStatsSuccess(res.data.data));
    }
    catch (e) {
      console.log("ERROR", e);
    }
    finally {
      if (yield cancelled()) {
        console.log("CANCELLED");
      }
    }
    if(action.callback) {
      action.callback(res);
    }
  }

//call app download API
  function* getAppDownloads(action) {
    let res;
    try {
    res = yield appDownloadsAPI(action.params);
    console.log("GET APP DOWNLOADS RUN", res);
    yield put(appDownloadsSuccess(res.data.data));
    }
    catch (e) {
      console.log("ERROR", e);
    }
    finally {
      if (yield cancelled()) {
        console.log("CANCELLED");
      }
    }
    if(action.callback) {
      action.callback(res);
    }
  }


// Our watcher Saga: spawn a new getUsers task on each USERS_REQUEST
export function* watchGetStats() {
  yield takeLatest(USER_STATS_REQUEST , getUserStats);
  yield takeLatest(GAME_STATS_REQUEST , getGameStats);
  yield takeLatest(EARNING_STATS_REQUEST , getEarningStats);
  yield takeLatest(WAGERS_STATS_REQUEST , getWagersStats);
  yield takeLatest(MONTHLY_USER_STATS_REQUEST, getMonthlyUserStats);
  yield takeLatest(APP_DOWNLOADS_REQUEST, getAppDownloads);
}