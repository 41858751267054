import React from "react";
import { 
  // Link,
   withRouter } from "react-router-dom";
// import { Menu } from "antd";
import { connect } from "react-redux";
import { Row, Col,
  //  Divider
   } from "antd";
// import { FiChevronRight } from "react-icons/fi";
// import PopoverWrapper from "../popover/index";
// import DropdownWrapper from "../dropdown";
// import MenuWrapper from "../menu";
// import Badge from "../badge/index";
// import Avatar from "../avatar/index";
// import Icon from "../Icon/index";
// import Button from "../button/index";
// import { logout } from "../../utils/index";
import BackButton from "../../appcomponents/shares/header/BackButton";
import PropTypes from 'prop-types';

class ExternalHeader extends React.Component {
  render() {
    return (
      <Row
        type="flex"
        align="middle"
        justify="space-between"
        style={{
          height: "100%",
          lineHeight: 0
          //flexWrap: "nowrap"
        }}
        //gutter={[{xs: 20, sm: 30}, 20]}
      >
        {/* For back button */}
        <Col xs={this.props.maintainSpans ? this.props.bisect ? 12 : 8 : 24} md={this.props.bisect ? 12 : 8}>
          {/* <Row
            type="flex"
            align="middle"
            gutter={[{xs: 20, sm: 30}, {xs: 20, sm: 30}]}
            //justify="center"
          >
            <Col xs={24} sm={this.props.leftAlign ? 12 : 24}> */}
              <BackButton
                title={this.props.App.backButtonLabel}
                action={
                  () =>
                    this.props.history.push(
                      this.props.location.state
                        ? this.props.location.state.referrer
                          ? this.props.location.state.referrerState
                            ? { pathname: this.props.location.state.referrer, state: this.props.location.state.referrerState }
                            : this.props.location.state.referrer
                          : "/"
                        : "/"
                    )
                  //this.props.history.goBack()
                }
              />
            {/* </Col>
            {this.props.leftAlign && 
              <Col xs={24} sm={12}>
                {this.props.leftAlign}
              </Col>
            }
          </Row> */}
        </Col>

        {/* For middle content */}
        {!this.props.bisect && <Col xs={this.props.maintainSpans ? 8 : 24} md={8}>
          <Row
            type="flex"
            align="middle"
            //justify="center"
          >
              {this.props.middleAlign && this.props.middleAlign.map((val) => {
                  return (
                      <Col span={24}>
                        {val}
                      </Col>
                  )
              })}
          </Row>
        </Col>}

        {/* For right content */}
        <Col xs={this.props.maintainSpans ? this.props.bisect ? 12 : 8 : 24} md={this.props.bisect ? 12 : 8}>
          <Row
            type="flex"
            align="middle"
            // justify="end"
          >
              {this.props.rightAlign && this.props.rightAlign.map((val) => {
                  return (
                      <Col span={24} style={{ textAlign: "right" }}>
                        {val}
                      </Col>
                  )
              })}
          </Row>
        </Col>
      </Row>
    );
  }
}

ExternalHeader.propTypes = {
    middleAlign: PropTypes.array,
    rightAlign: PropTypes.array,
  };

export default withRouter(
  connect(state => ({
    App: state.App
  }))(ExternalHeader)
);
