import * as Yup from "yup";

export const addOrganizationSchema = Yup.object().shape({
     name: Yup.string()
    .matches(/^([A-Za-z0-9 _.,!"'-]*)$/i, "No special characters allowed")
    .required("Please provide name"),

    email: Yup.string()
    .matches(
      /^([0-9a-zA-Z]+[.-_]{0,1})+@([0-9a-zA-Z][0-9a-zA-Z-]+\.)+[a-zA-Z]{2,}$/i,
      "Email is not valid!"
    )
    .required("Email Required"),

    subscriptionPlanId: Yup.string()
    .required("Please provide subscription plan"),

    ownerName: Yup.string()
    .matches(/^([A-Za-z0-9 _.,!"'-]*)$/i, "No special characters allowed")
    .required("Please provide owner name"),


    organizationName: Yup.string()
    .matches(/^([A-Za-z0-9 _.,!"'-]*)$/i, "No special characters allowed")
    .required("Please provide organization name"),


    pricePerUser: Yup.string()
    .matches(
    /(^\d*\.?\d*[0-9]+\d*$)|(^[0-9]+\d*\.\d*$)/,
    "Please provide a valid amount"
     )
    .required("Please provide price"),
//   .notOneOf(["0"], "Please provide an amount"),


    allowedUsers: Yup.string()
    .required("Please provide allowed users"),

    location: Yup.string()
    .required("Please provide location"),

    phone: Yup.string()
    .min(6, "Phone Number is too Short! it must be between 6 and 16 characters")
    .max(16, "Phone Number is too Long! it must be between 6 and 16 characters")
    .matches(
        /(^\d*\.?\d*[0-9]+\d*$)|(^[0-9]+\d*\.\d*$)/,
        "Please provide a valid Phone Number"
      )
      .required("Please provide phone number"),

});


export const updateOrganizationSchema = Yup.object().shape({
 subscriptionPlanId: Yup.string()
 .required("Please provide subscription plan"),

 pricePerUser: Yup.string()
 .matches(
 /(^\d*\.?\d*[0-9]+\d*$)|(^[0-9]+\d*\.\d*$)/,
 "Please provide a valid amount"
  )
 .required("Please provide price"),
//   .notOneOf(["0"], "Please provide an amount"),


allowedUsers: Yup.string()
 .matches(/^\d+$/, "Enter valid number of users")
 .required("Please provide allowed users"),
});

