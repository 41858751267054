import React from "react";
import styled from "styled-components";
import { Input } from "antd";

const StyledInput = styled(Input).attrs({ className: "inputField" })`
  &[type],
  input {
    background-color: ${(props) =>
      props.isSearch ? props.theme[props.theme.mode].primary.main :
      props.theme[props.theme.mode].background.secondary
    };
    color: ${(props) => props.isSearch?'#FFFFFF' : props.theme[props.theme.mode].textColor.primary};
    border: ${(props) =>
      props.border ? props.border : props.isSearch ? `2px solid #151D30` : ""};
    border-radius: ${(props) =>
      props.borderRadius ? props.borderRadius : `8px`
    };
  }
  &[type]:hover,
  input:hover {
    border: ${(props) => (props.border ? props.border : undefined)};
    border-color: #51515b;
    //  ${(props) => props.theme[props.theme.mode].primary.main} !important;
  }
  &[type]:focus,
  input:focus {
    border: ${(props) => (props.border ? props.border : undefined)};
    border-color: #51515b;
    // ${(props) => props.theme[props.theme.mode].primary.main} !important;
    box-shadow: 0 0 0 2px
      ${(props) => props.theme[props.theme.mode].primary.main}66;
  }
  * {
    // color: ${(props) => props.isSearch ?'#FFFFFF' : '#000'};
    // ${(props) => props.theme[props.theme.mode].background.green};
  }
  &.lightBg {
    background-color: rgba(255, 255, 255, 0.5);
  }
  input::placeholder {
    color: ${(props) => (
      props.isSearch ? '#FFFFFF': '#151D30'
      )} !important;
  }
`;

const InputWrapper = (props) => {
  return props.password ? (
    <StyledInput as={Input.Password} {...props}></StyledInput>
  ) : (
    <StyledInput {...props}></StyledInput>
  );
};

export default InputWrapper;
