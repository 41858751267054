//Left side bar menu content 
import React from "react";
import { Menu } from "antd";
import { withRouter, Link } from "react-router-dom";
import MenuWrapper from "../menu";
import Image from "../../components/image";
import Logo from "../../assets/images/Vector-Smart-Object.png";
import HomeIcon from "../../assets/icons/navbar/dashboard-line2.svg";
import UsersIcon from "../../assets/icons/navbar/group-line.png";
//import RequestsIcon from "../../assets/icons/navbar/file-line.svg";
import FlagsIcon from "../../assets/icons/navbar/affiliate-marketing.svg";
import MessagesIcon from "../../assets/icons/navbar/subscription.svg";


const LeftSideBar = (props) => {
  return(
    <React.Fragment>

    <div style={{ textAlign: "center", padding: "0.64rem" }}>
      <Image src={Logo} style={{width: "100%"}}/>
    </div>

    

    </React.Fragment>
  );
};
export default withRouter(LeftSideBar);