export const TOGGLE_MODE = "TOGGLE_MODE";

export const OPEN_MODAL = "OPEN_MODAL";
export const CLOSE_MODAL = "CLOSE_MODAL";
export const CLOSE_ALL_MODAL = "CLOSE_ALL_MODAL";

//App
export const SET_STRIPE = "SET_STRIPE";
export const SET_HISTORY = "SET_HISTORY";
export const REDIRECT = "REDIRECT";

// Contact
export const CONTACT_REQUEST = "CONTACT_REQUEST";
export const CONTACT_SUCCESS = "CONTACT_SUCCESS";
export const CONTACT_ERROR = "CONTACT_ERROR";

//Auth
export const REGISTER_REQUEST = "REGISTER_REQUEST";
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_ERROR = "REGISTER_ERROR";
export const SIGNUP_VALIDATION_REQUEST = "SIGNUP_VALIDATION_REQUEST";
export const SIGNUP_VALIDATION_SUCCESS = "SIGNUP_VALIDATION_SUCCESS";
export const SIGNUP_VALIDATION_ERROR = "SIGNUP_VALIDATION_ERROR";
export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_ERROR = "LOGIN_ERROR";
export const RESEND_EMAIL = "RESEND_EMAIL";
export const LOGOUT = "LOGOUT";
export const PASSWORD_RESET_REQUEST = "PASSWORD_RESET_REQUEST";
export const PASSWORD_RESET_SUCCESS = "PASSWORD_RESET_SUCCESS";
export const PASSWORD_RESET_ERROR = "PASSWORD_RESET_ERROR";
export const RESEND_CODE_REQUEST = "RESEND_CODE_REQUEST";
export const RESEND_CODE_SUCCESS = "RESEND_CODE_SUCCESS";
export const RESEND_CODE_ERROR = "RESEND_CODE_ERROR";
export const VERIFY_TOKEN_REQUEST = "VERIFY_TOKEN_REQUEST";
export const VERIFY_TOKEN_SUCCESS = "VERIFY_TOKEN_SUCCESS";
export const VERIFY_TOKEN_ERROR = "VERIFY_TOKEN_ERROR";
export const RESET_PASSWORD_REQUEST = "RESET_PASSWORD_REQUEST";
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_ERROR = "RESET_PASSWORD_ERROR";
export const CHANGE_PASSWORD_REQUEST = "CHANGE_PASSWORD_REQUEST";
export const CHANGE_PASSWORD_SUCCESS = "CHANGE_PASSWORD_SUCCESS";
export const CHANGE_PASSWORD_ERROR = "CHANGE_PASSWORD_ERROR";
export const CHANGE_HEADER_PROFILE_PICTURE = "CHANGE_HEADER_PROFILE_PICTURE";

//profile name and email update
export const PROFILE_UPDATE_REQUEST = "PROFILE_UPDATE_REQUEST";
export const PROFILE_UPDATE_SUCCESS = "PROFILE_UPDATE_SUCCESS";
export const PROFILE_UPDATE_ERROR = "PROFILE_UPDATE_ERROR";

/* bank Account Types */
// -- Add
export const ADD_BANK_ACCOUNT_REQUEST = "ADD_BANK_ACCOUNT_REQUEST";
export const ADD_BANK_ACCOUNT_SUCCESS = "ADD_BANK_ACCOUNT_SUCCESS";
export const ADD_BANK_ACCOUNT_ERROR = "ADD_BANK_ACCOUNT_ERROR";
// -- get  all banks
export const GET_BANK_ACCOUNTS_REQUEST = "GET_BANK_ACCOUNTS_REQUEST";
export const GET_BANK_ACCOUNTS_SUCCESS = "GET_BANK_ACCOUNTS_SUCCESS";
export const GET_BANK_ACCOUNTS_ERROR = "GET_BANK_ACCOUNTS_ERROR";
// -- Update
export const UPDATE_BANK_ACCOUNT_REQUEST = "UPDATE_BANK_ACCOUNT_REQUEST";
export const UPDATE_BANK_ACCOUNT_SUCCESS = "UPDATE_BANK_ACCOUNT_SUCCESS";
export const UPDATE_BANK_ACCOUNT_ERROR = "UPDATE_BANK_ACCOUNT_ERROR";
// -- Delete
export const DELETE_BANK_ACCOUNT_REQUEST = "DELETE_BANK_ACCOUNT_REQUEST";
export const DELETE_BANK_ACCOUNT_SUCCESS = "DELETE_BANK_ACCOUNT_SUCCESS";
export const DELETE_BANK_ACCOUNT_ERROR = "DELETE_BANK_ACCOUNT_ERROR";
// -- single get
export const GET_ONE_BANK_REQUEST = "GET_ONE_BANK_REQUEST";
export const GET_ONE_BANK_SUCCESS = "GET_ONE_BANK_SUCCESS";
export const GET_ONE_BANK_ERROR = "GET_ONE_BANK_ERROR";
export const CLEAR_ONE_BANK = "CLEAR_ONE_BANK";
// Record entry
export const RECORD_ENTRY = "RECORD_ENTRY";

// --get Contacts
export const GET_FIELD_OPTIONS_REQUEST = "CONTACT_USER_REQUEST";
// --get Account Names
export const GET_ACCOUNTS_REQUEST = "GET_ACCOUNTS_REQUEST";
// Add/Edit Account Name
export const ADD_ACCOUNT_NAME_REQUEST = "ADD_ACCOUNT_NAME_REQUEST";
export const ADD_ACCOUNT_NAME_SUCCESS = "ADD_ACCOUNT_NAME_SUCCESS";
export const ADD_ACCOUNT_NAME_ERROR = "ADD_ACCOUNT_NAME_ERROR";
export const EDIT_ACCOUNT_NAME_REQUEST = "EDIT_ACCOUNT_NAME_REQUEST";
export const EDIT_ACCOUNT_NAME_SUCCESS = "EDIT_ACCOUNT_NAME_SUCCESS";
export const EDIT_ACCOUNT_NAME_ERROR = "EDIT_ACCOUNT_NAME_ERROR";
// --get Jobs
export const GET_JOBS_REQUEST = "GET_JOBS_REQUEST";
// --Create Contact
export const ADD_CONTACT_REQUEST = "ADD_CONTACT_REQUEST";
export const ADD_CONTACT_SUCCESS = "ADD_CONTACT_SUCCESS";
export const ADD_CONTACT_ERROR = "ADD_CONTACT_ERROR";

// --Add Jobs
export const ADD_JOB_REQUEST = "ADD_JOB_REQUEST";
export const ADD_JOB_SUCCESS = "ADD_JOB_SUCCESS";
export const ADD_JOB_ERROR = "ADD_JOB_ERROR";
/* Transaction Types */
// -- Get banks Transaction
export const GET_TRANSACTION_REQUEST = "GET_TRANSACTION_REQUEST";
export const GET_TRANSACTION_SUCCESS = "GET_TRANSACTION_SUCCESS";
export const GET_TRANSACTION_ERROR = "GET_TRANSACTION_ERROR";
export const CLEAR_TRANSACTION = "CLEAR_TRANSACTION";

// -- Get SINGLE bank Transaction
export const GET_BANK_TRANSACTION_REQUEST = "GET_BANK_TRANSACTION_REQUEST";
export const GET_BANK_TRANSACTION_SUCCESS = "GET_BANK_TRANSACTION_SUCCESS";
export const GET_BANK_TRANSACTION_ERROR = "GET_BANK_TRANSACTION_ERROR";

// Create-Transactions
export const ADD_TRANSACTION_REQUEST = "ADD_TRANSACTION_REQUEST";
export const ADD_TRANSACTION_SUCCESS = "ADD_TRANSACTION_SUCCESS";
export const ADD_TRANSACTION_ERROR = "ADD_TRANSACTION_ERROR";

// Edit-Transactions
export const UPDATE_TRANSACTION_REQUEST = "UPDATE_TRANSACTION_REQUEST";
export const UPDATE_TRANSACTION_SUCCESS = "UPDATE_TRANSACTION_SUCCESS";
export const UPDATE_TRANSACTION_ERROR = "UPDATE_TRANSACTION_ERROR";
export const UPDATE_RECURRING_REQUEST = "UPDATE_RECURRING_REQUEST";
export const UPDATE_RECURRING_SUCCESS = "UPDATE_RECURRING_SUCCESS";
export const UPDATE_RECURRING_ERROR = "UPDATE_RECURRING_ERROR";
export const UPDATE_TRANSFER_REQUEST = "UPDATE_TRANSFER_REQUEST";
export const UPDATE_TRANSFER_SUCCESS = "UPDATE_TRANSFER_SUCCESS";
export const UPDATE_TRANSFER_ERROR = "UPDATE_TRANSFER_ERROR";

// --Search Transactions

export const SERACH_TRANSACTIONS_REQUEST = "SERACH_TRANSACTIONS_REQUEST";
export const SERACH_TRANSACTIONS_SUCCESS = "SERACH_TRANSACTIONS_SUCCESS";
export const SERACH_TRANSACTIONS_ERROR = "SERACH_TRANSACTIONS_ERROR";

// --Delete Transactions
export const DELETE_TRANSACTION_REQUEST = "DELETE_TRANSACTION_REQUEST";
export const DELETE_TRANSACTION_SUCCESS = "DELETE_TRANSACTION_SUCCESS";
export const DELETE_TRANSACTION_ERROR = "DELETE_TRANSACTION_ERROR";

// --Cleared Transactions

export const CLEARED_TRANSACTION_REQUEST = "CLEARED_TRANSACTION_REQUEST";
export const CLEARED_TRANSACTION_SUCCESS = "CLEARED_TRANSACTION_SUCCESS";
export const CLEARED_TRANSACTION_ERROR = "CLEARED_TRANSACTION_ERROR";

// --Bank Account Summary Types
export const BANK_ACCOUNT_SUMMARY_REQUEST = "BANK_ACCOUNT_SUMMARY_REQUEST";
export const BANK_ACCOUNT_SUMMARY_SUCCESS = "BANK_ACCOUNT_SUMMARY_SUCCESS";
export const BANK_ACCOUNT_SUMMARY_ERROR = "BANK_ACCOUNT_SUMMARY_ERROR";

// --Get Recurring Types
export const GET_RECURRING_REQUEST = "GET_RECURRING_REQUEST";
export const GET_RECURRING_SUCCESS = "GET_RECURRING_SUCCESS";
export const GET_RECURRING_ERROR = "GET_RECURRING_ERROR";

// --Get Transfer Journal types

export const GET_TRANSFER_TRANSACTIONS_REQUEST =
  "GET_TRANSFER_TRANSACTIONS_REQUEST";
export const GET_TRANSFER_TRANSACTIONS_SUCCESS =
  "GET_TRANSFER_TRANSACTIONS_SUCCESS";
export const GET_TRANSFER_TRANSACTIONS_ERROR =
  "GET_TRANSFER_TRANSACTIONS_ERROR";

export const RECORD_TRANSFER_REQUEST = "RECORD_TRANSFER_REQUEST";
export const RECORD_TRANSFER_SUCCESS = "RECORD_TRANSFER_SUCCESS";
export const RECORD_TRANSFER_ERROR = "RECORD_TRANSFER_ERROR";
export const RECORD_RECURRING_REQUEST = "RECORD_RECURRING_REQUEST";
export const RECORD_RECURRING_SUCCESS = "RECORD_RECURRING_SUCCESS";
export const RECORD_RECURRING_ERROR = "RECORD_RECURRING_ERROR";
export const DELETE_ACCOUNT_HEAD_REQUEST = "DELETE_ACCOUNT_HEAD_REQUEST";
export const DELETE_ACCOUNT_HEAD_SUCCESS = "DELETE_ACCOUNT_HEAD_SUCCESS";
export const DELETE_ACCOUNT_HEAD_ERROR = "DELETE_ACCOUNT_HEAD_ERROR";
export const DELETE_JOB_REQUEST = "DELETE_JOB_REQUEST";
export const DELETE_JOB_SUCCESS = "DELETE_JOB_SUCCESS";
export const DELETE_JOB_ERROR = "DELETE_JOB_ERROR";

// Charts
export const GET_CHARTS_REQUEST = "GET_CHARTS_REQUEST";
export const GET_CHARTS_SUCCESS = "GET_CHARTS_SUCCESS";
export const GET_CHARTS_ERROR = "GET_CHARTS_ERROR";
export const CLEAR_CHARTS = "CLEAR_CHARTS";

// Reports
export const SEND_REPORT_REQUEST = "SEND_REPORT_REQUEST";
export const SEND_REPORT_SUCCESS = "SEND_REPORT_SUCCESS";
export const SEND_REPORT_ERROR = "SEND_REPORT_ERROR";
export const CHART_OF_ACCOUNTS_REQUEST = "CHART_OF_ACCOUNTS_REQUEST";
export const CHART_OF_ACCOUNTS_SUCCESS = "CHART_OF_ACCOUNTS_SUCCESS";
export const CHART_OF_ACCOUNTS_ERROR = "CHART_OF_ACCOUNTS_ERROR";
export const BANK_ACCOUNT_LEDGER_REQUEST = "BANK_ACCOUNT_LEDGER_REQUEST";
export const BANK_ACCOUNT_LEDGER_SUCCESS = "BANK_ACCOUNT_LEDGER_SUCCESS";
export const BANK_ACCOUNT_LEDGER_ERROR = "BANK_ACCOUNT_LEDGER_ERROR";
export const TRIAL_BALANCE_SUMMARY_REQUEST = "TRIAL_BALANCE_SUMMARY_REQUEST";
export const TRIAL_BALANCE_SUMMARY_SUCCESS = "TRIAL_BALANCE_SUMMARY_SUCCESS";
export const TRIAL_BALANCE_SUMMARY_ERROR = "TRIAL_BALANCE_SUMMARY_ERROR";
export const PROFIT_AND_LOSS_REQUEST = "PROFIT_AND_LOSS_REQUEST";
export const PROFIT_AND_LOSS_SUCCESS = "PROFIT_AND_LOSS_SUCCESS";
export const PROFIT_AND_LOSS_ERROR = "PROFIT_AND_LOSS_ERROR";
export const TRANSFER_JOURNAL_LEDGER_REQUEST =
  "TRANSFER_JOURNAL_LEDGER_REQUEST";
export const TRANSFER_JOURNAL_LEDGER_SUCCESS =
  "TRANSFER_JOURNAL_LEDGER_SUCCESS";
export const TRANSFER_JOURNAL_LEDGER_ERROR = "TRANSFER_JOURNAL_LEDGER_ERROR";
export const RECONCILIATION_FOR_LAWYERS_REQUEST =
  "RECONCILIATION_FOR_LAWYERS_REQUEST";
export const RECONCILIATION_FOR_LAWYERS_SUCCESS =
  "RECONCILIATION_FOR_LAWYERS_SUCCESS";
export const RECONCILIATION_FOR_LAWYERS_ERROR =
  "RECONCILIATION_FOR_LAWYERS_ERROR";
export const CHART_REPORT_REQUEST = "CHART_REPORT_REQUEST";
export const CHART_REPORT_SUCCESS = "CHART_REPORT_SUCCESS";
export const CHART_REPORT_ERROR = "CHART_REPORT_ERROR";

//-- GET--Contacts
export const GET_CONTACTS_REQUEST = "GET_CONTACTS_REQUEST";
export const GET_CONTACTS_SUCCESS = "GET_CONTACTS_SUCCESS";
export const GET_CONTACTS_ERROR = "GET_CONTACTS_ERROR";

//-- Delete Contacts
export const DELETE_CONTACT_REQUEST = "DELETE_CONTACT_REQUEST";
export const DELETE_CONTACT_SUCCESS = "DELETE_CONTACT_SUCCESS";
export const DELETE_CONTACT_ERROR = "DELETE_CONTACT_ERROR";

// --Edit Contacs
export const EDIT_CONTACT_REQUEST = "EDIT_CONTACT_REQUEST";
export const EDIT_CONTACT_SUCCESS = "EDIT_CONTACT_SUCCESS";
export const EDIT_CONTACT_ERROR = "EDIT_CONTACT_ERROR";

// -- Print Checks
export const GET_PRINT_TRANSACTION_REQUEST = "GET_PRINT_TRANSACTION_REQUEST";
export const GET_PRINT_TRANSACTION_SUCCESS = "GET_PRINT_TRANSACTION_SUCCESS";
export const GET_PRINT_TRANSACTION_ERROR = "GET_PRINT_TRANSACTION_ERROR";
// -- USERS SETTINGS
export const GET_USERS_SETTINGS_REQUEST = "GET_USERS_SETTINGS_REQUEST";
export const GET_USERS_SETTINGS_SUCCESS = "GET_USERS_SETTINGS_SUCCESS";
export const GET_USERS_SETTINGS_ERROR = "GET_USERS_SETTINGS_ERROR";

export const GET_USER_DETAIL_REQUESST='GET_USER_DETAIL_REQUESST'
export const GET_USER_DETAIL_SUCCESS='GET_USER_DETAIL_SUCCESS'
export const GET_USER_DETAIL_ERROR='GET_USER_DETAIL_ERROR'

export const UPDATE_USERS_SETTINGS_REQUEST = "UPDATE_USERS_SETTINGS_REQUEST";
export const UPDATE_USERS_SETTINGS_SUCCESS = "UPDATE_USERS_SETTINGS_SUCCESS";
export const UPDATE_USERS_SETTINGS_ERROR = "UPDATE_USERS_SETTINGS_ERROR";

// -- Create Checks
export const GET_CREATE_CHECK_REQUEST = "GET_CREATE_CHECK_REQUEST";
export const GET_CREATE_CHECK_SUCCESS = "GET_CREATE_CHECK_SUCCESS";
export const GET_CREATE_CHECK_ERROR = "GET_CREATE_CHECK_ERROR";

//-- subscriptions
export const BUY_SUBSCRIPTION_REQUEST = "BUY_SUBSCRIPTION_REQUEST";
export const BUY_SUBSCRIPTION_SUCCESS = "BUY_SUBSCRIPTION_SUCCESS";
export const BUY_SUBSCRIPTION_ERROR = "BUY_SUBSCRIPTION_ERROR";
export const GET_SUBSCRIPTION_REQUEST = "GET_SUBSCRIPTION_REQUEST";
export const GET_SUBSCRIPTION_SUCCESS = "GET_SUBSCRIPTION_SUCCESS";
export const GET_SUBSCRIPTION_ERROR = "GET_SUBSCRIPTION_ERROR";
export const SET_SUBSCRIPTION = "GET_SUBSCRIPTION";
export const CANCEL_SUBSCRIPTION_REQUEST = "CANCEL_SUBSCRIPTION_REQUEST";
export const CANCEL_SUBSCRIPTION_SUCCESS = "CANCEL_SUBSCRIPTION_SUCCESS";
export const CANCEL_SUBSCRIPTION_ERROR = "CANCEL_SUBSCRIPTION_ERROR";

// Traer - Dashboard
export const GAME_STATS_REQUEST = "GAME_STATS_REQUEST"
export const GAME_STATS_SUCCESS = "GAME_STATS_SUCCESS"
export const GAME_STATS_ERROR = "GAME_STATS_ERROR"

export const EARNING_STATS_REQUEST = "EARNING_STATS_REQUEST"
export const EARNING_STATS_SUCCESS = "EARNING_STATS_SUCCESS"
export const EARNING_STATS_ERROR = "EARNING_STATS_ERROR"

export const WAGERS_STATS_REQUEST = "WAGERS_STATS_REQUEST"
export const WAGERS_STATS_SUCCESS = "WAGERS_STATS_SUCCESS"
export const WAGERS_STATS_ERROR = "WAGERS_STATS_ERROR"


export const USER_STATS_REQUEST = "USER_STATS_REQUEST"
export const USER_STATS_SUCCESS = "USER_STATS_SUCCESS"
export const USER_STATS_ERROR = "USERS_STATS_ERROR"
export const MONTHLY_USER_STATS_REQUEST = "MONTHLY_USER_STATS_REQUEST"
export const MONTHLY_USER_STATS_SUCCESS = "MONTHLY_USER_STATS_SUCCESS"
export const MONTHLY_USER_STATS_ERROR = "MONTHLY_USER_STATS_ERROR"
export const BANNER_BACKGROUND_JOB_START = "BANNER_BACKGROUND_JOB_START"
export const BANNER_BACKGROUND_JOB_STOP = "BANNER_BACKGROUND_JOB_STOP"
export const BANNER_MATCHES_REQUEST = "BANNER_MATCHES_REQUEST"
export const BANNER_MATCHES_SUCCESS = "BANNER_MATCHES_SUCCESS"
export const BANNER_MATCHES_ERROR = "BANNER_MATCHES_ERROR"
export const APP_DOWNLOADS_REQUEST = "APP_DOWNLOADS_REQUEST"
export const APP_DOWNLOADS_SUCCESS = "APP_DOWNLOADS_SUCCESS"
export const APP_DOWNLOADS_ERROR = "APP_DOWNLOADS_ERROR"
// END
//--Traer

//user Types
export const USERS_REQUEST = "USERS_REQUEST";
export const USERS_SUCCESS = "USERS_SUCCESS";
export const USERS_ERROR = "USERS_ERROR";
export const USER_REQUEST = "USER_REQUEST";
export const USER_SUCCESS = "USER_SUCCESS";
export const USER_ERROR = "USER_ERROR";
export const CLEAR_USER = "CLEAR_USER";
export const CHANGE_USER_STATUS_REQUEST = "CHANGE_USER_STATUS_REQUEST";
export const CHANGE_USER_STATUS_SUCCESS = "CHANGE_USER_STATUS_SUCCESS";
export const CHANGE_USER_STATUS_ERROR = "CHANGE_USER_STATUS_ERROR";
export const ADD_USER_REQUEST = "ADD_USER_REQUEST";
export const ADD_USER_SUCCESS = "ADD_USER_SUCCESS";
export const ADD_USER_ERROR = "ADD_USER_ERROR";
export const DELETE_USER_REQUEST = "DELETE_USER_REQUEST";
export const DELETE_USER_SUCCESS = "DELETE_USER_SUCCESS";
export const DELETE_USER_ERROR = "DELETE_USER_ERROR";

//--Traer
// Traer - Messages
export const MESSAGES_REQUEST = "MESSAGES_REQUEST"
export const MESSAGES_SUCCESS = "MESSAGES_SUCCESS"
export const MESSAGES_ERROR = "MESSAGES_ERROR"
export const MESSAGE_DEL_REQUEST = "MESSAGE_DEL_REQUEST"
export const MESSAGE_DEL_SUCCESS = "MESSAGE_DEL_SUCCESS"
export const MESSAGE_DEL_ERROR = "MESSAGE_DEL_ERROR"
export const MESSAGE_RESPONSE_REQUEST = "MESSAGE_RESPONSE_REQUEST"
export const MESSAGE_RESPONSE_SUCCESS = "MESSAGE_RESPONSE_SUCCESS"
export const MESSAGE_RESPONSE_ERROR = "MESSAGE_RESPONSE_ERROR"
// END
// Traer - Organization
export const ORGANIZATION_REQUEST = "ORGANIZATION_REQUEST"
export const ORGANIZATION_SUCCESS = "ORGANIZATION_SUCCESS"
export const ORGANIZATION_ERROR = "ORGANIZATION_ERROR"

export const ADD_ORGANIZATION_REQUEST = "ADD_ORGANIZATION_REQUEST"
export const ADD_ORGANIZATION_SUCCESS = "ADD_ORGANIZATION_SUCCESS"
export const ADD_ORGANIZATION_ERROR = "ADD_ORGANIZATION_ERROR"

export const ORGANIZATION_DETAIL_REQUEST = "ORGANIZATION_DETAIL_REQUEST";
export const ORGANIZATION_DETAIL_SUCCESS = "ORGANIZATION_DETAIL_SUCCESS";
export const ORGANIZATION_DETAIL_ERROR = "ORGANIZATION_DETAIL_ERROR";

export const UPDATE_ORGANIZATION_REQUEST = "UPDATE_ORGANIZATION_REQUEST"
export const UPDATE_ORGANIZATION_SUCCESS = "UPDATE_ORGANIZATION_SUCCESS"
export const UPDATE_ORGANIZATION_ERROR = "UPDATE_ORGANIZATION_ERROR"
// END

// Traer - Users
// export const CHANGE_USER_STATUS_REQUEST =  "CHANGE_USER_STATUS_REQUEST"
// export const CHANGE_USER_STATUS_SUCCESS =  "CHANGE_USER_STATUS_SUCCESS"
// export const CHANGE_USER_STATUS_ERROR =  "CHANGE_USER_STATUS_ERROR"
// END

// Traer - Requests
export const REQUESTS_REQUEST = "REQUESTS_REQUEST"
export const REQUESTS_SUCCESS = "REQUESTS_SUCCESS"
export const REQUESTS_ERROR = "REQUESTS_ERROR"
export const REQUEST_REQUEST = "REQUEST_REQUEST"
export const REQUEST_SUCCESS = "REQUEST_SUCCESS"
export const REQUEST_ERROR = "REQUEST_ERROR"
export const USER_REQUESTS_REQUEST = "USER_REQUESTS_REQUEST"
export const USER_REQUESTS_SUCCESS = "USER_REQUESTS_SUCCESS"
export const USER_REQUESTS_ERROR = "USER_REQUESTS_ERROR"
export const CLEAR_REQUESTS = "CLEAR_REQUESTS"
export const CLEAR_REQUEST = "CLEAR_REQUEST"
export const DELETE_DELIVERY_REQUEST="DELETE_DELIVERY_REQUEST"
export const DELETE_DELIVERY_SUCCESS="DELETE_DELIVERY_SUCCESS"
export const DELETE_DELIVERY_ERROR="DELETE_DELIVERY_ERROR"
export const CLEAR_LOGOUT='CLEAR_LOGOUT'
// END
