import React, { useEffect } from "react";
import { STRIPE_PUBLISHABLE_API_KEY } from "./configurations/Constants"
import { setStripe } from "./actions/App"
import { connect } from "react-redux";
// import { StripeProvider } from 'react-stripe-elements';
import "antd/dist/antd.css";
import { ThemeProvider } from "styled-components";
import { theme } from "./configurations/Theme";
import Routes from "./Routes";
import RootModal from "./components/modals";
// import FormView from "./components/formikwrapper/FormView"

function App(props) {
  return (

      <ThemeProvider theme={{ ...theme, mode: props.app.mode }}>
        <React.Fragment>
          <div id={props.modal.modals.length !== 0 ? 'filterModal' : ''} >
            <Routes />
          </div>
          <RootModal />
        </React.Fragment>
      </ThemeProvider>
  );
}

export default connect(
(storeState) => ({ app: storeState.App, modal: storeState.Modal }))
(App);
