import React, { useCallback } from 'react';
import { connect ,useDispatch } from "react-redux";
import { Row, Col } from 'antd';
import Input from "../../../components/input";
import useFormik from "../../../components/formik/useFormik";
import Button from '../../../components/button';
import {addUserSchema} from "../../../configurations/Schemas/addUser";
import { updateOrganizationRequest } from '../../../actions/Organization'
import { addUser } from '../../../actions/Users'
import Icon from '../../../components/Icon';
import { closeModal } from "../../../actions/Modal";


const SubscriptionUpdate = (props) => {

  const dispatch = useDispatch();

  const formik = useFormik({
    validationSchema: addUserSchema,
    initialValues: {
      userName: "",
      email: "",
      state:props.currentState
    },
    onSubmit: (values,  {...rest} ) => {
      dispatch(addUser(values,(res) => {
        if(res?.data?.status == 200){
          props.closeModal()
          props.callback();
        }
      }))
        rest.resetForm({ 
          values: {
            userName: "",
            email: ""
          }
         });
    },
});

  const field = useCallback(
    (placeholder, name, value, customProps) => (
        <Col span={24}>
            <Input
                style={{ marginTop: 15, marginBottom:'0px'}}
                placeholder={placeholder}
                value={value}
                name={name}
                onChange={(e) => {
                    formik.customHandleChange(e, name);
                }}
                onBlur={(e) => formik.customHandleBlur(e, name)}
                {...customProps}
            />
            <span className="dangerColor caption textCenter">
                {formik.touched[name] && formik.errors[name]}
            </span>
        </Col>
    ),
    [formik]
);

  return (
    <Row type="flex" justify="center" align="middle">
      <Col span={24}>
        <h4 style={{marginLeft:12 , fontWeight: "bold"}}>Add User</h4>
        <Row style={{ marginTop:'20px' }} gutter={{ xs: 8, sm: 32, md: 24, lg: 32 }}>
         <Col span={24}>
                <label style={{ fontSize:10, marginLeft: "3%",fontWeight:'600' }} >Name</label>
                {field(
                  '',
                  "userName",
                  formik.values['userName']
                )
                }</Col>
               
                <Col style={{ marginTop: 10}}  span={24}>
                <label style={{ fontSize:10,  marginLeft: "3%", fontWeight:'600' }} >Email</label>
                {field(
                  '',
                  "email",
                  formik.values['email']
                )
                }</Col>
            </Row>

      </Col>

      <div style={{ marginTop:'40px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Button
          style={{ width: 200, boxShadow: "0px 5px 5px 1px rgba(0,0,0,0.15)" }}
          sharpBorder size={"large"}
          isGreen={true}
          onClick={() => formik.submitForm()}
          >
            Add User
           {/* {props.organizationState.loading? 
           <Icon type="loading" style={{ fontSize: "1rem" }} /> : 'Add User' }  */}
                </Button>
      </div>
    </Row>
  )
}

export default connect(state => ({ organizationState: state.Organization }),({
  updateOrganizationRequest,
  closeModal,
  addUser,
}))(SubscriptionUpdate);