import React from "react";
import { DatePicker } from "antd";
// import moment from "moment";
import styled, { css } from "styled-components";

const { RangePicker } = DatePicker;


const RangePickerStyles = 
//styled(RangePicker).attrs(() => ({ size: "large" }))
css`
//   color: palevioletred;
//   font-weight: bold;
//   box-shadow: 0 0 0 2px rgba(0, 0, 0, 1);
input {
    //background-color: ${props => props.theme[props.theme.mode].background.primary};
    color: ${props => props.theme[props.theme.mode].textColor.primary};
    height: auto !important;
    padding: 0 !important;
    //border: 1px solid ${props => props.theme[props.theme.mode].primary.main};
    //${props => props.appStyles ? props.appStyles : null};
    // padding-top: 18px;
    // padding-bottom: 18px;
}
& .ant-calendar-range {
  width: 100%;
}
& > .ant-calendar-picker-input {
  background-color: ${props => props.theme[props.theme.mode].background.secondary};
  border:${props => props.border ? props.border : `1px solid ${props => props.theme[props.theme.mode].primary.main}66`};
  border-radius:${props => props.borderRadius ? props.borderRadius : `3px`};
  line-height: 0;
}
.ant-calendar-picker-input:hover {
  border: 1px solid ${props => props.theme[props.theme.mode].primary.main};
  border-color: ${props => props.theme[props.theme.mode].primary.main} !important;
}
.ant-calendar-picker-input:focus {
  border: 1px solid ${props => props.theme[props.theme.mode].primary.main};
  border-color: ${props => props.theme[props.theme.mode].primary.main} !important;
  box-shadow: 0 0 0 2px ${props => props.theme[props.theme.mode].primary.main}66;
}
&:focus .ant-calendar-picker-input {
  border: 1px solid ${props => props.theme[props.theme.mode].primary.main};
  border-color: ${props => props.theme[props.theme.mode].primary.main} !important;
  box-shadow: 0 0 0 2px ${props => props.theme[props.theme.mode].primary.main}66 !important;
}
& .ant-calendar-range-picker-separator {
  vertical-align: middle;
}
&.ant-calendar-picker-container {
  //margin-top: 50px !important;
  transform: translateY(50px);
}

.ant-calendar-month-panel-selected-cell .ant-calendar-month-panel-month {
  color: ${props => props.theme[props.theme.mode].textColor.inverted} !important;
  background: ${props => props.theme[props.theme.mode].primary.main} !important;
}

.ant-calendar-month-panel-cell[class="ant-calendar-month-panel-cell"] .ant-calendar-month-panel-month:hover,
.ant-calendar-month-panel-current-cell:not(.ant-calendar-month-panel-selected-cell) .ant-calendar-month-panel-month:hover {
  color: ${props => props.theme[props.theme.mode].primary.main} !important;
  background: ${props => props.theme[props.theme.mode].primary.light} !important;
}
.dangerColor { 
    color: ${props => props.theme[props.theme.mode].error.main}; 
    text-align: left;
    font-weight: 600;
  }
.ant-calendar-month-panel-year-select:hover {
  color: ${props => props.theme[props.theme.mode].primary.main} !important;
}

svg {
    color: ${props => props.theme[props.theme.mode].primary.main};
}
`;

// ${props => [1,2,3,4,5,6,7,8,9,10,11,12].map((val, ind) => ind > props.currentMonth.month() ? `
//   .ant-calendar-range-right 
//   .ant-calendar-month-panel-tbody 
//   tr:nth-child(${Math.ceil((props.currentMonth.month() + 1)/3)}) 
//   .ant-calendar-month-panel-cell:nth-child(${(val % 3) ? (val % 3) : 3}) 
//   .ant-calendar-month-panel-month {
//     color: rgba(0, 0, 0, 0.25) !important;
//     background: #f5f5f5 !important;
//     cursor: not-allowed !important;
// }

// ` : ``)}

const RangePickerWrapper = props => {
  return <RangePicker dropdownClassName={props.className} {...props}/>;
};
export default styled(RangePickerWrapper).attrs({className: 'inputField'})`${RangePickerStyles}`;


// cb funtion recieverd from props to get the selected date
// function onChange(date, dateString) {
//   console.log(date, dateString);
// }
