import {
  GET_BANK_TRANSACTION_REQUEST,
  GET_BANK_TRANSACTION_SUCCESS,
  GET_BANK_TRANSACTION_ERROR,
  ADD_TRANSACTION_REQUEST,
  ADD_TRANSACTION_SUCCESS,
  ADD_TRANSACTION_ERROR,
  RECORD_RECURRING_REQUEST,
  RECORD_RECURRING_SUCCESS,
  RECORD_RECURRING_ERROR,
  RECORD_TRANSFER_REQUEST,
  RECORD_TRANSFER_SUCCESS,
  RECORD_TRANSFER_ERROR,
  UPDATE_TRANSACTION_REQUEST,
  UPDATE_TRANSACTION_SUCCESS,
  UPDATE_TRANSACTION_ERROR,
  UPDATE_RECURRING_REQUEST,
  UPDATE_RECURRING_SUCCESS,
  UPDATE_RECURRING_ERROR,
  UPDATE_TRANSFER_REQUEST,
  UPDATE_TRANSFER_SUCCESS,
  UPDATE_TRANSFER_ERROR,
  BANK_ACCOUNT_SUMMARY_SUCCESS,
  BANK_ACCOUNT_SUMMARY_REQUEST,
  BANK_ACCOUNT_SUMMARY_ERROR,
  GET_RECURRING_REQUEST,
  GET_RECURRING_SUCCESS,
  GET_RECURRING_ERROR,
  GET_TRANSACTION_REQUEST,
  GET_TRANSACTION_SUCCESS,
  GET_TRANSACTION_ERROR,
  CLEAR_TRANSACTION,
} from "../configurations/Types";

const INITIALS_STATE = {
  // for single bank trasaction
  totalBal: 0,
  currency: null,
  clearedBal: 0,
  outStanding: 0,
  bankAccName: "",
  bankName: "",
  lawFirmName: "",
  bankTransactions: [],
  bankTransactionCount: 0,
  bankTransLoading: false,
  // for All banks transaction
  transactions: [],
  modalLoader: false,
  bankSummaryDetails: {},
  allRecurrings: [],
  recurringLoader: false,
  recurringTotalCount: 0,
  // for other states, add below
  // for other states, add below
  transaction: null,
  transactionLoading: false,

  addTransactionLoading: false,
  recordRecurringLoading: false,
  recordTransferLoading: false,
  
  updateTransactionLoading: false,
  updateRecurringLoading: false,
  updateTransferLoading: false
};

export default (state = INITIALS_STATE, action) => {
  switch (action.type) {
    case GET_BANK_TRANSACTION_REQUEST: {
      return Object.assign({}, state, {
        bankTransLoading: true,
        //bankTransactionCount: action.flag ? 0 : state.bankTransaction,
        //bankTransaction: action.flag ? [] : state.bankTransaction
      });
    }
    case GET_BANK_TRANSACTION_SUCCESS: {
      let transactionHolder = [],
        singleBankTransaction = [],
        singleBankTransCount = 0,
        singleBankTotalBal = 0,
        singleBankCurrency = null,
        singleBankClearedBal = 0,
        singleBankOutStanding = 0,
        singleBankAccName = "",
        singleBankLawFirm = "",
        singleBankName = "";
      if (action.payload) {
        const {
          all_transactions,
          total_count,
          total_balance,
          currency,
          cleared_balance,
          outstanding,
          law_firm_name,
          bank_account_name,
          bank_name
        } = action.payload;
        singleBankTransCount = total_count;
        singleBankTotalBal = total_balance;
        singleBankCurrency = currency;
        singleBankClearedBal = cleared_balance;
        singleBankOutStanding = outstanding;
        singleBankAccName = bank_account_name;
        singleBankLawFirm = law_firm_name;
        singleBankName = bank_name;
        // transactionHolder =
        console.log("all_transactions", all_transactions);
        transactionHolder = Object.values(all_transactions);
        transactionHolder = transactionHolder.flat();
        console.log("Flat array", transactionHolder);

        console.log("transaction Holder", transactionHolder);
      }

      if (
        state.bankTransactions.length !== 0 &&
        !action.clearFlag &&
        !action.ofPage
      ) {
        console.log("if---!action.clearFlag && !action.ofPage");
        transactionHolder = [...state.bankTransactions, ...transactionHolder];
      }
      if (action.ofPage) {
        console.log("pahge ofSize", action.ofPage);
        let { page, pageSize } = action.ofPage;
        // if (page < (pages - 1))
        // Old approach - faulty due to missing records
        // let oldIds = state.users.slice(page * pageSize, (page * pageSize) + (pageSize + 1)).map(oldUser => oldUser.id);
        // arr = arr.filter((user) => oldIds.includes(user.id));
        // let newUsers = [...state.users];
        // newUsers.splice(page * pageSize, pageSize, ...arr);
        // arr = newUsers;
        //Another faulty approach - Missing records
        // let newPageIds = arr.map(user => user.id);
        // let nextPage = [...state.users].slice((page * pageSize) + pageSize, ((page * pageSize) + pageSize) + (pageSize + 1));
        // nextPage = nextPage.filter(user => !newPageIds.includes(user.id));
        // let newUsers = [...state.users];
        // newUsers.splice(page * pageSize, pageSize * 2, ...[...arr, ...nextPage]);
        // arr = newUsers;
        let newBankTransactions = [...state.bankTransactions];
        console.log("new Transactions", newBankTransactions);
        newBankTransactions.splice(
          page * pageSize,
          newBankTransactions.length - page * pageSize,
          ...transactionHolder
        );
        transactionHolder = newBankTransactions;
        // console.log("newBankTransactions", newBankTransactions);
      }

      return Object.assign({}, state, {
        bankTransLoading: false,
        totalBal: singleBankTotalBal,
        currency: singleBankCurrency,
        clearedBal: singleBankClearedBal,
        outStanding: singleBankOutStanding,
        bankAccName: singleBankAccName,
        bankName: singleBankName,
        lawFirmName: singleBankLawFirm,
        bankTransactionCount: singleBankTransCount,
        bankTransactions: transactionHolder
      });
    }
    case GET_BANK_TRANSACTION_ERROR: {
      return Object.assign({}, state, {
        bankTransLoading: false
      });
    }

    case ADD_TRANSACTION_REQUEST: {
      return Object.assign({}, state, {
        addTransactionLoading: true
      });
    }

    case ADD_TRANSACTION_SUCCESS: {
      return Object.assign({}, state, {
        addTransactionLoading: false
      });
    }
    case ADD_TRANSACTION_ERROR: {
      return Object.assign({}, state, {
        addTransactionLoading: false
      });
    }

    case RECORD_RECURRING_REQUEST: {
      return Object.assign({}, state, {
        recordRecurringLoading: true
      });
    }

    case RECORD_RECURRING_SUCCESS: {
      return Object.assign({}, state, {
        recordRecurringLoading: false
      });
    }
    case RECORD_RECURRING_ERROR: {
      return Object.assign({}, state, {
        recordRecurringLoading: false
      });
    }

    case RECORD_TRANSFER_REQUEST: {
      return Object.assign({}, state, {
        recordTransferLoading: true
      });
    }

    case RECORD_TRANSFER_SUCCESS: {
      return Object.assign({}, state, {
        recordTransferLoading: false
      });
    }
    case RECORD_TRANSFER_ERROR: {
      return Object.assign({}, state, {
        recordTransferLoading: false
      });
    }

    case BANK_ACCOUNT_SUMMARY_REQUEST: {
      return Object.assign({}, state, {
        modalLoader: true
      });
    }

    case BANK_ACCOUNT_SUMMARY_SUCCESS: {
      console.log("BANK_ACCOUNT_SUMMARY_SUCCESS", action);
      return Object.assign({}, state, {
        bankSummaryDetails: action.payload,
        modalLoader: false
      });
    }

    case BANK_ACCOUNT_SUMMARY_ERROR: {
      return Object.assign({}, state, {
        modalLoader: false
      });
    }
    //Recurring reducers
    case GET_RECURRING_REQUEST: {
      return Object.assign({}, state, {
        recurringLoader: true
      });
    }

    case GET_RECURRING_SUCCESS: {
      console.log("GET_RECURRING_SUCCESS", action.recurringData);
      let recurringHolder = [];
      console.log(
        "Object.values",
        Object.values(action.recurringData.all_transactions).flat()
      );

      recurringHolder = Object.values(
        action.recurringData.all_transactions
      ).flat();

      if (
        state.allRecurrings.length !== 0 &&
        !action.clearFlag &&
        !action.ofPage
      ) {
        console.log("if---!action.clearFlag && !action.ofPage");
        recurringHolder = [...state.allRecurrings, ...recurringHolder];
      }

      // of page
      if (action.ofPage) {
        console.log("pahge ofSize", action.ofPage);
        let { page, pageSize } = action.ofPage;
        // if (page < (pages - 1))
        // Old approach - faulty due to missing records
        // let oldIds = state.users.slice(page * pageSize, (page * pageSize) + (pageSize + 1)).map(oldUser => oldUser.id);
        // arr = arr.filter((user) => oldIds.includes(user.id));
        // let newUsers = [...state.users];
        // newUsers.splice(page * pageSize, pageSize, ...arr);
        // arr = newUsers;
        //Another faulty approach - Missing records
        // let newPageIds = arr.map(user => user.id);
        // let nextPage = [...state.users].slice((page * pageSize) + pageSize, ((page * pageSize) + pageSize) + (pageSize + 1));
        // nextPage = nextPage.filter(user => !newPageIds.includes(user.id));
        // let newUsers = [...state.users];
        // newUsers.splice(page * pageSize, pageSize * 2, ...[...arr, ...nextPage]);
        // arr = newUsers;
        let newRecurrings = [...state.allRecurrings];
        console.log("newRecurrings", newRecurrings);
        newRecurrings.splice(
          page * pageSize,
          newRecurrings.length - page * pageSize,
          ...recurringHolder
        );
        recurringHolder = newRecurrings;
        // console.log("newRecurrings", newRecurrings);
      }

     // of page

      return Object.assign({}, state, {
        recurringLoader: false,
        allRecurrings: recurringHolder,
        recurringTotalCount: action.recurringData.total_count
      });
    }
    case GET_RECURRING_ERROR: {
      console.log("GET_RECURRING_ERROR");
      return Object.assign({}, state, {
        recurringLoader: false,
    
      });
    }

    case GET_TRANSACTION_REQUEST: {
      return Object.assign({}, state, {
        transactionLoading: true
      });
    }

    case GET_TRANSACTION_SUCCESS: {
      return Object.assign({}, state, {
        transaction: action.payload,
        transactionLoading: false
      });
    }
    case GET_TRANSACTION_ERROR: {
      return Object.assign({}, state, {
        transactionLoading: false
      });
    }
    case CLEAR_TRANSACTION: {
      return Object.assign({}, state, {
        transaction: null
      });
    }

    case UPDATE_TRANSACTION_REQUEST: {
      return Object.assign({}, state, {
        updateTransactionLoading: true
      });
    }

    case UPDATE_TRANSACTION_SUCCESS: {
      return Object.assign({}, state, {
        updateTransactionLoading: false
      });
    }
    case UPDATE_TRANSACTION_ERROR: {
      return Object.assign({}, state, {
        updateTransactionLoading: false
      });
    }

    case UPDATE_RECURRING_REQUEST: {
      return Object.assign({}, state, {
        updateRecurringLoading: true
      });
    }

    case UPDATE_RECURRING_SUCCESS: {
      return Object.assign({}, state, {
        updateRecurringLoading: false
      });
    }
    case UPDATE_RECURRING_ERROR: {
      return Object.assign({}, state, {
        updateRecurringLoading: false
      });
    }

    case UPDATE_TRANSFER_REQUEST: {
      return Object.assign({}, state, {
        updateTransferLoading: true
      });
    }

    case UPDATE_TRANSFER_SUCCESS: {
      return Object.assign({}, state, {
        updateTransferLoading: false
      });
    }
    case UPDATE_TRANSFER_ERROR: {
      return Object.assign({}, state, {
        updateTransferLoading: false
      });
    }
    default: {
      return state;
    }
  }
};
