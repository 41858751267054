import { put, takeLatest, cancelled } from 'redux-saga/effects';
import { ORGANIZATION_REQUEST, ADD_ORGANIZATION_REQUEST, UPDATE_ORGANIZATION_REQUEST, ORGANIZATION_DETAIL_REQUEST } from "../configurations/Types";
import { ORGANIZATION_ENDPOINT, CHANGE_USER_STATUS_ENDPOINT, USERS_ENDPOINT } from "../configurations/Constants";
import {
  organizationReceived,
  organizationError,
  organizationRequest,

  addOrganizationRequest,
  addOrganizationSuccess,
  addOrganizationError,

  organizationDetailRecieved,
  organizationDetailError,

  updateOrganizationSuccess,
  updateOrganizationError,


} from "../actions/Organization";
import info from "../components/message/index";
import moment from "moment";
// import { tableData } from "../mocks/Users"
import { privateAgent, CancelToken } from "../configurations/AxiosAgent";
// const flaggedAPI = (ms) => new Promise((res, rej) => setTimeout(() => res(tableData), ms))
const organizationAPI = (params, token) => privateAgent.get(ORGANIZATION_ENDPOINT, { cancelToken: token.token, params: { ...params } })
const addOrganizationAPI = (body) => {
  return privateAgent.post(`${ORGANIZATION_ENDPOINT}`, {
    ...body,
  });
};
const organizationDetailAPI = () => privateAgent.get(`${ORGANIZATION_ENDPOINT}`);

const updateOrganizationAPI = (param, body) => {
  return privateAgent.put(`${ORGANIZATION_ENDPOINT}/${param}`, {
      ...body,
  })
}
let token;

// Our worker Saga: will perform the async flaggedRequest task
function* organizationGetRequest(action) {
  let res;
  try {
    if (token) {
      token.cancel();
    }
    token = CancelToken.source();
    res = yield organizationAPI(action.params, token);
    yield put(organizationReceived(res.data.data));
  }
  catch (e) {
    console.log("ERROR", e);
    try {
      info("error", `${e.response ? e.response.data.message : "Network error"}`);
    }
    catch (e) { }
    yield put(organizationError(e));
  }
  finally {
    if (yield cancelled()) {
      console.log("CANCELLED");
    }
  }
  //console.log("In saga", res);
  if (action.callback) {
    action.callback(res);
  }
}


function* organizationAddRequest(action) {
  let res;
  try {
    if (token) {
      token.cancel();
    }
    token = CancelToken.source();
    res = yield addOrganizationAPI(action.body);
    info("success", `Organization has been added successfully`)
    yield put(addOrganizationSuccess(res));
  }
  catch (e) {
    console.log("ERROR", e.response);
    try {
      info("error", `${e.response ? e.response.data.message : "Network error"}`);
    }
    catch (e) { }
    yield put(addOrganizationError(e));
  }
  finally {
    if (yield cancelled()) {
      console.log("CANCELLED");
    }
  }
  //console.log("In saga", res);
  if (action.callback) {
    action.callback(res);
  }
}


function* organizationDetailRequest(action) {
  let res;
  try {
    if (token) {
      token.cancel();
    }
    token = CancelToken.source();
    res = yield organizationDetailAPI(action.param, token);
    yield put(organizationDetailRecieved(res.data.data));
  }
  catch (e) {
    console.log("ERROR", e);
    try {
      info("error", `${e.response ? e.response.data.message : "Network error"}`);
    }
    catch (e) { }
    yield put(organizationDetailError(e));
  }
  finally {
    if (yield cancelled()) {
      console.log("CANCELLED");
    }
  }
  //console.log("In saga", res);
  if (action.callback) {
    action.callback(res);
  }
}


function* organizationUpdateRequest(action) {
  let res;
  try {
    if (token) {
      token.cancel();
    }
    token = CancelToken.source();
    res = yield updateOrganizationAPI(action.param, action.body);
    info("success", `Organization has been updated successfully`)
    yield put(updateOrganizationSuccess(res.data.data));
  }
  catch (e) {
    console.log("ERROR", e.response);
    try {
      info("error", `${e.response ? e.response.data.message : "Network error"}`);
    }
    catch (e) { }
    yield put(updateOrganizationError(e));
  }
  finally {
    if (yield cancelled()) {
      console.log("CANCELLED");
    }
  }
  //console.log("In saga", res);
  if (action.callback) {
    action.callback(res);
  }
}

// Our watcher Saga: spawn a new flaggedRequest task on each FLAGGED_REQUEST
export function* watchGetOrganization() {
  yield takeLatest(ORGANIZATION_REQUEST, organizationGetRequest)
  yield takeLatest(ADD_ORGANIZATION_REQUEST, organizationAddRequest)
  yield takeLatest(ORGANIZATION_DETAIL_REQUEST, organizationDetailRequest)
  yield takeLatest(UPDATE_ORGANIZATION_REQUEST, organizationUpdateRequest)
}