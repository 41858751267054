import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Row, Col, Skeleton } from "antd";
import { openModal, closeModal, closeAllModal } from "../../../actions/Modal";
import ContentHeader from "../../../appcomponents/shares/contentheader";
import Blip from "../../../appcomponents/shares/blip";
import CardWrapper from "../../../components/card/index";
import MessageBox from "../../../appcomponents/shares/messages/MessageBox";
// import { messagesGenerator } from "../../../mocks/Messages";
import { MessagesRequest } from "../../../actions/Messages";
import { current_sprint } from "../../../configurations/Constants";
import ReplyMessage from "../../../appcomponents/shares/messages/ReplyMessage";
import { FiChevronsDown } from "react-icons/fi";
import styled from "styled-components";
// import usersdata from './dummydata.json';
import EmptyProfilePic from "../../../assets/images/empty-profile.png";
import { formattedDate } from "../../../configurations/Config";
// const dataUsers = flagsGenerator(20);

const ScrollDown = styled(FiChevronsDown)`
  position: absolute;
  top: auto;
  bottom: auto;
  //bottom: 80px;
  left: 0;
  right: 0;
  //margin: -40px auto 0;
  margin: 0 auto 0;
  font-size: 1.5vw;
  animation: move 3s ease-out infinite;
  color: ${(props) => props.theme[props.theme.mode].primary.main};
  @keyframes move {
    25% {
      opacity: 1;
    }
    33% {
      opacity: 1;
      transform: translateY(30px);
    }
    67% {
      opacity: 1;
      transform: translateY(40px);
    }
    100% {
      opacity: 0;
      transform: translateY(55px) scale3d(0.5, 0.5, 0.5);
    }
  }
  @media screen and (max-width: 991px) {
    font-size: 1.8rem;
  }
`;

const Messages = (props) => {
  const [offSet, setOffSet] = useState(0);
  const [toggle, setToggle] = useState(false);
  const [state, setState] = useState({
    limit: 8,
    search: "",
    filter: "",
  });
  const [mount, setMount] = useState(false);
  const [hasScrolled, setHasScrolled] = useState(false);
  const [hasDeleted, setHasDeleted] = useState(false);
  const [hasReplyed, setHasReplyed] = useState(false);
  const [loading, setLoading] = useState(false);
  const [scroll, setScroll] = useState(false);
  useEffect(() => {
    if (mount) {
      console.log(
        "CALL MADE ",
        toggle,
        state,
        offSet,
        props.messageState.totalCount,
        props.messageState.messages.length
      );
      props.MessagesRequest(state.limit, offSet, state.search, state.filter);
    }
  }, [toggle]);
  //  for Search and Filter
  useEffect(() => {
    let { limit, search, filter } = state;
    window.addEventListener("scroll", handleScroll);
    //document.body.addEventListener("touchmove", handleScroll);
    if (!mount) {
      props.MessagesRequest(limit, offSet, search, filter);
    }
    setMount(true);
    return () => {
      window.removeEventListener("scroll", handleScroll);
      //document.body.removeEventListener("touchmove", handleScroll);
    };
  }, []);

  useEffect(() => {
    console.log(
      "Tested",
      state.search,
      state.filter,
      offSet,
      hasDeleted,
      hasReplyed
    );
    if (mount) {
      setToggle(!toggle);
    }
  }, [state.search, state.filter, offSet, hasDeleted, hasReplyed]);

  useEffect(() => {
    if (mount) {
      if (
        !loading &&
        !props.messageState.loading &&
        props.messageState.totalCount > props.messageState.messages.length
      ) {
        setLoading(true);
        setOffSet((prev) => prev + 8);
      }
    }
  }, [hasScrolled]);

  useEffect(() => {
    if (mount) {
      if (!props.messageState.loading) {
        setLoading(false);
      }
    }
  }, [props.messageState.loading]);

  const handleScroll = () => {
    console.log(
      "Test",
      window.innerHeight,
      window.pageYOffset,
      document.getElementById("root").clientHeight - 5,
      window.innerHeight + window.pageYOffset >=
        document.getElementById("root").clientHeight - 5
    );
    if (
      window.innerHeight + window.pageYOffset >=
      document.getElementById("root").clientHeight - 5
    ) {
      setHasScrolled((prev) => !prev);
    }
  };
  const searchChange = (e) => {
    setOffSet(0);
    setState({ ...state, search: e });
  };

  const filterChange = (e) => {
    setOffSet(0);
    setState({ ...state, filter: e });
  };
  const handleDelete = () => {
    setOffSet(0);
    if (!offSet) setHasDeleted(!hasDeleted);
  };
  const handleReply = () => {
    props.closeAllModal();
    setOffSet(0);
    if (!offSet) setHasReplyed(!hasReplyed);
  };
  const emptyStateMessage = (message) => {
    return (
      <React.Fragment>
        <Col
          className={"mainContentDivSmall"}
          style={{ width: "100%" }}
          lg={24}
          md={24}
          sm={24}
          xm={24}
        >
          <div
            style={{
              width: "100%",
              height: "100%",
              padding: "32vh 0",
              textAlign: "center",
            }}
          >
            <h3>{message || "Coming Soon"}</h3>
          </div>
        </Col>
      </React.Fragment>
    );
  };

  const renderMessageCard = () => {
    // let usersdata = 
    const usersdata = props.messageState.messages;
    console.log(props.messageState.messages , "message state")
    return (
      <React.Fragment>
        <Row
          type="flex"
          //style={{ marginTop: 40, marginBottom: 36 }}
          gutter={[20, 20]}
          justify="start"
        >
          {usersdata.length === 0
            ? emptyStateMessage("No Messages Found")
            : usersdata.map((item, index) => (
                <Col
                  key={item.message_id || index}
                  className={"mainContentDivSmall"}
                  lg={6}
                  md={12}
                  sm={24}
                  xs={24}
                >
                  <CardWrapper bordered={true}>
                    <Skeleton loading={!isNaN(item)} avatar active>
                      <MessageBox
                        render={
                          <MessageBox
                            message_id={item.id}
                            email={item.email}
                            name={item.user.full_name}
                            description={item.message}
                            profileImage={item.user.profile_picture || EmptyProfilePic}
                            date={formattedDate(item.created_at)}
                            is_replied={item.is_replied}
                            openDesc
                            openModal={() => {
                              props.openModal(
                                null,
                                <div className="textCenter">
                                  <ReplyMessage
                                    closeSelf={handleReply}
                                    email={item.email}
                                    message_id={item.id}
                                  />
                                </div>,
                                null,
                                null,
                                { footer: null }
                              );
                              props.closeModal(true);
                            }}
                            handleDelete={handleDelete}
                          />
                        }
                        message_id={item.id}
                        email={item.user.email}
                        name={item.user.full_name}
                        description={`${
                          item.message
                            ? item.message.length >= 150
                              ? item.message.substring(0, 150) + "..."
                              : item.message
                            : item.message
                        }`}
                        profileImage={item.user.profile_picture || EmptyProfilePic}
                        date={formattedDate(item.created_at)}
                        is_replied={item.is_replied}
                        openDesc={props.openModal}
                        openModal={() =>
                          props.openModal(
                            null,
                            <div className="textCenter">
                              <ReplyMessage
                                closeSelf={handleReply}
                                email={item.email}
                                message_id={item.id}
                              />
                            </div>,
                            null,
                            null,
                            { footer: null }
                          )
                        }
                        handleDelete={handleDelete}
                      />
                    </Skeleton>
                  </CardWrapper>
                </Col>
              ))}
        </Row>
        {/* {props.messageState.messages.length !==
        parseInt(props.messageState.totalCount) ? (
          <ScrollDown />
        ) : null} */}
      </React.Fragment>
    );
  };
  return (
    <React.Fragment>
      <Row type="flex" gutter={[15, 20]} justify="space-between">
        <Col span={24}>
          <ContentHeader
            title="Messages"
            searchChange={searchChange}
            filterChange={filterChange}
            count={props.messageState?.messages?.length}
            hideOption
            options={[
              { label: "View All", value: "" },
              {
                label: (
                  <Row
                    type="flex"
                    align="middle"
                    justify="center"
                    gutter={[5, 0]}
                  >
                    <Col>
                      <Blip color="#72C91C" margin={"0 0.25vw 0 0"} />
                    </Col>
                    <Col>Resolved</Col>
                  </Row>
                  // "Resolved"
                ),
                value: "read",
              },
              {
                label: (
                  <Row
                    type="flex"
                    align="middle"
                    justify="center"
                    gutter={[5, 0]}
                  >
                    <Col>
                      <Blip color="#FF4235" margin={"0 0.25vw 0 0"} />
                    </Col>
                    <Col>Un-Resolved</Col>
                  </Row>
                  // "Un-Resolved"
                ),
                value: "unread",
              },
            ]}
          />
        </Col>
      </Row>
      {current_sprint > 1 ? (
        renderMessageCard()
      ) : (
        <Row
          type="flex"
          style={{ marginTop: 40, marginBottom: 36, textAlign: "center" }}
          gutter={{ xs: 8, sm: 16, md: 24, lg: 24 }}
          justify="space-between"
        >
          {emptyStateMessage()}
        </Row>
      )}
    </React.Fragment>
  );
};

export default connect((state) => ({ messageState: state.Messages }), {
  openModal,
  closeModal,
  closeAllModal,
  MessagesRequest,
})(Messages);
