import {
  ORGANIZATION_REQUEST,
  ORGANIZATION_SUCCESS,
  ORGANIZATION_ERROR,

  ADD_ORGANIZATION_REQUEST,
  ADD_ORGANIZATION_SUCCESS,
  ADD_ORGANIZATION_ERROR,

  ORGANIZATION_DETAIL_REQUEST,
  ORGANIZATION_DETAIL_SUCCESS,
  ORGANIZATION_DETAIL_ERROR,

  UPDATE_ORGANIZATION_REQUEST,
  UPDATE_ORGANIZATION_SUCCESS,
  UPDATE_ORGANIZATION_ERROR
  // FLAGGED_DEL_ERROR,
  // FLAGGED_DEL_SUCCESS,
  // FLAGGED_DEL_REQUEST,
  // FLAGGED_BAN_REQUEST,
  // FLAGGED_BAN_SUCCESS,
  // FLAGGED_BAN_ERROR,
} from '../configurations/Types';

export function organizationRequest(limit, offset, search, active, cb) {
  return {
    type: ORGANIZATION_REQUEST,
    params: {
      limit: limit || 100,
      offset: offset || 0,
      like: search || '',
      active: active,
    },
    callback: cb
  }
}

export function organizationReceived(organizationData) {
  return {
    type: ORGANIZATION_SUCCESS,
    payload: organizationData
  }
}

export function organizationError(errorInfo) {
  return {
    type: ORGANIZATION_ERROR,
    payload: errorInfo,
  }
}


export function addOrganizationRequest(body,cb) {
  return {
    type: ADD_ORGANIZATION_REQUEST,
    body,
    callback: cb
  }
}

export function addOrganizationSuccess(organizationData) {
  return {
    type: ADD_ORGANIZATION_SUCCESS,
    payload: organizationData
  }
}

export function addOrganizationError(errorInfo) {
  return {
    type: ADD_ORGANIZATION_ERROR,
    payload: errorInfo,
  }
}


export function organizationDetailRequest(param,cb) {
  return {
    type: ORGANIZATION_DETAIL_REQUEST,
    param,
    callback: cb
  }
}

export function organizationDetailRecieved(organizationData) {
  return {
    type: ORGANIZATION_DETAIL_SUCCESS,
    payload: organizationData
  }
}

export function organizationDetailError(errorInfo) {
  return {
    type: ORGANIZATION_DETAIL_ERROR,
    payload: errorInfo,
  }
}


export function updateOrganizationRequest(param,body,cb) {
  return {
    type: UPDATE_ORGANIZATION_REQUEST,
    body,
    param,
    callback: cb
  }
}

export function updateOrganizationSuccess(organizationData) {
  return {
    type: UPDATE_ORGANIZATION_SUCCESS,
    payload: organizationData
  }
}

export function updateOrganizationError(errorInfo) {
  return {
    type: UPDATE_ORGANIZATION_ERROR,
    payload: errorInfo,
  }
}


// export function deleteFlaggedRequest(id, cb) {
//   return {
//     type: FLAGGED_DEL_REQUEST,
//     id: id,
//     callback: cb
//   }
// }
// export function deleteFlaggedReceived(deleteMsg) {
//   return {
//     type: FLAGGED_DEL_SUCCESS,
//     payload: deleteMsg,
//   }
// }
// export function deleteFlaggedError(errorInfo) {
//   return {
//     type: FLAGGED_DEL_ERROR,
//     payload: errorInfo
//   }
// }

// export function banFlaggedRequest(id, cb) {
//   return {
//     type: FLAGGED_BAN_REQUEST,
//     id: id,
//     callback: cb
//   }
// }
// export function banFlaggedReceived(deleteMsg) {
//   return {
//     type: FLAGGED_BAN_SUCCESS,
//     payload: deleteMsg,
//   }
// }
// export function banFlaggedError(errorInfo) {
//   return {
//     type: FLAGGED_BAN_ERROR,
//     payload: errorInfo
//   }
// }