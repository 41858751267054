import { resetHeaders, privateAgent} from "../configurations/AxiosAgent";
import { store } from "../ConfigureStore";
import { loginSuccess, logoutRequest } from "../actions/Auth";

const login = (auth, cb) => {
    //let auth = res.data.data;
    window.localStorage.setItem("reviseuserauth", JSON.stringify(auth))
    privateAgent.defaults.headers = { 
        ...privateAgent.defaults.headers,
        "x-auth-token": auth.token,
        "userId": auth.userId
    }
    // console.log("privateAgent.defaults.headers::",privateAgent.defaults.headers)
    if (cb)
    {
       cb(auth.user_id);
    }
        
    store.dispatch(loginSuccess(auth));
}

const logout = () => {
    console.log("I AM CALLED");
    localStorage.removeItem("reviseuserauth");
    resetHeaders(privateAgent);
    store.dispatch(logoutRequest());
}

const isLogin = () => {
    if (localStorage.getItem("reviseuserauth")) {
        //private agent headers set
        return true;
    }

    return false;
}

const redirect = (...params) => {
    //let history = store.getState().App.history;
    //console.log("HISTORY", history)
    //history.push(...params);
    // store.dispatch(redirect(url))
}

export {login, logout, isLogin, redirect}; 