import { takeLatest,put } from "redux-saga/effects";
import moment from "moment";
import info from "../components/message/index";
import { privateAgent, CancelToken } from "../configurations/AxiosAgent";
import {
  GET_FIELD_OPTIONS_REQUEST,
  GET_ACCOUNTS_REQUEST,
  GET_JOBS_REQUEST,
  ADD_JOB_REQUEST,
  ADD_ACCOUNT_NAME_REQUEST,
  EDIT_ACCOUNT_NAME_REQUEST
} from "../configurations/Types";
import { addAccountSuccess, addAccountError, editAccountSuccess, editAccountError } from "../actions/FieldOptions";
import {
  // MOVE CONTACT ENDPOINT TO A NEW SAGA.
  CONTACT_USER_ENDPOINT,
  TRANSACTION_ENDPOINT,
  ACCOUNTS_ENDPOINT,
  GET_JOBS_ENDPOINT,
  ADD_JOB_ENDPOINT,
  ACCOUNT_NAME_ENDPOINT,
  EDIT_ACCOUNT_NAME_ENDPOINT
} from "../configurations/Constants";

import { addJobSuccess, addJobError } from "../actions/FieldOptions";

let current_time = () => moment().format("YYYY-MM-DD HH:mm:ss");
//get Field Options
const getFieldOptionsApi = contact_type => {
  return privateAgent.get(`${CONTACT_USER_ENDPOINT}`, {
    params: {
      contact_type
    }
  });
};

function* getFieldOptions(action) {
  let res;

  try {
    res = yield getFieldOptionsApi(action.contactType);
    if (action.cb) {
      let data = Object.values(res.data.data.user_contacts);
      action.cb(null, data);
    }
  } catch (e) {
    if (e.response) {
      info("error", `${e.response.data.error.message}`);
    }
    if (action.cb) {
      action.cb(e);
    }
  }
}
//get Field Options

// get Accounts
const getAccountsApi = (accountType, bankAccount) => {
  return privateAgent.get(`${TRANSACTION_ENDPOINT}${ACCOUNTS_ENDPOINT}`, {
    params: {
      account_type: accountType,
      bank_account_id: bankAccount
    }
  });
};

function* getAccountsRequest(action) {
  let res;

  try {
    res = yield getAccountsApi(action.accountType, action.bankAccount);
    if (action.cb) {
      action.cb(null, res.data.data);
    }
  } catch (e) {
    if (e.response) {
      info("error", `${e.response.data.error.message}`);
    }
    if (action.cb) {
      action.cb(e);
    }
  }
}

// Add Account Name
let addAccountToken;
const addAccountNameApi = (body, token) => {
  return privateAgent.post(`${TRANSACTION_ENDPOINT}${ACCOUNT_NAME_ENDPOINT}`, {
    ...body,
    current_time: current_time()
  }, {
    cancelToken: token.token
  });
};
function* addAccountNameRequest(action) {
  let res;
  try {
    if (addAccountToken) {
      addAccountToken.cancel();
    }
    addAccountToken = CancelToken.source();
    res = yield addAccountNameApi(action.body, addAccountToken);
    info("success", `${res.data.data.message}`);
    yield put(addAccountSuccess());
    if (action.cb) {
      action.cb();
    }
  } catch (e) {
    if (e.response) {
      info("error", `${e.response.data.error.message}`);
      yield put(addAccountError());
      
    }
  }
}
// Edit Account Name
let editAccountToken;
const editAccountNameApi = (body, id, token) => {
  return privateAgent.put(`${TRANSACTION_ENDPOINT}${EDIT_ACCOUNT_NAME_ENDPOINT}/${id}`, {
    ...body,
    current_time: current_time()
  }, {
    cancelToken: token.token
  });
};
function* editAccountNameRequest(action) {
  let res;
  try {
    if (editAccountToken) {
      editAccountToken.cancel();
    }
    editAccountToken = CancelToken.source();
    res = yield editAccountNameApi(action.body, action.id, editAccountToken);
    info("success", `${res.data.data.message}`);
    yield put(editAccountSuccess());
    if (action.cb) {
      action.cb();
    }
  } catch (e) {
    if (e.response) {
      info("error", `${e.response.data.error.message}`);
      yield put(editAccountError());
      
    }
  }
}
//get Jobs
const getJobsApi = () => {
  return privateAgent.get(`${TRANSACTION_ENDPOINT}${GET_JOBS_ENDPOINT}`);
};

function* getJobsRequest(action) {
  let res;

  try {
    res = yield getJobsApi();
    if (action.cb) {
      action.cb(null, res.data.data);
    }
  } catch (e) {
    if (e.response) {
      info("error", `${e.response.data.error.message}`);
    }
    if (action.cb) {
      action.cb(e);
    }
  }
}
//get Jobs

//add Jobs
let addJobToken;
const addJobApi = (body, token) => {
  // let current_time = moment().format("YYYY-MM-DD HH:mm:ss");
  console.log("addContactApi", body);
  return privateAgent.post(`${TRANSACTION_ENDPOINT}${ADD_JOB_ENDPOINT}`, {
    ...body,
    current_time: current_time()
  }, {
    cancelToken: token.token
  });
};

function* addJobsRequest(action) {
  console.log("action", action);
  let res;
  try {
    if (addJobToken) {
      addJobToken.cancel();
    }
    addJobToken = CancelToken.source();
    res = yield addJobApi(action.body, addJobToken);
    info("success", `${res.data.data.message}`);
    yield put(addJobSuccess());
    if (action.cb) {
      action.cb();
    }
  } catch (e) {
    if (e.response) {
      info("error", `${e.response.data.error.message}`);
    }

    yield put(addJobError());
  }
}

//add Jobs

// Our watcher Saga: spawn all Saga request for Field Options
export function* watchFieldOptions() {
  yield takeLatest(GET_FIELD_OPTIONS_REQUEST, getFieldOptions);
  yield takeLatest(GET_ACCOUNTS_REQUEST, getAccountsRequest);
  yield takeLatest(GET_JOBS_REQUEST, getJobsRequest);
  yield takeLatest(ADD_JOB_REQUEST, addJobsRequest);
  yield takeLatest(ADD_ACCOUNT_NAME_REQUEST, addAccountNameRequest);
  yield takeLatest(EDIT_ACCOUNT_NAME_REQUEST, editAccountNameRequest);
}
