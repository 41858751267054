import {
  SET_STRIPE,
  TOGGLE_MODE,
  CHANGE_PASSWORD_REQUEST,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_ERROR,
  CONTACT_REQUEST,
  CONTACT_SUCCESS,
  CONTACT_ERROR,
  SET_HISTORY,
  REDIRECT,
} from "../configurations/Types";

const INITIAL_STATE = {
  history: null,
  mode: "light",
  stripe: null,
  loading: false,
  backButtonLabel: "Go Back",
  scroll: false,
  externalScreen: false,

  contactFormLoading: false,

  // faqs: []
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_STRIPE: {
      //console.log("SET STRIPE", action)
      return Object.assign({}, state, { stripe: action.payload });
      //return { ..state }
    }
    case TOGGLE_MODE: {
      return Object.assign({}, state, {
        mode: state.mode === "light" ? "dark" : "light",
      });
    }
    case CHANGE_PASSWORD_REQUEST: {
      return Object.assign({}, state, { loading: true });
    }
    case CHANGE_PASSWORD_SUCCESS: {
      return Object.assign({}, state, {
        profileDetails: action.payload,
        loading: false,
      });
    }
    case CHANGE_PASSWORD_ERROR: {
      return Object.assign({}, state, { loading: false });
    }

    case CONTACT_REQUEST: {
      return Object.assign({}, state, { contactFormLoading: true });
    }
    case CONTACT_SUCCESS: {
      return Object.assign({}, state, { contactFormLoading: false });
    }
    case CONTACT_ERROR: {
      return Object.assign({}, state, { contactFormLoading: false });
    }
    case SET_HISTORY: {
      return Object.assign({}, state, { history: action.payload });
    }
    case REDIRECT: {
      let url = action.payload;

      let externalScreen = false;
      let scroll = false;
      let backButtonLabel = "Go Back";

      switch (url) {
        case "/requests": {
          scroll = true;
          // externalScreen = true;
          // backButtonLabel = "Add Bank Account";
          break;
        }
        case "/messages": {
          scroll = true;
          // externalScreen = true;
          // backButtonLabel = "Add Bank Account";
          break;
        }
        case "/flagged": {
          scroll = true;
          // externalScreen = true;
          // backButtonLabel = "Add Bank Account";
          break;
        }
        case "/add-bank": {
          externalScreen = true;
          backButtonLabel = "Add Bank Account";
          break;
        }
        case "/add-recurring": {
          externalScreen = true;
          backButtonLabel = "Add Recurring";
          break;
        }
        case "/update-profile": {
          externalScreen = true;
          backButtonLabel = "Edit Profile";
          break;
        }
        case "/add-contact": {
          externalScreen = true;
          backButtonLabel = "Add Contact";
          break;
        }
        case "/transfer-journal": {
          externalScreen = true;
          backButtonLabel = "Transfer Journal";
          break;
        }
        case "/print-cheques": {
          externalScreen = true;
          backButtonLabel = "Print Checks";
          break;
        }
        case "/print-cheques/details": {
          externalScreen = true;
          backButtonLabel = "Check Details";
          break;
        }
        case "/reports/send-report": {
          externalScreen = true;
          backButtonLabel = "Send Report";
          break;
        }
        case "/reports/preview-chart-report": {
          externalScreen = true;
          backButtonLabel = "Charts Preview";
          break;
        }
        case "/reports/preview-chart-of-accounts": {
          externalScreen = true;
          backButtonLabel = "Chart of Accounts";
          break;
        }
        case "/reports/preview-bank-account-ledger": {
          externalScreen = true;
          backButtonLabel = "Bank Account Ledger";
          break;
        }
        case "/reports/preview-trial-balance-summary": {
          externalScreen = true;
          backButtonLabel = "Trial Balance Summary";
          break;
        }
        case "/reports/preview-profit-and-loss": {
          externalScreen = true;
          backButtonLabel = "Profit and Loss";
          break;
        }
        case "/reports/preview-journal-transfer-ledger": {
          externalScreen = true;
          backButtonLabel = "Journal/Transfer Ledger";
          break;
        }
        case "/reports/preview-reconciliation-for-lawyers": {
          externalScreen = true;
          backButtonLabel = "Reconciliation for Laywers";
          break;
        }
        default: {
        }
      }

      //for non exact routes
      if (url.includes("/bank-account-details/")) {
        externalScreen = true;
        backButtonLabel = "Bank Account Details";
      }
      if (url.includes("/edit-bank/")) {
        externalScreen = true;
        backButtonLabel = "Edit Bank Account";
      }
      if (url.includes("/view-transaction/")) {
        externalScreen = true;
        backButtonLabel = "Transaction Details";
      }
      if (url.includes("/edit-transaction/")) {
        externalScreen = true;
        backButtonLabel = "Edit Transaction";
      }
      if (url.includes("/edit-contact/")) {
        externalScreen = true;
        backButtonLabel = "Edit Contact";
      }
      if (url.includes("/users/")) {
        externalScreen = false;
        scroll = true;
        backButtonLabel = "User Details";
      }

      //traer
      if (url.includes("/requests/")) {
        externalScreen = true;
        backButtonLabel = "Request Details";
      }

      //traer
      // console.log(externalScreen)

      return Object.assign({}, state, { externalScreen, scroll, backButtonLabel });
    }

    default: {
      return state;
    }
  }
};
