import React from "react";
import { Layout } from "antd";
import styled from "styled-components";
import HeaderContent from "./HeaderContent";
import ExternalHeader from "./ExternalHeader";

const { Header } = Layout;
// ${StyledContent}
const StyledHeader = styled(Header)`
  // margin: 0;
  // padding: 0;
  // min-height: auto;
  background: ${props => props.theme[props.theme.mode].background.primary};
  color: ${props => props.theme[props.theme.mode].textColor.primary};
  padding: ${props => (props.externalscreen ? `0 ${props.theme[props.theme.mode].spacing.mainscreen}` : `0 ${props.theme[props.theme.mode].spacing.mainscreen} 0 ${props.theme[props.theme.mode].spacing.mainscreen}`)};
  box-shadow: ${props =>
    props.externalscreen ? "0px 5px 20px #1E1E220C" : "0px 5px 20px #1E1E220C"};
  z-index: 2000;
  position: ${props => (props.externalscreen ? "static" : "fixed")};
  top: 0;
  left: 0;
  width: 100%;
  text-align: left;
  height: ${props => props.theme[props.theme.mode].spacing.mainscreen};
  //${props => props.externalscreen ? "auto" : props.theme[props.theme.mode].spacing.mainscreen};
  & .headerIcon {
    color: ${props => props.theme[props.theme.mode].textColor.primary};
  }
  &.ant-layout-header{
    height:60px
  }
  @media screen and (max-width: 767px) {
    padding: ${props => props.externalscreen ? "0px 20px" : "0px 20px"};
  }
  &.ant-layout-header{
    padding:0
  }
  p {
    font-size: ${props =>
      props.theme[props.theme.mode].textSizes.relative.body};
    margin: 0;
    font-weight: 400;
    line-height: 1.3;
  }

  input,
  .inputField[type],
  .inputField input,
  .selectField,
  .selectField.ant-select-dropdown-menu-item,
  .selectField.ant-select .ant-select-selection__rendered,
  textarea.inputField.ant-input {
    font-size: ${props =>
      props.theme[props.theme.mode].textSizes.relative.captions};
    margin: 0;
    font-weight: 400;
    line-height: 1.3;
    letter-spacing: 0.021vw;
  }

  .button,
  .navigation-bar {
    font-size: ${props => props.theme[props.theme.mode].textSizes.relative.btn};
    letter-spacing: 0.021vw;
  }
  @media screen and (max-width: 991px) {
    p
    {
      font-size: calc(${props =>
        props.theme[props.theme.mode].textSizes.relative.body} - 4px);
    }
    /* p, */
    input,
    .inputField[type],
    .inputField input,
    .selectField,
    .selectField.ant-select-dropdown-menu-item,
    .selectField.ant-select .ant-select-selection__rendered,
    textarea.inputField.ant-input {
      font-size: calc(
        ${props => props.theme[props.theme.mode].textSizes.relative.captions} - 4px
      );
      letter-spacing: 0.4px;
    }
    .button,
    .navigationBar {
      font-size: calc(
        ${props => props.theme[props.theme.mode].textSizes.relative.btn} - 4px
      );
      letter-spacing: 0.45px;
    }
  }

  i {
    font-size: ${props =>
      props.theme[props.theme.mode].textSizes.relative.generalHeading};
  }

  /* Should be globalized */
  .flexFill {
    flex-grow: 1;
  }

  .zeroLineHeight {
    line-height: 0;
  }
  @media screen and (max-width: 767px) {
    /* ${props => (props.externalscreen ? "height: 100%;" : null)} */
    p,
    input,
    .inputField[type],
    .inputField input,
    .selectField,
    .selectField.ant-select-dropdown-menu-item,
    .selectField.ant-select .ant-select-selection__rendered,
    textarea.inputField.ant-input {
      font-size: ${props =>
        props.theme[props.theme.mode].textSizes.actual.body};
    }
    .button,
    .navigationBar {
      font-size: ${props => props.theme[props.theme.mode].textSizes.actual.btn};
    }
  }
`;

const HeaderWrapper = props => {
  return (
    <StyledHeader
      externalscreen={props.externalscreen ? 1 : 0}
      //style={{ padding: "0px" }}
    >
      {props.externalscreen && false ? (
        <ExternalHeader {...props} />
      ) : (
        <HeaderContent {...props} />
      )}
    </StyledHeader>
  );
};

export default HeaderWrapper;
