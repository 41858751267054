import { put, takeLatest, cancelled } from "redux-saga/effects";
import {
  REQUESTS_REQUEST,
  REQUEST_REQUEST,
  USER_REQUESTS_REQUEST,
  DELETE_DELIVERY_REQUEST,
} from "../configurations/Types";
import {
  requestsSuccess,
  requestsError,
  requestSuccess,
  requestError,
  userRequestsSuccess,
  userRequestsError,
  requestRequest,
  deleteDeliveryError,
  deleteDeliverySuccess,
  clearRequest
} from "../actions/Requests";
// import { tableData } from "../mocks/Users"
import {
  REQUESTS_ENDPOINT,
  REQUEST_ENDPOINT,
  USER_REQUESTS_ENDPOINT,
} from "../configurations/Constants";
import { privateAgent, CancelToken } from "../configurations/AxiosAgent";
import info from "../components/message/index";
import moment from "moment";
const requestsApi = (params, token) =>
  privateAgent.get(REQUESTS_ENDPOINT, { cancelToken: token.token, params });

let requestsToken;

// Our worker Saga: will perform the async MessagesRequest task
function* requests(action) {
  let res;
  try {
    if (requestsToken) {
      requestsToken.cancel();
    }
    requestsToken = CancelToken.source();
    res = yield requestsApi(action.params, requestsToken);
    console.log("MESSAGE API GET", res.data);
    yield put(requestsSuccess(res.data.data));
  } catch (e) {
    console.log("ERROR", e);
    try {
      info(
        "error",
        `${e.response ? e.response.data.error.message : "Network error"}`
      );
    } catch (e) {}
    yield put(requestsError(res));
  } finally {
    if (yield cancelled()) {
      console.log("CANCELLED");
    }
  }
  //console.log("In saga", res);
  // if(action.callback) {
  //   action.callback(res);
  // }
}

const userRequestsApi = (params, id, token) =>
  privateAgent.get(`${USER_REQUESTS_ENDPOINT}/${id}`, {
    cancelToken: token.token,
    params,
  });

let userRequestsToken;

function* userRequests(action) {
  let res;
  try {
    if (userRequestsToken) {
      userRequestsToken.cancel();
    }
    userRequestsToken = CancelToken.source();
    res = yield userRequestsApi(action.params, action.id, userRequestsToken);
    console.log("MESSAGE API GET", res.data);
    yield put(userRequestsSuccess(res.data.data));
  } catch (e) {
    console.log("ERROR", e);
    try {
      info(
        "error",
        `${e.response ? e.response.data.error.message : "Network error"}`
      );
    } catch (e) {}
    yield put(userRequestsError(res));
  } finally {
    if (yield cancelled()) {
      console.log("CANCELLED");
    }
  }
  //console.log("In saga", res);
  // if(action.callback) {
  //   action.callback(res);
  // }
}

const requestApi = (id) => privateAgent.get(`${REQUEST_ENDPOINT}/${id}`);

// let requestToken;

function* request(action) {
  let res;
  try {
    // if (requestToken) {
    //   requestToken.cancel();
    // }
    // requestToken = CancelToken.source();
    res = yield requestApi(action.id);
    console.log("MESSAGE API GET", res.data);
    yield put(
      requestSuccess({
        data: res.data.data,
        traveller: res.data.TravelerInfo,
        travellerRequests:
          res.data.Travelers && Array.isArray(res.data.Travelers)
            ? res.data.Travelers
            : [],
      })
    );
  } catch (e) {
    console.log("ERROR", e);
    try {
      info(
        "error",
        `${e.response ? e.response.data.error.message : "Network error"}`
      );
    } catch (e) {}
    yield put(requestError(res));
  } finally {
    if (yield cancelled()) {
      console.log("CANCELLED");
    }
  }
  //console.log("In saga", res);
  // if(action.callback) {
  //   action.callback(res);
  // }
}

// Delete delivery request
const deleteDeliveryAPi = (id) =>
  privateAgent.delete(`${REQUEST_ENDPOINT}/${id}`);
function* deleteDeliveryRequest(action) {
  let res;
  try {
    res = yield deleteDeliveryAPi(action.id);
    yield put(deleteDeliverySuccess());
    yield put(clearRequest());
    info("success", `Delivery Request Has been deleted`);
  } catch (e) {
    yield put(deleteDeliveryError());
    info("error", `Delivery Request error`);
  }
}
// Delete delivery request
// Our watcher Saga: spawn a new getUsers task on each MESSAGES_REQUEST
export function* watchRequests() {
  yield takeLatest(REQUESTS_REQUEST, requests);
  yield takeLatest(REQUEST_REQUEST, request);
  yield takeLatest(USER_REQUESTS_REQUEST, userRequests);
  yield takeLatest(DELETE_DELIVERY_REQUEST, deleteDeliveryRequest);
}
