import React from 'react';
import styled from "styled-components";
import ReactCodeInput from 'react-code-input';

const StyledInput = styled(ReactCodeInput)`
input {
    text-align: center;
    font-size:  inherit;
    background-color: ${props => props.theme[props.theme.mode].background.primary};
    color: ${props => props.theme[props.theme.mode].textColor.primary};
    border: 1px solid ${props => props.theme[props.theme.mode].primary.main}38;
    border-radius:5px;
    /* margin: 4px; */
    width: 3rem;
    height: 3rem;
    margin-right:0.8rem;
    margin-bottom:0.8rem;
    box-shadow: none;
    outline: ${props => props.theme[props.theme.mode].primary.main} !important;;
}

input:last-child {
  margin-right: 0;
}

input:hover {
  border: 1px solid #151D30
  // ${props => props.theme[props.theme.mode].primary.main}; 
   border-color: #151D30
  //  ${props => props.theme[props.theme.mode].primary.main} !important;
}

input:focus {
  border: 1px solid #151D30
  // ${props => props.theme[props.theme.mode].primary.main};
  /* border-color: ${props => props.theme[props.theme.mode].primary.main} !important; */
  box-shadow: 0 0 0 2px ${props => props.theme[props.theme.mode].primary.main}38;
}

* {
    color: ${props => props.theme[props.theme.mode].primary.main};
}
`;

const CodeWrapper = (props) => {
  return (
    <StyledInput {...props} />
  )
}

export default CodeWrapper;