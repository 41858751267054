import * as Yup from "yup";

const emojiRegex = /\p{Extended_Pictographic}/u;
export const addUserSchema = Yup.object().shape({

  email: Yup.string()
  .min(5, "Email must contains atleast 5 characters")
    .max(60, "Email must be under 60 characters!")
    .test(
      'Emoji',
      'Emojies are not allowed',
      (value) => !emojiRegex.test(value)
  )
    // .matches(/\p{Extended_Pictographic}/u,'Emojies are not allowed')
    .matches(
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,3}))$/,
      "Email is not valid!"
    )
    .required("Email Required"),
    // /^[^]+$/"

    userName: Yup.string()
    .min(3, "Name is too short")
    .max(50, "Name is Too Long!")
    //  .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z0-9\W\S]{8,16}$/, 'Must contain one uppercase and one lowercase.')
    // .matches(/^.+\s.+$/, "Name required")
    .required("Name Required")
    .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field ")



 });