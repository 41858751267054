import React, { useState, useEffect } from "react";
import ContentHeader from "../../../appcomponents/shares/contentheader";
import { Switch } from "react-router-dom";
import TableWrapper from "../../../components/react-table";
import SwitchWrapper from "../../../components/switch";
import AvatarWrapper from "../../../components/avatar/index";
import EmptyProfilePic from "../../../assets/images/empty-profile.png";
import { Row, Col, Pagination } from "antd";
import { connect, useDispatch, useSelector } from "react-redux";
import { usersRequest } from "../../../actions/Users";
import PopconfirmWrapper from "../../../components/pop-confirm";
import UserFloatingCard from "../../../appcomponents/general/FloatingCard";
import { FiAlertTriangle } from "react-icons/fi";
import { MdDelete } from "react-icons/md";
import Blip from "../../../appcomponents/shares/blip";
import { openModal, closeModal } from "../../../actions/Modal";
import SubscriptionUpdate from "../../../appcomponents/shares/organization/SubscriptionUpdate";
import SubscriptionModal from "../../../components/modals/subscriptionModal";
import DeleteConfirm from "../../../components/modals/DeleteConfirm";

const Users = (props) => {
  const [tableChanged, setTableChanged] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [userData, setUserData] = useState("");
  const [subscribed, setsubscribed] = useState();
  const [current, setCurrent] = useState(1);
  const [state, setState] = useState({
    search: "",
    offset: 0,
    limit: 10,
  });

  const dispatch = useDispatch();
  const objGetUser = {
    search: state.search,
    limit: state.limit,
    offset: state.offset,
  };
  const userId = JSON.parse(localStorage.getItem("reviseuserauth"))?.user?.id;

  useEffect(() => {
    const payload = { ...objGetUser };
    dispatch(
      usersRequest(payload, userId, (res) => {
        if (res.data.status == 400)
          dispatch(
            openModal(
              <SubscriptionModal
                callback={() => {
                  setsubscribed(!subscribed);
                  if (localStorage.getItem("reviseuserauth")) {
                    var userData = JSON.parse(
                      window.localStorage.getItem("reviseuserauth")
                    );
                  }
                  setUserData(userData);
                  // alert("final call back")
                }}
              />,
              null,null,null,{footer: null, maskClosable: false, closable: false, body:'none'}
              // { footer: null, maskClosable: false, closable: false }
            )
          );
      })
    );
    return ()=>{
      props.closeModal()
    }
  }, []);

  const data = useSelector((state) => {
    return state;
  });
  console.log("allUSer_data: ", data);

  // useEffect(() => {
  //   if (localStorage.getItem("reviseuserauth")) {
  //     let userData = JSON.parse(window.localStorage.getItem("reviseuserauth"));
  //     console.log(userData, "user data");
  //     props.openModal(
  //       "",

  //       <SubscriptionModal />,
  //       null,
  //       null,
  //       { footer: null, maskClosable: false, closable: false }
  //     );
  //     setUserData(userData);
  //   }

  //   console.log(userData, "USER DATA");
  // }, [props.userData]);

  const [fil] = useState({
    apiCall: (...params) => {
      let userData = JSON.parse(localStorage.getItem("reviseuserauth"));
      props.usersRequest({ ...params[0] }, userData.organization_id);
    },
  });
  // useEffect(() => {
  //   if (localStorage.getItem("reviseuserauth")) {
  //     var userData = JSON.parse(window.localStorage.getItem("reviseuserauth"));
  //   }

  //   //TODO uncomment this for subcription
  //   {
  //     (!userData.subscription || userData?.subscription?.status == 0) &&
  //       props.openModal(
  //         "",

  //         <SubscriptionModal
  //           callback={() => {
  //             setsubscribed(!subscribed);
  //             if (localStorage.getItem("reviseuserauth")) {
  //               var userData = JSON.parse(
  //                 window.localStorage.getItem("reviseuserauth")
  //               );
  //             }
  //             setUserData(userData);
  //             // alert("final call back")
  //           }}
  //         />,
  //         null,
  //         null,
  //         { footer: null, maskClosable: false, closable: false }
  //       );
  //   }

  //   setUserData(userData);
  // }, []);

  // const changeOffset = (abc) => {
  //   let payload = {
  //     limit: 10,
  //     offset: state.offset + 10,
  //     search: "",
  //   };
  //   // alert(abc)
  //   let userData = JSON.parse(localStorage.getItem("reviseuserauth"));
  //   props.usersRequest(payload, userData.organization_id);
  //   setState({
  //     ...state,
  //     offset: state.offset + 10,
  //   });
  // };

  const handlePages = (pageNumber) => {
    const offset = (pageNumber - 1) * 10;
    const payload = { ...objGetUser, offset };
    dispatch(usersRequest(payload));
    setState({ ...state, offset });
    setCurrent(pageNumber);
  };

  // const BackOffset = (abc) => {
  //   let payload = {
  //     limit: 10,
  //     offset: state.offset - 10,
  //     search: "",
  //   };
  //   let userData = JSON.parse(localStorage.getItem("reviseuserauth"));
  //   props.usersRequest(payload, userData.organization_id);
  //   console.log(abc, "pag");
  //   setState({
  //     ...state,
  //     offset: state.offset - 10,
  //   });
  // };

  const searchChange = (e) => {
      const payload = {
        ...objGetUser,
        search: e
      };

      props.usersRequest(payload);
      setState({ ...state, search: "",limit:'' ,offset:''});
  };


  const { Users } = data;
  return (
    <Switch>
      <Row type="flex" gutter={[15, 20]}>
        <Col span={24}>
          {/* remove ||true */}
          {(userData?.subscription && userData?.subscription?.status == 1) ||
            (true && (
              <ContentHeader
                title="Users"
                count={Users?.totalCount}
                searchChange={searchChange}
                showAddButton
                hideOption
                onClick={() =>
                  props.openModal(
                    "",

                    <SubscriptionUpdate
                      callback={() => {
                        // props.closeModal()
                        setTableChanged(!tableChanged);
                      }}
                      currentState={state}
                    />,
                    null,
                    null,
                    { footer: null }
                  )
                }
                options={[
                  { label: "View All", value: "all" },

                  {
                    label: (
                      <Row
                        type="flex"
                        align="middle"
                        justify="center"
                        gutter={[5, 0]}
                      >
                        <Col>
                          <Blip color="#F8604E" margin={"0 0.3vw 0 0"} />
                        </Col>

                        <Col>Organization</Col>
                      </Row>
                    ),
                    value: "organization",
                  },
                  {
                    label: (
                      <Row
                        type="flex"
                        align="middle"
                        justify="center"
                        gutter={[5, 0]}
                      >
                        <Col>
                          <Blip color="#AD1E23" margin={"0 0.3vw 0 0"} />
                        </Col>

                        <Col>Individual</Col>
                      </Row>
                    ),
                    value: "individual",
                  },
                  {
                    label: (
                      <Row
                        type="flex"
                        align="middle"
                        justify="center"
                        gutter={[5, 0]}
                      >
                        <Col>
                          <Blip color="#717070" margin={"0 0.3vw 0 0"} />
                        </Col>

                        <Col>Org member</Col>
                      </Row>
                    ),
                    value: "organization member",
                  },
                  {
                    label: (
                      <Row
                        type="flex"
                        align="middle"
                        justify="center"
                        gutter={[5, 0]}
                      >
                        <Col>
                          <Blip color="#717070" margin={"0 0.3vw 0 0"} />
                        </Col>

                        <Col>Int member</Col>
                      </Row>
                    ),
                    value: "interested",
                  },

                  {
                    label: (
                      <Row
                        type="flex"
                        align="middle"
                        justify="center"
                        gutter={[5, 0]}
                      >
                        <Col>
                          <Blip color="#22E6AA" margin={"0 0.3vw 0 0"} />
                        </Col>

                        <Col>Active user</Col>
                      </Row>
                    ),
                    value: "activeUsers",
                  },
                  {
                    label: (
                      <Row
                        type="flex"
                        align="middle"
                        justify="center"
                        gutter={[5, 0]}
                      >
                        <Col>
                          <Blip color="#717070" margin={"0 0.3vw 0 0"} />
                        </Col>

                        <Col>Inactive user</Col>
                      </Row>
                    ),
                    value: "bannedUsers",
                  },
                ]}
              />
            ))}
        </Col>
        {/* remove ||true */}
        {(userData?.subscription && userData?.subscription?.status == 1) ||
          (true && (
            <Col span={24}>
              <TableWrapper
                tableData={Users?.users}
                Pagination={false}
                loading={false}
                // changeOffset={changeOffset}
                // BackOffset={BackOffset}
                filters={state}
                search={state.search}
                pageSize={10}
                dataCount={Users?.totalCount}
                // getTrProps={(state, rowInfo, column) => ({
                //   onClick: () =>
                //     props.history.push({
                //       pathname: `/users/${rowInfo.original.user_id}`,

                //       state: {
                //         referrer: "/users",
                //         user: rowInfo.original,
                //       },
                //     }),
                //   style: { cursor: "pointer" },
                // })}
                // getTdProps={(state, rowInfo, column, c, d) => {
                //   return column.className === "clickable"
                //     ? {
                //         onClick: (e) => e.stopPropagation(),
                //         style: { cursor: "default", overFlow: "hidden" },
                //       }
                //     : {};
                // }}
                tableChanged={tableChanged}
                content={[
                  {
                    name: "",
                    id: "profile_picture",
                    centered: true,
                    small: true,
                    sortable: false,
                    render: (r) => {
                      return (
                        <AvatarWrapper
                          shape="circle"
                          size="large"
                          src={r.original["profile_picture"] || EmptyProfilePic}
                        ></AvatarWrapper>
                      );
                    },
                  },
                  {
                    name: "Name",
                    id: "fullName",
                    render: (r) => {
                      return (
                        <span
                          style={{
                            // cursor: "pointer",
                            textTransform: "capitalize",
                          }}
                          onClick={() => {
                            setSelectedUserId(r.original.id);
                          }}
                        >
                          {r.original.userName}
                        </span>
                      );
                    },
                  },
                  {
                    name: "Email",
                    id: "email",
                    sortable: false,
                  },

                  // {
                  //   name: "Actions",
                  //   id: "actions",
                  //   sortable: false,
                  //   className: "clickable",
                  //   render: (r) => {
                  //     return (
                  //       <Row
                  //         type="flex"
                  //         style={{ width: "100%" }}
                  //         align="middle"
                  //       >
                  //         <Col>
                  //           <PopconfirmWrapper
                  //             title={
                  //               <h4>{console.log('statusVall',r.original)}
                  //                 Are you sure you want to
                  //                 {r.original.status === 1
                  //                   ? " deactivate "
                  //                   : " activate "}
                  //                 this user?
                  //               </h4>
                  //             }
                  //             icon={
                  //               <FiAlertTriangle
                  //                 style={{
                  //                   position: "absolute",
                  //                   color: "#f44336",
                  //                   left: 0,
                  //                   top: 0,
                  //                   marginTop: 6,
                  //                 }}
                  //               />
                  //             }
                  //             cancelText="No"
                  //             okText="Yes"
                  //             onConfirm={function () {
                  //               dispatch(
                  //                 changeUserStatusRequest(
                  //                   {
                  //                     // ...state,
                  //                     user_id: r.original["id"],
                  //                     status:
                  //                       r.original["status"] === 1 ? 3 : 1,
                  //                   },
                  //                   (res) => {
                  //                     dispatch(
                  //                       usersRequest({
                  //                         ...state,
                  //                       })
                  //                     );
                  //                     setTableChanged(!tableChanged);
                  //                   }
                  //                 )
                  //               );
                  //             }}
                  //           >
                  //             <SwitchWrapper
                  //               checked={
                  //                 r?.original?.status == 1 ? true : false
                  //               }
                  //               style={{ marginRight: 10 }}
                  //             />
                  //           </PopconfirmWrapper>
                  //         </Col>
                  //         <Col>
                  //           <p style={{ fontWeight: 600 }}>
                  //             User is{" "}
                  //             {r.original["status"] == 1
                  //               ? "Active"
                  //               : "In-Active"}
                  //           </p>
                  //         </Col>
                  //       </Row>
                  //     );
                  //   },
                  // },
                  {
                    name: "",
                    id: "deleteuser",
                    className: "clickable",
                    centered: true,
                    sortable: false,
                    render: (r) => {
                      return (
                        <MdDelete
                          size={25}
                          style={{
                            color: "#151D30",
                            cursor: "pointer",
                          }}
                          onClick={() =>
                            props.openModal(
                              "",
                              <DeleteConfirm
                                userData={{ state, user: r.original }}
                                user_id={r.original["user_id"]}
                                callback={() => {
                                  setTableChanged(!tableChanged);
                                }}
                              />,
                              null,
                              null,
                              { footer: null }
                            )
                          }
                        />
                      );
                    },
                  },
                ]}
              />
            </Col>
          ))}
        <Pagination
          responsive="true"
          showLessItems="true"
          current={current}
          className="tbl-pagination"
          pageSize={10}
          total={Users?.totalCount}
          onChange={(e) => {
            console.log("Page number is:", e);
            handlePages(e);
          }}
        />
      </Row>
    </Switch>
  );
};

export default connect((storeState) => ({ userData: storeState.Users }), {
  usersRequest,
  // changeUserStatusRequest,
  openModal,
  closeModal,
})(Users);
