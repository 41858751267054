import React, { useEffect, useCallback, useState } from "react";
import { connect, useSelector, useDispatch } from "react-redux";
import { Redirect } from "react-router-dom";
import { useHistory } from "react-router-dom";
import Loading from "../../../components/loading";
import { Row, Col, Empty } from "antd";
import RowCard from "../../../appcomponents/shares/organization/RowCard";
import SubscriptionUpdate from "../../../appcomponents/shares/organization/SubscriptionUpdate";
import Icon from "../../../components/Icon";
import { openModal, closeModal, closeAllModal } from "../../../actions/Modal";
import Input from "../../../components/input";
import useFormik from "../../../components/formik/useFormik";
import { changeUserStatusRequest } from "../../../actions/Users";
import { clearRequests } from "../../../actions/Requests";
import { organizationDetailRequest } from "../../../actions/Organization";
import TableWrapper from "../../../components/react-table";
import EmptyProfilePic from "../../../assets/images/empty-profile.png";
import AvatarWrapper from "../../../components/avatar/index";
import { FiEdit2 } from "react-icons/fi";
import moment from "moment";

const OrganizationDetails = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(organizationDetailRequest());
  }, []);

  const data = useSelector((state) => {
    return state;
  });

  const { organizationDetail } = data?.Organization;
  console.log("organizationDetail: ", organizationDetail);

  const {
    name,
    email,
    location,
    phone,
    pricePerUser,
    subscriptionPackage,
    usersLimit,
    orgSubscription,
    orgMembersCount,
  } = organizationDetail;
  console.log("orgSubscription: ", orgSubscription);

  return (
    <Row>
      <Col span={24}>
        <Row
          gutter={[10, 10]}
          style={{ display: "flex", alignItems: "center" }}
        >
          <Col>
            <Icon
              type="arrow-left"
              style={{ fontSize: "15px", color: "#151D30", cursor: "pointer" }}
              onClick={() => history.push("/users")}
            />
          </Col>
          <Col>
            <h4 style={{ marginLeft: 10, marginTop: 5 }}> Profile Details</h4>
          </Col>
        </Row>
      </Col>
      <Col
        style={{
          paddingLeft: "8%",
          paddingRight: "8%",
          paddingTop: 30,
          paddingBottom: 50,
        }}
        span={24}
      >
        <h3 style={{ fontWeight: "bold", marginBottom: "20px" }}>
          User Details
        </h3>
        <Row gutter={[10, 10]}>
          <Col span={12}>
            <label>Name</label>
            <Input value={name && name} disabled />
          </Col>
          <Col span={12}>
            <label>Email</label>
            <Input value={email && email} disabled />
          </Col>
        </Row>
        <Row gutter={[10, 10]}>
          <Col span={12}>
            <label>Location</label>
            <Input value={location && location} disabled />
          </Col>
          <Col span={12}>
            <label>Phone number</label>
            <Input value={phone && phone} disabled />
          </Col>
        </Row>
        <Row gutter={[10, 10]}>
          <Col span={24}>
            <h3 style={{ fontWeight: "bold", marginBottom: "20px" }}>
              Subscription Details
            </h3>
          </Col>
          <Col span={24}>
            <RowCard
              loading={!subscriptionPackage}
              title={"Plan"}
              value={`${subscriptionPackage && subscriptionPackage} - £ ${
                usersLimit * pricePerUser
              } / years`}
              // style={{ length: "20px" }}
            />
          </Col>
          <Col span={24}>
            <RowCard
              loading={!orgSubscription}
              title={"Expiring"}
              value={`${moment(orgSubscription?.endDate).format("DD-MM-YYYY")}`}
              // style={{ length: "20px" }}
            />
          </Col>
          <Col span={24}>
            <RowCard
              loading={!orgSubscription}
              title={"Billed On"}
              value={`${moment(orgSubscription?.startDate).format(
                "Do"
              )} of Each Month`}
              // style={{ length: "20px" }}
            />
          </Col>
          <Col span={24}>
            <RowCard
              loading={!orgMembersCount}
              title={"Number of Users"}
              value={`${orgMembersCount && orgMembersCount} / ${
                usersLimit && usersLimit
              }`}
              // style={{ length: "20px" }}
            />
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default OrganizationDetails;
