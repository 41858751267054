import React from "react";
import { connect } from "react-redux";
import { Row, Col } from "antd";

import useFormik from "../../components/formik/useFormik";
import { useDispatch } from "react-redux";
import Button from "../../components/button";
import { deleteUser } from "../../../src/actions/Users";
import Icon from "../../components/Icon";

import { closeModal } from "../../actions/Modal";

const SubscriptionUpdate = (props) => {
  const dispatch = useDispatch();
  const { state, user } = props.userData;

  const formik = useFormik({
    initialValues: {
      userName: user?.userName,
      email: user?.email,
      id: user?.id,
      state,
    },
    onSubmit: (values, { ...rest }) => {
      dispatch(
        deleteUser(values, (res) => {
          if (res?.data?.status == 200) {
            props.closeModal();
            props.callback();
          }
        })
      );
    },
  });

  return (
    <Row type="flex" justify="center" align="middle">
      <Col span={24} justify="center" align="middle" style={{ marginTop: 20 }}>
        <h3>Confirm Delete</h3>
      </Col>
      <Col span={24} justify="center" align="middle" style={{ marginTop: 20 }}>
        <p>Do you really want to delete?</p>
      </Col>

      <Col span={24} justify="center" align="middle" style={{ marginTop: 2 }}>
        <p>This action can not be un-done!</p>
      </Col>
      <div style={{ display: "flex", flexDirection: "column" }}>
        <div
          style={{
            marginTop: "40px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Button
            style={{
              width: 200,
              boxShadow: "0px 5px 5px 1px rgba(0,0,0,0.15)",
              // backgroundColor: "#AD1E23",
              color: "#ffffff",
            }}
            isGreen={true}
            sharpBorder
            size={"large"}
            block
            onClick={() => formik.submitForm()}
          >
            {props.organizationState.loading ? (
              <Icon type="loading" style={{ fontSize: "1rem" }} />
            ) : (
              "Delete User"
            )}
          </Button>
        </div>

        <div
          style={{
            marginTop: "10px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Button
            style={{ width: 200, backgroundColor: "#151D30", color:'#fff' }}
            sharpBorder
            size={"large"}
            block
            onClick={() => props.closeModal()}
          >
            {props.organizationState.loading ? (
              <Icon type="loading" style={{ fontSize: "1rem" }} />
            ) : (
              "Cancel"
            )}
          </Button>
        </div>
      </div>
    </Row>
  );
};

export default connect((state) => ({ organizationState: state.Organization }), {
  closeModal,
  deleteUser,
})(SubscriptionUpdate);
