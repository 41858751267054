import { USERS_SUCCESS,
    USER_REQUEST,
    USER_SUCCESS,
    USER_ERROR,
    CLEAR_USER,
    //INSTA_USER_SUCCESS,
     USERS_REQUEST,
    
    USERS_ERROR,
    ADD_USER_REQUEST,
    ADD_USER_SUCCESS,
    ADD_USER_ERROR,
    DELETE_USER_REQUEST,
    DELETE_USER_SUCCESS,
    DELETE_USER_ERROR,
    GET_USER_DETAIL_SUCCESS,
    GET_USER_DETAIL_REQUESST,
    GET_USER_DETAIL_ERROR,
    CLEAR_LOGOUT
    } from "../configurations/Types"
  
  const INITIAL_STATE = {
    totalCount: 0,
    users: [],
    profileDetails: null,
    profileLoading: false,
    userDetail:{},
  };
  
  export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
      case USERS_SUCCESS: {
        const {data,count} = action.payload
        // if (state.users.length !== 0 && !action.clearFlag && !action.ofPage) {
        //   arr = [ ...arr];
          
        // }
        // if (action.ofPage) {
        //   let { page, pageSize } = action.ofPage;
        //   if (page < (pages - 1))
  
        //   Old approach - faulty due to missing records
        //   let oldIds = state.users.slice(page * pageSize, (page * pageSize) + (pageSize + 1)).map(oldUser => oldUser.id);
        //   arr = arr.filter((user) => oldIds.includes(user.id));
  
        //   let newUsers = [...state.users];
        //   newUsers.splice(page * pageSize, pageSize, ...arr);
  
        //   arr = newUsers;
  
        //   Another faulty approach - Missing records
        //   let newPageIds = arr.map(user => user.id);
  
        //   let nextPage = [...state.users].slice((page * pageSize) + pageSize, ((page * pageSize) + pageSize) + (pageSize + 1));
        //   nextPage = nextPage.filter(user => !newPageIds.includes(user.id));
  
        //   let newUsers = [...state.users];
        //   newUsers.splice(page * pageSize, pageSize * 2, ...[...arr, ...nextPage]);
  
        //   arr = newUsers;
  
        //   let newUsers = [...state.users];
        //   newUsers.splice((page * pageSize), newUsers.length - (page * pageSize), ...arr);
  
        //   arr = newUsers;
        // }
        return Object.assign({}, state, 
          { totalCount: count, 
            users: data.map(item => item.user),
            profileLoading: false,
          });
      }
      case USERS_REQUEST: {

        return Object.assign({}, state, { profileLoading: true });
      }
      // case USERS_SUCCESS:{
      //   return Object.assign({}, state, { users: action.payload, profileLoading: false });
      // }
      // case INSTA_USER_SUCCESS: {
      //   console.log("INSTA DETAILS", action.payload)
      //   return Object.assign({}, state, {instaDetails: action.payload})
      // }
      case USER_REQUEST: {
        return Object.assign({}, state, { profileLoading: true });
      }
      case USER_SUCCESS: {
        console.log("GOT USER", action.payload)
        return Object.assign({}, state, { profileDetails: action.payload, profileLoading: false });
      }
      case USER_ERROR: {
        return Object.assign({}, state, { profileLoading: false });
      }
      case CLEAR_USER: {
        return Object.assign({}, state, { profileDetails: null });
      }
      case USERS_ERROR: {
        console.log("Error")
        return Object.assign({}, state, { totalCount: 0, users: [] });
      }
      case ADD_USER_REQUEST:{
        return {...state, loading:true}
      }
  
      case ADD_USER_SUCCESS:{
        return {...state, loading:false}
      }
  
      case ADD_USER_ERROR:{
        return {...state, loading:false}
      }
      case DELETE_USER_REQUEST:{
        return {...state, loading:true}
      }
  
      case DELETE_USER_SUCCESS:{
        return {...state, loading:false}
      }
  
      case DELETE_USER_ERROR:{
        return {...state, loading:false}
      }
      case GET_USER_DETAIL_REQUESST:{
        return {...state, loading:true}
      }
      case GET_USER_DETAIL_SUCCESS:{
    console.log("action pAYLOAD",action.payload)
        return {...state,loading:false,userDetail:action.payload}
      }
      case GET_USER_DETAIL_ERROR:{
        console.log('actionADA',action)
        return {...state,loading:false}
      }
      case CLEAR_LOGOUT:{
        return {...state,userDetail:{}}
      }
      default: {
        return state;
      }
    }
  }