import React from "react";
import MenuWrapper from "../menu";
import { Menu } from "antd";
import Image from "../../components/image";
import { withRouter, Link } from "react-router-dom";
// import HomeIcon from "../../assets/icons/home-line.svg";
// import ProfileIcon from "../../assets/icons/user-4-line.svg";
import {
  FiDollarSign,
  FiPieChart,
  FiPlus,
  FiFileText,
  FiSearch,
  FiUsers,
  FiSettings,
  FiLayers,
} from "react-icons/fi";
//import Logo from "../../assets/images/logo01.png";
import HomeIcon from "../../assets/icons/navbar/dashboard-line2.svg";
import GroupLine from "../../assets/icons/navbar/group-line.png";
import RequestsIcon from "../../assets/icons/navbar/file-line.svg";
import FlagsIcon from "../../assets/icons/navbar/affiliate-marketing.svg";
import MessagesIcon from "../../assets/icons/navbar/subscription.svg";
import styled from "styled-components";

const NavigationWrapper = styled.div`
  & {
    position: fixed;
    bottom: 0;
    width: 100%;
    z-index: 3000;
    box-shadow: 0px 5px 20px
      ${(props) => props.theme[props.theme.mode].textColor.primary}0D;
    background-color: ${(props) => props.theme[props.theme.mode].common.white};
    .ant-menu {
      justify-content: space-between;
    }
    .newMatchIcon {
      width: 2.313rem;
      min-width: auto;
    }
    .ant-menu-item.sideBarItem {
      height: 2.5rem;
      width: 2.5rem;
    }
  }
`;

const NavigationMenu = (props) => {
  return (
    <React.Fragment>
      <NavigationWrapper>
        <MenuWrapper
          mode="horizontal"
          defaultSelectedKeys={[props.location.pathname]}
          selectedKeys={[props.location.pathname]}
        >
          <Menu.Item title="Home" className="sideBarItem" key="/">
            <Link to="/">
              <Image width={20} src={HomeIcon} />
            </Link>
          </Menu.Item>
          <Menu.Item title="Users" className="sideBarItem" key="/users">
            <Link to="/users">
              <Image width={20} src={GroupLine} />
            </Link>
          </Menu.Item>
          {/* <Menu.Item title="Requests" className="sideBarItem" key="/requests">
            <Link to="/requests">
              <Image width={20} src={RequestsIcon} />
            </Link>
          </Menu.Item> */}
          <Menu.Item title="Messages" className="sideBarItem" key="/messages">
            <Link to="/messages">
              <Image width={20} src={MessagesIcon} />
            </Link>
          </Menu.Item>
          <Menu.Item title="organization" className="sideBarItem" key="/organization">
            <Link to="/organization">
              <Image width={20} src={FlagsIcon} />
            </Link>
          </Menu.Item>
        </MenuWrapper>
      </NavigationWrapper>
    </React.Fragment>
  );
};

export default withRouter(NavigationMenu);
