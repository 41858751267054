import React from "react";
import { connect } from "react-redux";
import Input from "../../components/input/index";
import RootForm from "../../components/form/index";
import { resetPassword } from "../../configurations/Schemas/index";
import AuthContainer from "../../appcomponents/shares/auth/AuthContainer";
import { Row, Col } from "antd";
import { changePasswordRequest } from "../../actions/Auth";

class ResetScreen extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email:"",
      password: "",
      confirm_password: ""
    };
  }
  componentDidMount() {
    if (this.props.history.location.state != undefined) {
      if (this.props.history.location.state.email != undefined) {
        this.setState({
          email: this.props.history.location.state.email
        });
      }
      else{
        this.props.history.push({
          pathname: "/login",
        });  
      }
    }
    else{
      this.props.history.push({
        pathname: "/login",
      });  
    }
  }
  handleChanged = e => {
    const { name, value } = e.target;
    this.setState({
      [name]: value
    });
  };
  
  render() {
    return (
      <AuthContainer
      >
        <Row type="flex" justify="center" align="middle" gutter={[10, 30]}>
          <Col span={24}>
            <h3 className='mr-t mr-b'>
              Reset Your Password
            </h3>
            <p>
            Choose a new password
            </p>
          </Col>
          <Col xs={24} sm={22}>
            <RootForm
              schemaName={resetPassword}
              initialValues={this.state}
              apiCall={changePasswordRequest}
              extras={this.state.email}
              loading={this.props.Auth.forgotPasswordLoading}
              cb={() => {
                this.props.history.push({
                  pathname: "/login",
                });
              }}
              //autoWidthSubmit
              controls={[
                {
                  span: 24,
                  name: "password",
                  value: this.state.password,
                  handleChanged: this.handleChanged,
                  placeholder: "New Password",
                  Type: Input
                },
                {
                  span: 24,
                  name: "confirm_password",
                  value: this.state.confirmPassword,
                  handleChanged: this.handleChanged,
                  placeholder: "Confirm Password",
                  Type: Input
                }
              ]}
              submitButton={{
                span: 14,
                buttonText: "Reset",
                className: "mr-t-md mr-b-md bg-gradient",
              }}
            />
          </Col>
        </Row>
      </AuthContainer>
    );
  }
}

export default connect(storeState => ({ Auth: storeState.Auth }))(ResetScreen);
