import React,{useState,useEffect} from "react";
import { useSelector } from "react-redux";
import Input from "../../components/input/index";
import RootForm from "../../components/form/index";
import { loginValidations } from "../../configurations/Schemas/index";
import AuthContainer from "../../appcomponents/shares/auth/AuthContainer";
import { Row, Col } from "antd";
import { loginRequest } from "../../actions/Auth";


const LoginScreen = props => {
  const [state, setState] = useState({
    email: "",
    password: "",
  })

  useEffect(()=>{
    if (localStorage.getItem("reviseuserauth")) {
        props.history.push("/");
  }
},[])

  const data = useSelector(state => {
    return state
  })

  const handleChanged = (e) => {
    const { name, value } = e.target;
    setState({
      [name]: value,
    });
  };

  return(
    <AuthContainer
    withImage
  >
    <Row type="flex" justify="center" align="middle" gutter={[10, 30]}>
      <Col span={24}>
        <h3 className='mr-b mr-t'>Welcome</h3>
        <p style={{ fontWeight:'600' }}>Please login to continue</p>
      </Col>
      <Col xs={24} sm={22}>
        <RootForm
          schemaName={loginValidations}
          initialValues={state}
          apiCall={loginRequest}
          extras={(email) =>
            props.history.push({
              pathname: "/signup",
              state: { fromVerify: true, email },
            })
          }
          loading={data.Auth.loginLoading}

          controls={[
            {
              span: 24,
              name: "email",
              value: state.email,
              handleChanged: handleChanged,
              placeholder: "Email",
              Type: Input,
            },
            {
              span: 24,
              name: "password",
              value: state.password,
              handleChanged: handleChanged,
              placeholder: "Password",
              Type: Input,
            },
          ]}
          isLink={{
            status: "true",
            style: {color:"#E42F36"},
            linktext: "Forgot Password?",
            url: "forgot-password",
            className:
              "link-color caption mr-b-md textLeft",
            align: "start",
          }}
          submitButton={{
            span: 14,
            buttonText: "Login",
            className: "mr-b-md bg-gradient",
          }}
        />
      </Col>
    </Row>
  </AuthContainer>
  )
}

export default LoginScreen
