import { put, takeLatest, cancelled } from 'redux-saga/effects';
import { MESSAGES_REQUEST, MESSAGE_RESPONSE_REQUEST, MESSAGE_DEL_REQUEST } from "../configurations/Types";
import { MessagesSuccess, MessagesError, MessagesDelSuccess, MessageResponseSuccess } from "../actions/Messages";
// import { tableData } from "../mocks/Users"
import { 
      MESSAGE_ENDPOINT,
      DELETE_MESSAGE_ENDPOINT,
      REPLY_MESSAGE_ENDPOINT
      } from "../configurations/Constants";
import { privateAgent, CancelToken } from "../configurations/AxiosAgent";
import info from "../components/message/index";
import moment from "moment";
const messagesAPI = (params, token) => privateAgent.get(MESSAGE_ENDPOINT, { cancelToken: token.token, params })
const messageDelAPI = (msgID) => privateAgent.delete(`${DELETE_MESSAGE_ENDPOINT}/${msgID}`)
const messageResponseAPI = (params) => privateAgent.post(`${REPLY_MESSAGE_ENDPOINT}/${params.id}`) 
//   ...params, 
//   //current_time: moment().format("YYYY-MM-DD HH:MM:SS") 
// })

let token;

// Our worker Saga: will perform the async MessagesRequest task
function* MessagesGetRequest(action) {
  console.log("Messages saga run", action)
  let res;
  try {
    if (token) {
      token.cancel();
    }
    token = CancelToken.source();
    res = yield messagesAPI(action.params, token);
    console.log("MESSAGE API GET", res.data.data);
    yield put(MessagesSuccess(res.data.data))
  
  }
  catch (e) {
    console.log("ERROR", e);
    try {
      info("error", `${e.response ? e.response.data.message : "Network error"}`);
    }
    catch (e) {}
    yield put(MessagesError(res))
  }
  finally {
    if (yield cancelled()) {
      console.log("CANCELLED");
    }
  }
  //console.log("In saga", res);
  if(action.callback) {
    action.callback(res);
  }
}

/* ------------ FOR DELETE REQUEST -----------------------*/
function* MessageDelRequest(action) {
  console.log("Message Delete saga run", action)
  let res;
  try {
  res = yield messageDelAPI(action.msgID);
  console.log("MESSAGE DEL API Response", res.data);
  info("success", `Message deleted successfully!`)
  yield put(MessagesDelSuccess(res))
  if(action.callback) {
    action.callback(res);
  }
  //yield put(MessagesRequest())
  }
  catch (e) {
    console.log("ERROR", e);
    try {
      info("error", `${e.response ? e.response.data.message : "Network error"}`);
    }
    catch (e) {}
  }
  finally {
    if (yield cancelled()) {
      console.log("CANCELLED");
    }
  }
  //console.log("In saga", res);
}
/* ------------ FOR Response -----------------------*/
function* MessageResponseRequest(action) {
  console.log("Message Response saga run", action)
  let res;
  try {
  res = yield messageResponseAPI(action.body);
  info("success", `Reply sent!`)
  console.log("MESSAGE Response API Response", res.data);
  yield put(MessageResponseSuccess(res))
  
  }
  catch (e) {
    console.log("ERROR", e);
    try {
      info("error", `${e.response ? e.response.data.message : "Network error"}`);
    }
    catch (e) {}
    //info("error", `${e.response ? e.response.data.error.message : "Network error"}`);
  }
  finally {
    if (yield cancelled()) {
      console.log("CANCELLED");
    }
  }
  //console.log("In saga", res);
  if(action.callback) {
    action.callback(res);
  }
}
// Our watcher Saga: spawn a new getUsers task on each MESSAGES_REQUEST
export function* watchMessagesRequest() {
  yield takeLatest(MESSAGES_REQUEST, MessagesGetRequest)
  yield takeLatest(MESSAGE_DEL_REQUEST, MessageDelRequest)
  yield takeLatest(MESSAGE_RESPONSE_REQUEST, MessageResponseRequest)
}