import React, { useEffect, useCallback } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { Link } from "react-router-dom";
import Loading from "../../../components/loading";
import { Row, Col } from "antd";
import Icon from '../../../components/Icon'
import { useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import Input from "../../../components/input";
import useFormik from "../../../components/formik/useFormik";
import { changeUserStatusRequest } from "../../../actions/Users";
import { getSubscriptionStatus } from "../../../actions/Subscription";
import { clearRequests } from "../../../actions/Requests";
import RowCard from "../../../appcomponents/shares/organization/RowCard";
import SettingsCard from "../../../components/card/settingsCard";
import { formattedDate } from "../../../configurations/Config";
import {useSelector} from 'react-redux'


const UserDetails = (props) => {
  const { user } = props.location.state ? props.location.state : {};
  const userId = props.match.params.id;
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const status=useSelector(state=>state.Auth?.profileDetails?.subscription?.product_name)
 

  const formik = useFormik({
    //validationSchema: LawyerSchema,
    initialValues: location.state || {
      lawyer_title: "",
      lawyer_name: "",
      lawyer_email: "",
    },
    onSubmit: (values, { ...rest }) => {
      console.log("Submitted: ", values, rest);
      const { enable, ...data } = values;
      // if (location.pathname === "/dashboard/lawyers/add-lawyer") {
      //   dispatch(
      //     addLawyerRequest(data, () => {
      //       history.push("/dashboard/lawyers");
      //     })
      //   );
      // } else {
      //   dispatch(
      //     updateLawyerRequest(data, () => {
      //       history.push("/dashboard/lawyers");
      //     })
      //   );
      // }
      // dispatch(fetchLawyersRequest());
    },
  });



  const field = useCallback(
    (placeholder, name, value, customProps) => (
      <>
        <Input
          style={{ marginBottom: '1%', marginTop: '5px' }}
          placeholder={placeholder}
          value={value}
          name={name}
          disabled
          onChange={(e) => {
            //console.log(e.target.value, 'inside onChange func of ', name)
            formik.customHandleChange(e, name);
          }}
          onBlur={(e) => formik.customHandleBlur(e, name)}
          {...customProps}
        />
        <span className="dangerColor caption textCenter">
          {formik.touched[name] && formik.errors[name]}
        </span>
      </>
    ),
    [formik]
  );

          return(
            <>
            <Col>
    
              <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyItems: "center" }}>
                <Link to="/users">
                  <Icon type="arrow-left" style={{ fontSize: '15px', color: '#AD1E23' }} />
                </Link>
                <h4 style={{ marginLeft: 10, marginTop: 5 }}>  Profile Details</h4>
              </div>
            </Col>
    
            <Col style={{ paddingLeft: '8%', paddingRight: '8%', paddingTop: 30, paddingBottom: 50 }}>
              <div>
                <h3 style={{ fontWeight: "bold", marginBottom: '10px' }}> User Details </h3>
                <Row gutter={{ xs: 24, sm: 24, md: 24, lg: 24 }}>
    
                  <Row type={"flex"} gutter={{ xs: 24, sm: 24, md: 24, lg: 24 }}>
    
                    <Col style={{ marginBottom: '15px' }} xl={12} lg={12} sm={24} xs={24} span={12} >
                      <Col span={24}>
                        <label className="mr-b-sm" >Name</label>
                        {field(
                          user.user_name,
                          "user name",
                          // formik.values[user.user_name]
                        )}
                      </Col>
                    </Col>
    
    
                    <Col style={{ marginBottom: '15px' }} xl={12} lg={12} sm={24} xs={24} span={12} >
                      <Col span={24}>
                        <label>Email</label>
                        {field(
                          // user.email,
                          "user email",
                          // formik.values[user.email]
                        )}
                      </Col>
                    </Col>
                  </Row>
    
                  {user.type === "organization" ?
                    <Row type={"flex"} gutter={{ xs: 24, sm: 24, md: 24, lg: 24 }}>
                      <Col style={{ marginBottom: '15px' }} xl={12} lg={12} sm={24} xs={24} span={12} >
                        <Col span={24}>
                          <label>Phone Number</label>
                          {field(
                            // user.phone,
                            'phone',
                            // formik.values[user.phone],
                            {
                              onChange: (e) => {
                                formik.customHandleChange(e, "email");
                                // here you dispatch your api for checking the email
                              },
                            }
                          )}
                        </Col>
                      </Col>
    
                      {/* <Col style={{ marginBottom: '15px' }} xl={12} lg={12} sm={24} xs={24} span={12} >
                        <Col span={24}>
                          <label>Location</label>
                          {field(
                            user.location,
                            'location',
                            formik.values[user.location],
                            {
                              onChange: (e) => {
                                formik.customHandleChange(e, "email");
                                // here you dispatch your api for checking the email
                              },
                            }
                          )}
                        </Col>
                      </Col> */}
                    </Row> : null}
    
                  <Row type={"flex"} gutter={{ xs: 24, sm: 24, md: 24, lg: 24 }}>
                    {/* <Col style={{ marginBottom: '15px' }} xl={12} lg={12} sm={24} xs={24} span={12} >
                      <Col span={24}>
                        <label >User Type</label>
                        {field(
                          user.type,
                          "user_type",
                          formik.values[user.type],
                          {
                            onChange: (e) => {
                              formik.customHandleChange(e, "email");
                              // here you dispatch your api for checking the email
                            },
                          }
                        )}
                      </Col>
                    </Col> */}
    
                    {/* <Col style={{ marginBottom: '15px' }} xl={12} lg={12} sm={24} xs={24} span={12} >
                      <Col span={24}>
                        <label>User Status</label>
                        {field(
                          user.status,
                          "use r_status",
                          formik.values[user.status],
                          {
                            onChange: (e) => {
                              formik.customHandleChange(e, "email");
                              // here you dispatch your api for checking the email
                            },
                          }
                        )}
                      </Col>
                    </Col> */}
                  </Row>
                </Row>
              </div>
    
              <div style={{ marginTop: '20px' }}>
                <h3 style={{ fontWeight: "bold", marginBottom: '10px' }}>
                  Subscription Details
                </h3>
    
                <div style={{ marginTop: '20px' }}>
                  <Row style={{ height: 60 }} xs={12} sm={12} md={12} lg={12} xl={12}>
                    <RowCard
                      // loading={loading}
                      title={'Plan'}
                      value={status}
                      style={{ length: "20px" }} />
                  </Row>
                  <br />
                  <Row style={{ height: 60 }} xs={12} sm={12} md={12} lg={12} xl={12}>
                    <RowCard
                      // loading={loading}
                      title={'Expiring'}
                      value={user?.subscription ? formattedDate(user?.subscription?.end_date) : ""}
                      style={{ length: "20px" }} />
                  </Row>
                  <br />
                  <Row style={{ height: 60 }} xs={12} sm={12} md={12} lg={12} xl={12}>
                    <RowCard
                      // loading={loading}
                      title={'Billed On'}
                      value={user.subscription ? formattedDate(user?.subscription?.start_date) : ""}
                      style={{ length: "20px" }} />
                  </Row>
                  <br />
                </div>
              </div>
    
    
    
    
              <div style={{ marginTop: '20px' }}>
                <h3 style={{ fontWeight: "bold", marginBottom: '10px' }}>
                  Other settings
    
                </h3>
    
                <div style={{ marginTop: 20 }}>
                  <Row style={{ height: 60 }} xs={12} sm={12} md={12} lg={12} xl={12}>
                    <SettingsCard
                      // loading={loading}
                      title={'Change Password'}
                      // value={organization?.subscription?.plan}
                      style={{ length: "20px" }} />
                  </Row>
                  <br />
                  <Row style={{ height: 60 }} xs={12} sm={12} md={12} lg={12} xl={12}>
                    <SettingsCard
                      // loading={loading}
                      title={'Logout'}
                      logout
                      // value={organization?.subscription?.expiry_date}
                      style={{ length: "20px" }} />
                  </Row>
                  <br />
    
                </div>
              </div>
    
    
    
    
            </Col>
          </>
          )
  // useEffect(() => {
  //   props.getSubscriptionStatus(() => { }, user.user_id ,  "userDetails")
  //   console.log("PROPS", user);
  // }, []);
  // useEffect(() => { }, [
  //   user
  // ])


  // return true ? (
    // user ? (

      // <Row type="flex" gutter={[15, 15]} justify="space-between">
      //   <Col xs={24} lg={6}>
      //     <Row type="flex" gutter={[15, 15]}>
      //       <Col span={24}>
      //         <InfoCard name={full_name} email={email} />
      //       </Col>
      //       <Col span={24}>
      //         <ClearedCard 
      //         checked={status !== "2"}
      //         onClick={e => {
      //           props.changeUserStatusRequest(
      //             {
      //               user_id: parseInt(userId),
      //               status: e ? 1 : 2
      //             },
      //             (res) => {
      //               let newRouteState = {...props.location.state, user: { ...props.location.state.user, status: e ? 1 : 2 } };

      //               props.history.replace({
      //                 pathname: props.location.pathname,
      //                 state: newRouteState
      //               });
      //             }
      //               // setTableChanged(
      //               //   r.original["status"] === "2" ? 1 : 2
      //               // )
      //           );
      //         } }
      //         />
      //       </Col>
      //     </Row>
      //   </Col>
      //   <Col xs={24} lg={18}>
      //     <Requests userId={userId} />
      //   </Col>
      // </Row>
     


  //   ) : (
  //     <Redirect to="/" />
  //     // <div style={{ margin: "30vh 0", textAlign: "center" }}>
  //     //   <Empty description="User not found" />
  //     // </div>
  //   )
  // ) : (
  //   <Loading />
  // );
};
export default UserDetails
// export default connect(null,{ changeUserStatusRequest, clearRequests, getSubscriptionStatus: (cb, id , from) => getSubscriptionStatus(cb, id ,from) })(UserDetails);
