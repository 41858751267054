import {
  ADD_BANK_ACCOUNT_REQUEST,
  ADD_BANK_ACCOUNT_SUCCESS,
  ADD_BANK_ACCOUNT_ERROR,
  GET_BANK_ACCOUNTS_REQUEST,
  GET_BANK_ACCOUNTS_ERROR,
  GET_BANK_ACCOUNTS_SUCCESS,
  UPDATE_BANK_ACCOUNT_REQUEST,
  UPDATE_BANK_ACCOUNT_SUCCESS,
  UPDATE_BANK_ACCOUNT_ERROR,
  DELETE_BANK_ACCOUNT_ERROR,
  DELETE_BANK_ACCOUNT_REQUEST,
  DELETE_BANK_ACCOUNT_SUCCESS,
  GET_ONE_BANK_REQUEST,
  GET_ONE_BANK_SUCCESS,
  GET_ONE_BANK_ERROR,
  CLEAR_ONE_BANK,
  RECORD_ENTRY
} from "../configurations/Types";
// ADD BANK
export const addBankAccountRequest = (body, cb) => {
  return {
    type: ADD_BANK_ACCOUNT_REQUEST,
    body,
    cb
  };
};

export const addBankAccountSuccess = cb => {
  return {
    type: ADD_BANK_ACCOUNT_SUCCESS,
    cb
  };
};

export const addBankAccountError = () => {
  return {
    type: ADD_BANK_ACCOUNT_ERROR
  };
};
// GET ALL BANKS
export const getBankAccountsRequest = (limit, offSet, like, cb) => {
    return {
      type: GET_BANK_ACCOUNTS_REQUEST,
      limit,
      offSet,
      like,
      flag: offSet ? false : true,
      cb,
    };
  };
export const getBankAccountsSuccess = (payload, cb) => {
  return {
    type: GET_BANK_ACCOUNTS_SUCCESS,
    payload: payload,
    cb
  };
};
export const getBankAccountsError = () => {
  return {
    type: GET_BANK_ACCOUNTS_ERROR
  };
};

// DELETE A BANK
export const deleteBankAccountRequest = id => {
  return {
    type: DELETE_BANK_ACCOUNT_REQUEST,
    id
  };
};
export const deleteBankAccountSuccess = cb => {
  return {
    type: DELETE_BANK_ACCOUNT_SUCCESS,
    cb
  };
};
export const deleteBankAccountError = () => {
  return {
    type: DELETE_BANK_ACCOUNT_ERROR
  };
};

// GET SINGLE BANK
export const getSingleBankRequest = id => {
  return {
    type: GET_ONE_BANK_REQUEST,
    id
  };
};
export const getSingleBankSuccess = (payload, cb) => {
  return {
    type: GET_ONE_BANK_SUCCESS,
    payload: payload,
    cb
  };
};
export const getSingleBankError = () => {
  return {
    type: GET_ONE_BANK_ERROR
  };
};
export const clearSingleBank = () => {
  return {
    type: CLEAR_ONE_BANK
  }
}
//update bank
export const updateBankAccountRequest = (body, cb) => {
  return {
    type: UPDATE_BANK_ACCOUNT_REQUEST,
    body,
    cb
  };
};

export const updateBankAccountSuccess = () => {
  return {
    type: UPDATE_BANK_ACCOUNT_SUCCESS
  };
};

export const updateBankAccountError = () => {
  return {
    type: UPDATE_BANK_ACCOUNT_ERROR
  };
};

export const recordEntry = (lawFirmName, bankAccountName, bankName) => ({
  type: RECORD_ENTRY,
  lawFirmName,
  bankAccountName,
  bankName
})
