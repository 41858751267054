import {
  // USERS_REQUEST,
  // USERS_SUCCESS,
  // USERS_ERROR,
  SERACH_TRANSACTIONS_REQUEST,
  SERACH_TRANSACTIONS_SUCCESS,
  SERACH_TRANSACTIONS_ERROR,
  DELETE_TRANSACTION_REQUEST,
  DELETE_TRANSACTION_SUCCESS,
  DELETE_TRANSACTION_ERROR,
  CLEARED_TRANSACTION_REQUEST,
  CLEARED_TRANSACTION_SUCCESS,
  CLEARED_TRANSACTION_ERROR,

  // CHANGE_USER_STATUS_ERROR,

} from "../configurations/Types";
import { DEFAULT_TABLE_PAGE_SIZE } from "../configurations/Constants";
//import rootModal from '../commons/modals/rootModal';
export function searchTransactionsRequest(params, cb, clearFlag, ofPage) {
  console.log("SERACH_TRANSACTIONS_REQUEST action", params);
  return {
    type: SERACH_TRANSACTIONS_REQUEST,
    params,
    callback: cb,
    clearFlag,
    ofPage
  };
}
export function searchTransactionsSuccess(transactionData, clearFlag, ofPage) {
  console.log("searchTransactionsSuccess", transactionData);
  return {
    type: SERACH_TRANSACTIONS_SUCCESS,
    payload: transactionData,
    clearFlag,
    ofPage
  };
}
export function searchTransactionsError(error) {
  return {
    type: SERACH_TRANSACTIONS_ERROR,
    error
  };
}

//--  Delete Transaction
export const deleteTransactionRequest = (id, cb) => {
  console.log("deleted row id", id);
  return {
    type: DELETE_TRANSACTION_REQUEST,
    id,
    cb
  };
};

export const deleteTransactionSuccess = () => {
  return {
    type: DELETE_TRANSACTION_SUCCESS
  };
};

export const deleteTransactionError = () => {
  return {
    type: DELETE_TRANSACTION_ERROR
  };
};

// --Cleared Transactions
export const ClearedTransactionsRequest = (id, flag, cb) => {
  return {
    type: CLEARED_TRANSACTION_REQUEST,
    id,
    flag,
    cb
  };
};

export const ClearedTransactionsSuccess = () => {
  return {
    type: CLEARED_TRANSACTION_SUCCESS
  };
};
export const ClearedTransactionsError = () => {
  return {
    type: CLEARED_TRANSACTION_ERROR
  };
};
