import React from "react";
import styled from "styled-components";
import { Tag } from "antd";
const StyledTag = styled(Tag)`
  text-align: center;
  background-color: ${(props) =>
    props.type === 'free' ? '#ea5c4d' :
    props.type === "subscribed" ?  props.theme[props.theme.mode].primary.main 
      : '#717070'}
  color: ${(props) => props.theme["light"].textColor.inverted};
  border: none;
  margin-right: 0;
  /* padding:0.2vw; */
  padding: ${props => props.defaultWidth ? "0.4rem" : "0.5vw 0.2vw"};;
  width: ${props => props.defaultWidth ? "7rem" : "50%"};
  border-radius: 25px;
  font-size: inherit;
  font-weight: normal;
  &:hover {
    opacity: 1;
  }
  & .ant-tag{}
  .tagWidth {
    width: 7rem
  }
  @media screen and (max-width: 991px) {
    width: ${props => props.defaultWidth ? "7rem" : "100%"};
  }
`;
const TagWrapper = (props) => {
  return <StyledTag {...props}></StyledTag>;
};
export default TagWrapper;
