import { USER_STATS_SUCCESS, MONTHLY_USER_STATS_SUCCESS, APP_DOWNLOADS_SUCCESS, GAME_STATS_SUCCESS, EARNING_STATS_SUCCESS, WAGERS_STATS_SUCCESS } from "../configurations/Types"

const INITIAL_STATE = {
  userCount: 10,
  freeUsers:0,
  conversions:0,
  bannedUsers:0,
  gameCount: 0,
  earningCount: 0,
  wagersCount: 0,
  gameStats: null,
  userStats: null,
  earningStats: null,
  wagersStats: null,
  monthlyUserStats: [10,80,10],
  appDownloads: []
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case USER_STATS_SUCCESS: {
      let stats = action.payload;
      let orderCount = parseInt(stats.confirmDevlieries) + parseInt(stats.expiresDevlieries) + parseInt(stats.pending_devlieries);
      // let status;
      // for (status in action.payload) {
      //   userCount += parseInt(action.payload[status]);
      // } 
      return Object.assign({}, state, { userStats: { ...action.payload, orderCount } });
    }
    case GAME_STATS_SUCCESS: {
      let gameCount = 0;
      let status;
      for (status in action.payload) {
        gameCount += parseInt(action.payload[status]);
      } 
      return Object.assign({}, state, { gameCount, gameStats: action.payload });
    }
    case EARNING_STATS_SUCCESS: {
      let earningCount = 0;
      let status;
      for (status in action.payload) {
        earningCount += parseInt(action.payload[status]);
      } 
      return Object.assign({}, state, { earningCount, earningStats: action.payload });
    }
    case WAGERS_STATS_SUCCESS: {
      let wagersCount = 0;
      let status;
      for (status in action.payload) {
        wagersCount += parseInt(action.payload[status]);
      } 
      return Object.assign({}, state, { wagersCount, wagersStats: action.payload });
    }
    case MONTHLY_USER_STATS_SUCCESS: {
      let monthlyUserStats = Object.keys(action.payload.data).map((key, ind) => ({ id: key, name: key === "bannedUsers" ? "Banned Users" : "Active Users", value: parseInt(action.payload.data[key]) }))
      return Object.assign({}, state, { userCount: action.payload.count, monthlyUserStats: monthlyUserStats });
      
    }
    // case APP_DOWNLOADS_REQUEST: {
    //   return Object.assign({}, state, { appDownloads: [] });
    // }
    case APP_DOWNLOADS_SUCCESS: {
      let appDownloads = Object.keys(action.payload).map((key, ind) => ({ id: key, name: key.split(",")[0].substr(0,3), Price: action.payload[key] }))
      return Object.assign({}, state, { appDownloads: appDownloads });
    }
    default: {
        return state;
    }
  }
}