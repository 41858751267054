import { put, takeLatest ,cancelled } from "redux-saga/effects";
import {
  BUY_SUBSCRIPTION_REQUEST,
  CANCEL_SUBSCRIPTION_REQUEST,
  GET_SUBSCRIPTION_REQUEST,
} from "../configurations/Types";
import {
  GET_SUBSCRIPTION_ENDPOINT,
  CANCEL_SUBSCRIPTION_ENDPOINT,
  BUY_SUBSCRIPTION_ENDPOINT,
  SUBSCRIPTION_ENDPOINT,
  //   CANCEL_SUBSCRIPTION,
} from "../configurations/Constants";

import {
  buySubscriptionSuccess,
  buySubscriptionError,
  cancelSubscriptionSuccess,
  cancelSubscriptionError,
  getSubscriptionStatus,
  getSubscriptionStatusSuccess,
  getSubscriptionStatusError,
  setSubscriptionStatus,
} from "../actions/Subscription";
import moment from "moment";
import info from "../components/message/index";
import { privateAgent } from "../configurations/AxiosAgent";

// buy subscription
const buySubscriptionApi = ({endDate, receiptData , startDate,productId }) =>
  privateAgent.post(`${BUY_SUBSCRIPTION_ENDPOINT}`, {
    endDate, receiptData , startDate,productId
  });





// function* buySubscription(action) {
//   let res;
//   try {
//     let { paymentMethod, error } = yield action.generatePaymentMethod();

//     if (error) throw error;

//     res = yield buySubscriptionApi(paymentMethod, { ...action.params, country: paymentMethod.card.country });
//     info("success", `Purchase successful`);
//     //yield put(setSubscriptionStatus(res.data.data));
//     yield put(getSubscriptionStatus());
//     yield put(buySubscriptionSuccess());

//     if (action.callback) {
//       action.callback();
//     }
//   } catch (e) {
//     if (e.response) {
//       info("error", `${e.response.data.error.message}`);
//     }
//     yield put(buySubscriptionError());
//   }
// }




function* buySubscription({payload,cb}) {
  console.log("ActionInSagaas",payload)
  let res;
  try {
    // if (token) {
    //   token.cancel();
    // }
    // token = CancelToken.source();
    res = yield buySubscriptionApi(payload);
    console.log('Subscription_res: ', res);
    info("success", res?.data?.message)

    if (cb) {
     cb(res);
    }
    // yield put(getSubscription())
    // yield put(updateOrganizationSuccess(res.data.data));
  }
  catch (e) {
    console.log("ERROR", e.response);
    try {
      info("error", `${e.response ? e.response.data.error.message : "Network error"}`);
    }
    catch (e) { }
    // yield put(updateOrganizationError(e));
  }
  finally {
    if (yield cancelled()) {
      console.log("CANCELLED");
    }
  }
  //console.log("In saga", res);
  
}









// cancel subscription
const cancelSubscriptionApi = (params) =>
  privateAgent.post(
    `${GET_SUBSCRIPTION_ENDPOINT}${CANCEL_SUBSCRIPTION_ENDPOINT}`,
    {
      ...params,
      status: true,
      current_time: moment().format("YYYY-MM-DD HH:mm:ss"),
    }
  );
function* cancelSubscription(action) {
  let res;
  try {
    res = yield cancelSubscriptionApi(action.params);
    info("success", `Your subscription has been cancelled`);

    yield put(setSubscriptionStatus({ plan: "none" }));
    //Set new subscription in localStorage
    // let newStorage = JSON.parse(localStorage.getItem("reviseuserauth"));
    // newStorage.subscription = { plan: "none" };

    // localStorage.setItem("reviseuserauth", JSON.stringify(newStorage));

    yield put(cancelSubscriptionSuccess());
    yield put(getSubscriptionStatus());
    // if (action.cb) {
    //   action.cb();
    // }
  } catch (e) {
    if (e.response) {
      info("error", `${e.response.data.error.message}`);
    }
    yield put(cancelSubscriptionError());
  }
}

// get subscriptions
const getSubscriptionApi = () => privateAgent.get(`${GET_SUBSCRIPTION_ENDPOINT}`);
function* getSubscription(action) {
  
  
  let res;
  try {
    res = yield getSubscriptionApi(action.userId);
    if(res.data.status == 200){
      yield put(setSubscriptionStatus(res.data.data));
      if (action.cb) {
        action.cb();
      }
    }
    // info("success", `${res.data.data.message}`);

    //Set new subscription in localStorage
    if(action.from == "subscription"){
    let newStorage = JSON.parse(localStorage.getItem("reviseuserauth"));
    newStorage.subscription = res.data.data;
    localStorage.setItem("reviseuserauth", JSON.stringify(newStorage));
    }

    yield put(getSubscriptionStatusSuccess());
  } catch (e) {
    if (e.response) {
      info("error", `${e.response.data.message}`);
    }

    yield put(getSubscriptionStatusError());
  }
}
// get subscription
export function* watchSubscription() {
  yield takeLatest(BUY_SUBSCRIPTION_REQUEST, buySubscription);
  yield takeLatest(CANCEL_SUBSCRIPTION_REQUEST, cancelSubscription);
  yield takeLatest(GET_SUBSCRIPTION_REQUEST, getSubscription);
}
