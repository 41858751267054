import { 
  USERS_REQUEST, 
  USERS_SUCCESS, 
  USERS_ERROR,
  USER_REQUEST, 
  USER_SUCCESS, 
  USER_ERROR,
  CLEAR_USER,
  // CHANGE_USER_STATUS_REQUEST,
  // CHANGE_USER_STATUS_SUCCESS,
  // CHANGE_USER_STATUS_ERROR,
  ADD_USER_REQUEST,
  ADD_USER_SUCCESS,
  ADD_USER_ERROR,
  DELETE_USER_REQUEST,
  DELETE_USER_SUCCESS,
  DELETE_USER_ERROR,
  GET_USER_DETAIL_REQUESST,
  GET_USER_DETAIL_SUCCESS,
  GET_USER_DETAIL_ERROR,
  CLEAR_LOGOUT

} from '../configurations/Types';

export function usersRequest (payload,userId,cb) {
  return {
    type: USERS_REQUEST,
    payload,
    userId,
    cb
  }
}

export function usersRequestSuccess(payload) {
  return {
    type: USERS_SUCCESS,
    payload
  }
}

export function usersError(error) {
  return {
    type: USERS_ERROR,
    error
  }
}

export function userRequest(id, cb) {
  return {
    type: USER_REQUEST,
    id,
    callback: cb
  }
}

export function receivedUser(userData) {
  return {
    type: USER_SUCCESS,
    payload: userData,
  }
}

export function userError(error) {
  return {
    type: USER_ERROR,
    error
  }
}

export function clearUser() {
  return {
    type: CLEAR_USER
  }
}

// export function changeUserStatusRequest(params, cb) {
//   //let newData = {...values, email:extras}
//   console.log("changeUserStatusRequest",params)
//   return {
//     type: CHANGE_USER_STATUS_REQUEST,
//     params,
//     cb
//   }
// }

// export function changeUserStatusSuccess(payload) {
//   return {
//     type: CHANGE_USER_STATUS_SUCCESS,
//     payload
//   }
// }

// export function changeUserStatusError(error) {
//   return {
//     type: CHANGE_USER_STATUS_ERROR,
//     error
//   }
// }


export function addUser(body,cb) {
  return {
    type: ADD_USER_REQUEST,
    body,
    cb
  }
}

export function addUserSuccesS(organizationData) {
  return {
    type: ADD_USER_SUCCESS,
    payload: organizationData
  }
}

export function addUserError(errorInfo) {
  return {
    type: ADD_USER_ERROR,
    payload: errorInfo,
  }
}


export function deleteUser(body, cb) {
  return {
    type: DELETE_USER_REQUEST,
    body,
    state:body.state,
    cb
  }
}

export function deleteUserSuccesS(organizationData) {
  return {
    type: DELETE_USER_SUCCESS,
    payload: organizationData
  }
}

export function deleteUserError(errorInfo) {
  return {
    type: DELETE_USER_ERROR,
    payload: errorInfo,
  }
}

export function getUserDetailRequest(){
  return {
    type:GET_USER_DETAIL_REQUESST,
  }
}
export function getUserDetailSuccess(data){
  return {
    type:GET_USER_DETAIL_SUCCESS,
    payload:data
  }
}
export function getUserDetailError(error){
  return {
    type:GET_USER_DETAIL_ERROR,
    payload:error
  }
}

export function clearLocalStorage(){
  return{
    type:CLEAR_LOGOUT,
  }
}