import React, { useState } from 'react';
import { connect } from "react-redux";
import RootForm from "../../../components/form/index";
import { Row, Col } from 'antd';
import TextAreaWrapper from '../../../components/input/TextArea';
import { replyMessage } from '../../../configurations/Schemas';
import {MessageResponseRequest } from "../../../actions/Messages";

const ReplyMessage = (props) => {
  console.log({props})
  const [state, setState] = useState({
    id: props.message_id,
    email: props.email,
    body: "",
  });

  const handleChanged = (e) => {
    const {name, value} = e.target;
    setState({
      ...state,
      [name]: value
    })
  }
  return (
    <Row type="flex" justify="center" align="middle">
      <Col span={24}>
        <h4 className='mr-b mr-t-md'>Reply to Message</h4>
        {console.log("Reply Message State: ", state.email, state.reply_message)}
        <RootForm
          schemaName={replyMessage}
          initialValues={state}
          apiCall={MessageResponseRequest}
          cb={props.closeSelf}
          extras={state.email}
          loading={props.Messages.loading}
          autoWidthSubmit
          controls={[
            {
              span: 24,
              name: "body",
              value: state.reply_message,
              handleChanged: handleChanged,
              placeholder: "Write Something...",
              rows: 6,
              addonBefore: "",
              Type: TextAreaWrapper,
            },
          ]}
          submitButton={{
            span: 24,
            buttonText: "Send",
            className: 'mr-t-md mr-b-md',
          }}
        />
      </Col>
    </Row>
  )
}

export default connect(state => ({ Messages: state.Messages }),
)(ReplyMessage);