import { combineReducers } from "redux";
import DashboardReducer from "./Dashboard";
import appReducer from "./App";
import modalReducer from "./Modal";
import authReducer from "./Auth";
import RequestsReducer from "./Requests";
import MessagesReducer from "./Messages";
import OrganizationReducer from "./Organization";
import updateProfileReducer from "./updateProfile";
import AddBankAccountReducer from "./BankAccount";
import OptionFieldsReducer from "./FieldOptions";
import ContactReducer from "./Contact";
import TransactionReducer from "./Transactions";
import ReportsReducer from "./Reports";
import SearchTransactionReducer from "./SearchTransactions";
import TransferDetailsReducer from "./TransferDetails";
import ChartsReducer from "./Charts";
import SettingsReducer from "./Settings";
import PrintCheckReducer from "./PrintChecks";
import SubscriptionReducer from "./Subscription";
//Traer
import UserReducer from "./Users";
//Traer
export default combineReducers({
  App: appReducer,
  Auth: authReducer,
  Dashboard: DashboardReducer,
  Modal: modalReducer,
  Requests: RequestsReducer,
  Messages: MessagesReducer,
  Organization: OrganizationReducer,
  UpdateProfile: updateProfileReducer,
  AddBankAccount: AddBankAccountReducer,
  FieldOption: OptionFieldsReducer,
  Contact: ContactReducer,
  transaction: TransactionReducer,
  Reports: ReportsReducer,
  SearchTransactions: SearchTransactionReducer,
  TransferDeatilsTransactions: TransferDetailsReducer,
  settings: SettingsReducer,
  charts: ChartsReducer,
  PrintCheck: PrintCheckReducer,
  Subscription: SubscriptionReducer,

  //Traer
  Users: UserReducer,
  //Traer
});

//lazmi krna hai
// function combine(state, action) {
//     if(action.type === "logout"} {
//         state = null;
//     }
// }
