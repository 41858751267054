import React from "react";
import { Row, Col } from "antd";
import InputWrapper from "../../../components/input";
import SelectWrapper from "../../../components/input/Select";
import OptionsWrapper from "../../../components/input/SelectOptions";
import Blip from "../../../appcomponents/shares/blip";
import { FiSearch } from "react-icons/fi";
import Button from "../../../components/button/index";

//Need to make this responsive
const ContentHeader = (props) => {
  return (
    <Row type="flex" align="middle" justify="space-between" gutter={[0, 10]}>
      <Row type="flex" align="middle" justify="space-between" gutter={[0, 10]}>
        <Col>
          <h3 className="no-mr" style={{ fontWeight: "bold" }}>
            {props.title}
          </h3>
        </Col>

        <Col>
          &nbsp;&nbsp;
          <Blip color="#C3F64D"/>
          &nbsp;&nbsp;
          {!props.hideCount && (
            <span className="small" style={{ fontWeight: "bold" }}>
              {props.count || "0"}
            </span>
          )}
        </Col>

        <Col>
          {props.showAddButton && (
            <span style={{ marginLeft: 15 }}>
              <Button
                sharpBorder
                size={"large"}
                block
                onClick={props.onClick}
                isGreen={true}
              >
                Add User
              </Button>
            </span>
          )}
        </Col>
      </Row>
      {/* <Col>
        <div style={{ display: "flex", alignItems: "center" }}>

          &nbsp;&nbsp;
          <Blip />
          &nbsp;&nbsp;
          {!props.hideCount && <span className="small" style={{ fontWeight: "bold" }}>
            {props.count || "0"}
          </span>}
          {props.showAddButton &&
            // <span>
            <Button style={{ boxShadow: "0px 5px 5px 1px rgba(0,0,0,0.15)" }} sharpBorder size={"large"} block onClick={() => props.openModal()}>
              Add Organization
                </Button>
            // </span>
          }
        </div>
      </Col> */}
      {props.hideOption ? (
        <Col xs={24} md={24} lg={12} xl={6}>
          <InputWrapper
            // border={`2px solid #AD1E23`}
            borderRadius={`10px`}
            withBackground
            onChange={(e) => props.searchChange(e.target.value)}
            className="no-mr"
            isSearch={true}
            prefix={<FiSearch style={{color:'#C3F64D'}}/>}
            placeholder="Search here."
          />
        </Col>
      ) : (
        !props.hideFilters && (
          <Col xs={24} md={24} lg={12} xl={9}>
            <Row gutter={[14, 10]}>
              <Col xs={24} sm={16} lg={16}>
                <InputWrapper
                  border={`2px solid #AD1E23`}
                  borderRadius={`10px`}
                  withBackground
                  onChange={(e) => props.searchChange(e.target.value)}
                  className="no-mr"
                  prefix={<FiSearch />}
                  placeholder="Search"
                />
              </Col>
              {!props.hideOption ? (
                <Col
                  xs={24}
                  sm={8}
                  lg={8}
                  //style={{ paddingLeft: 15 }}
                >
                  <SelectWrapper
                    noBorder
                    onChange={props.filterChange}
                    defaultValue={props.options ? props.options[0].label : "-"}
                    style={{ width: "100%" }}
                  >
                    {props.options
                      ? props.options.map((val) => {
                          return (
                            <OptionsWrapper value={val.value} key={val.value}>
                              {val.label}
                            </OptionsWrapper>
                          );
                        })
                      : null}
                  </SelectWrapper>
                </Col>
              ) : null}
            </Row>
          </Col>
        )
      )}
      {/* {!props.hideFilters && <Col xs={24} md={24} lg={12} xl={9}>
        <Row gutter={[14, 10]}>
          <Col xs={24} sm={16} lg={16}>
            <InputWrapper
              border={`2px solid #AD1E23`}
              borderRadius={`10px`}
              withBackground
              onChange={e => props.searchChange(e.target.value)}
              className="no-mr"
              prefix={<FiSearch />}
              placeholder="Search" />
          </Col>
          {!props.hideOption ? 
          <Col
            xs={24}
            sm={8}
            lg={8}
          //style={{ paddingLeft: 15 }}
          >
            <SelectWrapper
              noBorder
              onChange={props.filterChange}
              defaultValue={props.options ? props.options[0].label : "-"}
              style={{ width: "100%", }}
            >
              {props.options
                ? props.options.map(val => {
                  return (
                    <OptionsWrapper value={val.value} key={val.value}>
                      {val.label}
                    </OptionsWrapper>
                  );
                })
                : null}
            </SelectWrapper>
          </Col> : null}
        </Row>
      </Col>
      } */}
    </Row>
  );
};

export default ContentHeader;
