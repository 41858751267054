import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { openModal } from "../../actions/Modal";
// import { store } from "../../ConfigureStore";
import { ThemeContext } from "../../configurations/Theme";
import { Layout } from "antd";
import { menuShow } from "../../configurations/Config";
import HeaderWrapper from "../../components/header/index";
// import faqs from "../../appcomponents/landing/Faq";
// import Profile from "./Profile";
// import ManageTeam from "./ManageTeam";
import { setHistory, redirect } from "../../actions/App";
import NavigationMenu from "../../components/navigationMenu";
import {
  LeftSideMenuWrapper,
  RightSideMenuWrapper,
} from "../../components/sidebars/index";
import ContentWrapper from "../../components/maincontent/index";
// import FooterWrapper from "../../components/footer/index";
import Dashboard from "./dashboard";
import ChangePassword from '../auth/ChangePassword'
import { Requests, RequestDetails } from "./requests";
import { Users, UserDetails } from "./users";
// import MyMatchups from "./ProfileDetails";
// import Users from "./Users";
// //import OpenMatchups from "./openMatchups";
// import Flagged from "./Flagged";
import Messages from "./messages";
import Organization from "./organizations";
import AddOrganization from './organizations/addOrganization';
import OrganizationDetails from './organizations/OrganizationDetails';
// import Messages from "./Messages";
// import Requests from "./Requests";
// import Settings from "./Settings";
// import Reports from "./Reports";

// const action = (type, payload) => store.dispatch({ type, payload });
class Shell extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      collapsedRight: true,
      collapsedLeft: false,
      ScreenWidth: window.innerWidth,
    };

    this.handleWidthChange = this.handleWidthChange.bind(this);
  }

  componentWillMount() {
    if (!localStorage.getItem("reviseuserauth")) {
      //this.props.history.push("/login");
    } else if (localStorage.getItem("reviseuserauth")) {
      let userData = JSON.parse(window.localStorage.getItem("reviseuserauth"));
      this.setState({ userData: userData });
    }
  }

  toggleCollapsed = (type) => {
    this.setState({
      [type]: !this.state[type],
    });
  };

  componentDidUpdate(prevProps) {
    if (prevProps.location.pathname !== this.props.location.pathname) {
      this.props.redirect(this.props.location.pathname);
    }
    window.addEventListener("resize", this.handleWidthChange);
  }

  componentDidMount() {
    this.props.setHistory(this.props.history);
    this.props.redirect(this.props.location.pathname);
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.handleWidthChange);
  }
  handleWidthChange = () => {
    this.setState({
      ScreenWidth: window.innerWidth,
    });
  };

  render() {
    //console.log("State shell",this.props);
    let size = this.state.ScreenWidth;
    let externalscreen = this.props.storeState.App.externalScreen ? 1 : 0; //this.props.location.state //props.App.externalScreen;
    let scroll = this.props.storeState.App.scroll;
    return (
      <div className="App">
        <Layout>
          <HeaderWrapper
            state={this.state}
            toggleCollapsed={this.toggleCollapsed}
            externalscreen={externalscreen}
          />
          <Layout>
            {menuShow.leftMenu === true && !externalscreen ? (
              size >= 992 ? (
                <></>
                // <LeftSideMenuWrapper state={this.state} />
              ) : (
                <></>
                // <NavigationMenu />
              )
            ) : null}
            <ContentWrapper
              scroll={scroll ? 1 : 0}
              externalscreen={externalscreen}
              centercontent={
                this.props.location.pathname === "/profile" ? 1 : 0
              }
            >
              <Switch>
                <Route exact path="/" component={Users} />
                <Route exact path="/users" component={Users} />
                <Route exact path="/users/:id" component={UserDetails} />
                {/* <Route exact path="/requests" component={Requests} /> */}
                <Route exact path="/messages" component={Messages} />
                <Route exact path="/organization" component={Organization} />
                {/* <Route exact path="/requests/:id" component={RequestDetails} /> */}
                <Route exact path="/addOrganization" component={AddOrganization} />
                <Route exact path="/organizationDetails"  component={OrganizationDetails} />
                
                <Route exact path="/change-password" component={ChangePassword} />
                {/* <Route exact path="/my-matchups" component={MyMatchups} /> */}
                {/* <Route path="/users" component={Users} />
                <Route exact path="/finances" component={Messages} />
                <Route exact path="/faqs" component={Flagged} />
                <Route exact path="/profile" component={Profile} />
                <Route exact path="/manage-team" component={ManageTeam} /> */}
                {/* <Route exact path="/faqs" component={faqs} />
                <Route exact path="/contact-us" component={Contact} /> */}
                <Route exact path="*" render={() => <Redirect to="/" />} />
                {/* <Route exact path ='/reports' component={Reports}/>
                <Route exact path ='/requests' component={Requests}/>
                <Route exact path ='/settings' component={Settings}/> */}
              </Switch>
            </ContentWrapper>
            {menuShow.rightMenu === true ? (
              <RightSideMenuWrapper state={this.state} />
            ) : null}
          </Layout>
          {/* <FooterWrapper /> */}
        </Layout>
      </div>
    );
  }
}

Shell.contextType = ThemeContext;

export default connect((state) => ({ storeState: state }), {
  setHistory,
  redirect,
  openModal,
})(Shell);
