import React from "react";
import { Skeleton } from "antd";
import CardWrapper from "../../../components/card";
import Image from "../../../components/image";

import styled from "styled-components";

const StyledCard = styled(CardWrapper)`
  & > .ant-card-body {
    padding: 2.4rem 1rem;
  }
`;

const UserStatCard = props => 
  (<StyledCard className={props.styledClass}>
    <Skeleton loading={props.loading} avatar active>
      <div style={{ textAlign: "left" }}>
        <div 
        style={
          { display: "flex", 
          alignItems: "center", 
          justifyContent: "center", 
          color: props.iconColor, 
          width: 40, 
          height: 40, 
          fontSize: "1.3rem", 
          borderRadius: 8, 
          backgroundColor: props.iconBack }}
        >
          <Image width={20} src={props.icon} />
          {console.log('this is props ',props)}
        </div>
        <h4 
        style={{ margin: "14px 0px 2px 0px", fontWeight: "bold" }}
        >
        {props.title}
        </h4>
        <p 
        className="hintColor" 
        style={{ margin: "2px 0px 14px 0px", fontWeight: "bold" }}
        >
        {props.amount}
        </p>
      </div>
    </Skeleton>
  </StyledCard>)

export default UserStatCard;