import React from "react";
import { Layout } from "antd";
import styled, { withTheme } from "styled-components";
import { LeftSideBarMenu } from "../sidebarMenus/index";

const { Sider } = Layout;

const StyledSider = styled(Sider)`
  min-width: unset !important;
  position: fixed;
  height: 10vh;
  z-index: 2500;
  background: ${props => props.theme[props.theme.mode].background.primary};
  color: ${props => props.theme[props.theme.mode].textColor.primary}; 
  box-shadow: 0px 5px 20px #1E1E2214;

  transition: width 0.2s, min-width 0.2s, max-width 0.2s, flex 0.2s, background 0s;

  && * {
    transition: width 0.2s, min-width 0.2s, max-width 0.2s, flex 0.2s,  background 0s;
  }
//   font-weight: bold;
//   box-shadow: 0 0 0 2px rgba(0, 0, 0, 1);
`;

const LeftSideMenuWrapper = props => {
  return (
    <StyledSider
    width={props.theme[props.theme.mode].spacing.mainscreen}
    //collapsedWidth={0}
    //collapsed={props.state.collapsedLeft}
    {...props}
  >
    <LeftSideBarMenu />
    
  </StyledSider>
  // <></>
  );
};

export default withTheme(LeftSideMenuWrapper);
